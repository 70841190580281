import { DialogContent, Dialog, Button, DialogContentText, DialogActions } from '@mui/material';
import { useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';

import { Readinglesson } from './Readinglesson';
export function ReadingDialog(props: any) {
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [openDiscart, setOpenDiscart] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
   
    const handleClickOpen = () => {
        setOpen(true);
    };
    //close modal method
    //modify values by the input value
    const handleOpenDelete = () => {
        setOpenDelete(true)
    }
    const handleCloseDelete = () => {
        setOpenDelete(false)
    }
   const handleOpenDiscart=()=>{
    setOpenDiscart(true)
   }
    const handleCloseDiscart = () => {
        setOpenDiscart(false)
    }
    const handleAcceptDiscart = () => {
      
        handleCloseDiscart()
        setOpen(false)
    }
   
    const handdleDelete = () => {
        const token = localStorage.getItem('token');
        MetadataApi.delete("readinglesson/deletereadinglesson/" + props.id, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            props.getReadingsLessonsByContent()
            handleCloseDelete()

        })
    }
    return (
        <>
            <Button onClick={handleClickOpen} sx={{ marginRight: 1 }} size="small" variant='contained' color='primary'>
                {props.add ? "Crear Nuevo" : "Editar"}
            </Button>
            {props.add ? null : <Button sx={{ marginRight: 1 }} onClick={handleOpenDelete} size="small" variant='contained' color='primary' >
                Eliminar
            </Button>}
            {<Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea realmente desea eliminar este Readinglesson?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancelar</Button>
                    <Button onClick={handdleDelete} autoFocus >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>}
            {<Dialog open={open} onClose={()=>{setOpen(false)}} fullWidth maxWidth="lg">
                <DialogContent>
                    <Readinglesson handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenSuccess} handleUpdateSnackbar={props.handleUpdateSnackbar} height={400} handleOpenDiscart={handleOpenDiscart} setCloseDialog={setOpen} getData={props.getReadingsLessonsByContent} id={props.id} contentId={props.contentId} add={props.add}></Readinglesson>
                </DialogContent>
            </Dialog>}
            {
                <Dialog
                    open={openDiscart}
                    onClose={handleCloseDiscart}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Desea realmente descartar los cambios realizados?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDiscart} variant='outlined'>Cancelar</Button>
                        <Button onClick={handleAcceptDiscart} variant='contained'>
                            Descartar
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}