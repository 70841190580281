import { TextField, Button, Checkbox, FormControlLabel, FormLabel, MenuItem, Radio } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import "./styles/video.css"
import Grid from '@mui/material/Grid';
import "./styles/editor.css";
import { Wysiwyg } from './Wysiwyg';
import { Selection } from '../enums/typeSelection.enum';
import { SaveButton } from './SaveButton';

export function Questionlesson(props: any) {
    const [isCreating,setIsCreating]=useState<boolean>(false)
    const [editorValue, setEditorValue] = useState<string>("<p><br></p>");
    const [feedbackDisabled, setFeedbackDisabled] = useState<boolean>(true)
    const [checkFeedback, setCheckFeedback] = useState<boolean>(false)
    const [feedbackRequired, setFeedbackRequired] = useState<boolean>(false)
    const [initialEditor, setInitialEditor] = useState<string>("<p><br></p>")
    const [initialQuestionType, setInitialQuetionType] = useState<string>("")
    const [firstSaveDone, setFirstSaveDone] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [textChanged, setTextChanged] = useState<boolean>(false)
    const [answers, setAnswers] = useState(
        {
            alternative1_correct: true,
            alternative2_correct: false,
            alternative3_correct: false,
            alternative4_correct: false,
            alternative5_correct: false
        }
    )
    const [initialEditAnswers, setInitialEditAnswers] = useState(
        {
            alternative1_correct: true,
            alternative2_correct: false,
            alternative3_correct: false,
            alternative4_correct: false,
            alternative5_correct: false
        }
    )
    const { alternative1_correct,
        alternative2_correct,
        alternative3_correct,
        alternative4_correct,
        alternative5_correct } = answers

    const [initialQuestion, setInitialQuestion] = useState({
        contentId: props.contentId,
        id: 0,
        question: "",
        alternative1: "",
        alternative1_correct: true,
        alternative2: "",
        alternative2_correct: false,
        alternative3: "",
        alternative3_correct: false,
        alternative4: "",
        alternative4_correct: false,
        alternative5: "",
        alternative5_correct: false,
        multiselection: Selection.UniqueSelection,
        order: 0,
        hadFeedback: false,
        alternative1_feedback: "",
        alternative2_feedback: "",
        alternative3_feedback: "",
        alternative4_feedback: "",
        alternative5_feedback: "",
    })
    const [question, setQuestion] = useState(initialQuestion);
    //nuevo

    const fillQuestionData = useCallback((data: any) => {
        let typeQuestion = Selection.UniqueSelection
        if (data.multiselection) {
            typeQuestion = Selection.MultiSelection
        }
        if (data.hadFeedback) {
            setFeedbackDisabled(false)
            setFeedbackRequired(true)
            setCheckFeedback(true)
        }
        const newQuestion = {
            contentId: props.contentId,
            id: data.id,
            question: data.question,
            alternative1: data.alternative1,
            alternative1_correct: data.alternative1_feedback,
            alternative2: data.alternative2,
            alternative2_correct: data.alternative2_correct,
            alternative3: data.alternative3,
            alternative3_correct: data.alternative3_correct,
            alternative4: data.alternative4,
            alternative4_correct: data.alternative4_correct,
            alternative5: data.alternative5,
            alternative5_correct: data.alternative5_correct,
            order: data.order,
            multiselection: typeQuestion,
            hadFeedback: data.hadFeedback,
            alternative1_feedback: data.alternative1_feedback,
            alternative2_feedback: data.alternative2_feedback,
            alternative3_feedback: data.alternative3_feedback,
            alternative4_feedback: data.alternative4_feedback,
            alternative5_feedback: data.alternative5_feedback,
        }
        setInitialQuetionType(typeQuestion)
        setQuestion(newQuestion)
        setInitialQuestion(newQuestion)
        setInitialEditor(data.detail)
        setEditorValue(data.detail)
        const answers = {
            alternative1_correct: data.alternative1_correct,
            alternative2_correct: data.alternative2_correct,
            alternative3_correct: data.alternative3_correct,
            alternative4_correct: data.alternative4_correct,
            alternative5_correct: data.alternative5_correct
        }
        setAnswers(answers)

        setInitialEditAnswers(answers)
    }, [props.contentId])

    const getReadingData = useCallback(() => {
        const token = localStorage.getItem('token');
        MetadataApi.get("questions/getquestionsbyid/" + props.id, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            fillQuestionData(res.data)

        })
    }, [fillQuestionData, props.id])

    useEffect(() => {
        if (props.add !== true) {
            getReadingData()
        }
    }, [props.add, getReadingData])

    const resetAnswers = () => {
        setAnswers({
            ...answers, alternative1_correct: true,
            alternative2_correct: false,
            alternative3_correct: false,
            alternative4_correct: false,
            alternative5_correct: false,
        })
    }
    //close modal method
    /* const handleClose = useCallback(() => {
        setQuestion(
            initialQuestion
        )
        setInitialEditor("<p><br></p>")
        props.setCloseDialog(false);
    },[initialQuestion,props]); */
    //modify values by the input value
    const handleInputChange = (e: any) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.value
        })
    }
    const handleCheckBoxChange = (e: any) => {
        resetAnswers()
        setAnswers({
            ...answers, [e.target.name]: e.target.checked
        })
    }

const saveQuestion=useCallback(()=>{
    setIsSaving(true)
    setIsCreating(true)
    let typeQuestion = false
    if (question.multiselection === Selection.MultiSelection) {
        typeQuestion = true
    }
    let alternative1_feedback = question.alternative1_feedback
    let alternative2_feedback = question.alternative2_feedback
    let alternative3_feedback = question.alternative3_feedback
    let alternative4_feedback = question.alternative4_feedback
    let alternative5_feedback = question.alternative5_feedback
    if (feedbackDisabled) {
        alternative1_feedback = ""
        alternative2_feedback = ""
        alternative3_feedback = ""
        alternative4_feedback = ""
        alternative5_feedback = ""
    }
    let feedbackValue = true
    if (feedbackDisabled) {
        feedbackValue = false
    }
    
    const token = localStorage.getItem('token');
    MetadataApi.post("questions/addquestion", {
        contentId: props.contentId,
        id: question.id,
        detail: editorValue,
        question: question.question,
        alternative1: question.alternative1,
        alternative1_correct: answers.alternative1_correct,
        alternative2: question.alternative2,
        alternative2_correct: answers.alternative2_correct,
        alternative3: question.alternative3,
        alternative3_correct: answers.alternative3_correct,
        alternative4: question.alternative4,
        alternative4_correct: answers.alternative4_correct,
        alternative5: question.alternative5,
        alternative5_correct: answers.alternative5_correct,
        multiselection: typeQuestion,
        order: question.order,
        hadFeedback: feedbackValue,
        alternative1_feedback: alternative1_feedback,
        alternative2_feedback: alternative2_feedback,
        alternative3_feedback: alternative3_feedback,
        alternative4_feedback: alternative4_feedback,
        alternative5_feedback: alternative5_feedback,
    }, {
        headers: ({
            Authorization: 'Bearer ' + token
        })
    }).then((res: AxiosResponse) => {
        setFirstSaveDone(true)
        if(props.add){
            props.setCloseDialog(false)
        }
     
        setTextChanged(false)
        setUnsavedChanges(false)
        setIsSaving(false)
        setInitialEditor(editorValue)
       /*  handleClose() */
        getReadingData()
        props.getData()
        if (props.add) {
            props.handleUpdateSnackbar("Questionlesson creado")
        } else {

            props.handleUpdateSnackbar("Questionlesson editado")
        }
        setIsCreating(false)
        props.handleOpenSuccess()
    }).catch((reason: AxiosError) => {
        setIsSaving(false)
        if (props.add) {

            props.handleUpdateSnackbar("Crear Questionlesson")
        } else {

            props.handleUpdateSnackbar("Editar Questionlesson")
        }
        props.handleOpenError()
        setIsCreating(false)
    })

},[question,answers,editorValue,feedbackDisabled,props,getReadingData])

    const handleSubmit = (e: any) => {
        e.preventDefault()
        saveQuestion()
       
    }
    const handleChangeRadioButton = (e: any) => {
        setAnswers(previousValue => ({ ...previousValue, alternative1_correct: false }))
        setAnswers(previousValue => ({ ...previousValue, alternative2_correct: false }))
        setAnswers(previousValue => ({ ...previousValue, alternative3_correct: false }))
        setAnswers(previousValue => ({ ...previousValue, alternative4_correct: false }))
        setAnswers(previousValue => ({ ...previousValue, alternative5_correct: false }))
        setAnswers(previousValue => ({ ...previousValue, [e.target.name]: true }))

    }


    const handleOpenDiscart = () => {
        if (initialEditor === editorValue && JSON.stringify(initialQuestion) === JSON.stringify(question)) {
            props.setCloseDialog(false)()
        } else {
            props.handleOpenDiscart()
        }

    }

    const handlefeedback = () => {

        setFeedbackDisabled(!feedbackDisabled)
        setFeedbackRequired(!feedbackRequired)
        setCheckFeedback(!checkFeedback)

    }
    useEffect(() => {
        if (question.multiselection !== initialQuestionType) {
            setAnswers(
                {
                    alternative1_correct: true,
                    alternative2_correct: false,
                    alternative3_correct: false,
                    alternative4_correct: false,
                    alternative5_correct: false
                }
            )
        } else {
            setAnswers(initialEditAnswers)
        }

    }, [question.multiselection, initialEditAnswers, initialQuestionType])

    const customReactQuill = () => {

        let toolbarQuesitonlesson = "questionlesson"
        if (props.repeated) {
            toolbarQuesitonlesson = "questionlessontreeview"
        }

        return (

            < Wysiwyg toolbarName={toolbarQuesitonlesson} height={props.height} value={editorValue} change={setEditorValue} courseId={props.courseId} />

        );
    }

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    
    useEffect(() => {
        const handleUnload = (event: any) => {
            if (unsavedChanges) {
                event.preventDefault();

            }
        };
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [unsavedChanges]);
    useEffect(() => {
        if (editorValue === initialEditor && JSON.stringify(initialQuestion) === JSON.stringify(question) ) {
        
            setUnsavedChanges(false)
            setTextChanged(false)
        } else {
            setTextChanged(true)
            setUnsavedChanges(true)
        }
     
    }, [editorValue, initialEditor,initialQuestion,question])
    useEffect(()=>{
        if(answers===initialEditAnswers){
            setTextChanged(false)
        }else{
            setTextChanged(true)
          
        }
    },[answers,initialEditAnswers])

    useEffect(() => {
        // Auto-save when text changes
        if (unsavedChanges&&!props.add) {

            const saveTimeout = setTimeout(() => {
                saveQuestion()
                setUnsavedChanges(false);
            }, Number(process.env.REACT_APP_AUTOSAVE_TIMER)|| 60000);

            return () => clearTimeout(saveTimeout);
        }
    }, [unsavedChanges, saveQuestion,props.add]);
    return (
        <>
            <form onSubmit={handleSubmit} id="form" style={{width:"100%"}}>
            <Grid container spacing={4} columns={16} justifyContent="center" >
        
                <Grid item xs={8}>
                    {customReactQuill()}
                    {props.treeview ?
                        <Grid sx={{ marginTop: 2, textAlign: "right" }}>
                            <SaveButton textChanged={textChanged} isSaving={isSaving} firstSaveDone={firstSaveDone} saveFunction={saveQuestion}></SaveButton>
                        </Grid >
                        : <Grid sx={{ marginTop: 2, textAlign: "right" }}><Button variant="outlined" onClick={handleOpenDiscart} sx={{marginRight:2}}>Cerrar</Button><Button variant='contained' type='submit' disabled={isCreating}>{props.add ? "Crear" : "Guardar"}</Button></Grid>
                    }
                </Grid>
                <Grid item xs={8}>
                   
                        <TextField
                            required
                            margin="dense"
                            id="question"
                            name="question"
                            label="Pregunta"
                            type="textarea"
                            sx={{ maxHeight: "100%" }}
                            value={question.question}
                            onChange={handleInputChange}
                            fullWidth
                            variant="standard"
                        />
                        <FormControlLabel
                            value="top"
                            control={<Checkbox checked={checkFeedback} onChange={handlefeedback} />}
                            label="¿Añadir Feedback?"
                            labelPlacement="start"
                        />
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    margin="dense"
                                    id="alternative1"
                                    name="alternative1"
                                    label="Alternativa 1"
                                    type="text"
                                    multiline
                                    value={question.alternative1}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required={feedbackRequired}
                                    disabled={feedbackDisabled}
                                    margin="dense"
                                    id="alternative1_feedback"
                                    name="alternative1_feedback"
                                    label="Feedback 1"
                                    type="text"
                                    multiline
                                    value={question.alternative1_feedback}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    margin="dense"
                                    id="alternative2"
                                    name="alternative2"
                                    label="Alternativa 2"
                                    type="text"
                                    multiline
                                    value={question.alternative2}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required={feedbackRequired}
                                    disabled={feedbackDisabled}
                                    margin="dense"
                                    id="alternative2_feedback"
                                    name="alternative2_feedback"
                                    label="Feedback 2"
                                    type="text"
                                    multiline
                                    value={question.alternative2_feedback}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <TextField
                                    required

                                    margin="dense"
                                    id="alternative3"
                                    name="alternative3"
                                    label="Alternativa 3"
                                    type="text"
                                    multiline
                                    value={question.alternative3}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required={feedbackRequired}
                                    disabled={feedbackDisabled}

                                    margin="dense"
                                    id="alternative3_feedback"
                                    name="alternative3_feedback"
                                    label="Feedback 3"

                                    type="text"
                                    multiline
                                    value={question.alternative3_feedback}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <TextField
                                    required

                                    margin="dense"
                                    id="alternative4"
                                    name="alternative4"
                                    label="Alternativa 4"
                                    type="text"
                                    multiline
                                    value={question.alternative4}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required={feedbackRequired}
                                    disabled={feedbackDisabled}

                                    margin="dense"
                                    id="alternative4_feedback"
                                    name="alternative4_feedback"
                                    label="Feedback 4"
                                    type="text"
                                    multiline
                                    value={question.alternative4_feedback}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <TextField
                                    required

                                    margin="dense"
                                    id="alternative5"
                                    name="alternative5"
                                    label="Alternativa 5"
                                    type="text"
                                    multiline
                                    value={question.alternative5}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required={feedbackRequired}
                                    disabled={feedbackDisabled}

                                    margin="dense"
                                    id="alternative5_feedback"
                                    name="alternative5_feedback"
                                    label="Feedback 5"
                                    type="text"
                                    multiline
                                    value={question.alternative5_feedback}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            required

                            margin="dense"
                            id="multiselection"
                            name="multiselection"
                            onChange={handleInputChange}
                            label="Tipo de pregunta"
                            value={question.multiselection}
                            select
                            fullWidth
                            variant="standard"
                        >
                            <MenuItem key={"multi"} value={Selection.MultiSelection}>
                                Selección Multiple
                            </MenuItem>
                            <MenuItem key={"unique"} value={Selection.UniqueSelection}>
                                Selección Única
                            </MenuItem>
                        </TextField>
                        <FormLabel component="legend">Alternativas correctas</FormLabel>
                        {question.multiselection === Selection.MultiSelection ? <><FormControlLabel
                            control={
                                <Checkbox checked={alternative1_correct} onChange={handleCheckBoxChange} name="alternative1_correct" />
                            }
                            label="Alternativa 1"
                        />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={alternative2_correct} onChange={handleCheckBoxChange} name="alternative2_correct" />
                                }
                                label="Alternativa 2"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={alternative3_correct} onChange={handleCheckBoxChange} name="alternative3_correct" />
                                }
                                label="Alternativa 3"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={alternative4_correct} onChange={handleCheckBoxChange} name="alternative4_correct" />
                                }
                                label="Alternativa 4"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={alternative5_correct} onChange={handleCheckBoxChange} name="alternative5_correct" />
                                }
                                label="Alternativa 5"
                            />
                        </> : null}
                        {question.multiselection === Selection.UniqueSelection ? <>
                            <FormControlLabel
                                control={
                                    <Radio checked={alternative1_correct}
                                        onChange={(e) => { handleChangeRadioButton(e) }}
                                        value="a"
                                        name="alternative1_correct"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                }
                                label="Alternativa 1"
                            />
                            <FormControlLabel
                                control={
                                    <Radio checked={alternative2_correct}
                                        onChange={(e) => { handleChangeRadioButton(e) }}
                                        value="a"
                                        name="alternative2_correct"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                }
                                label="Alternativa 2"
                            />
                            <FormControlLabel
                                control={
                                    <Radio checked={alternative3_correct}
                                        onChange={(e) => { handleChangeRadioButton(e) }}
                                        value="a"
                                        name="alternative3_correct"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                }
                                label="Alternativa 3"
                            />
                            <FormControlLabel
                                control={
                                    <Radio checked={alternative4_correct}
                                        onChange={(e) => { handleChangeRadioButton(e) }}
                                        value="a"
                                        name="alternative4_correct"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                }
                                label="Alternativa 4"
                            />
                            <FormControlLabel
                                control={
                                    <Radio checked={alternative5_correct}
                                        onChange={(e) => { handleChangeRadioButton(e) }}
                                        value="a"
                                        name="alternative5_correct"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                }
                                label="Alternativa 5"
                            />
                        </> : null}
                   
                </Grid>
                
            </Grid>
            </form>
        </>
    )
}