import { useContext,  } from 'react';
import { myContext } from '../contexts/Context';
export default function Profile() {
  const ctx = useContext(myContext);
  return (
    <div className="centered">
      {ctx && Object.keys(ctx).length !== 0 ?
       <h1>Actualmente conectado como: {ctx.firstname} {ctx.lastname}</h1>: ''}
    </div>
  )
}