
import Typography from "@mui/material/Typography";
import { useState, useEffect } from 'react';
import { LinearProgress } from "@mui/material";
import { ContentStatus } from "../../enums/contentStatus.enum";

type Props={
  data:any,
  isProgram:boolean,

}


export function ProgressBar({data,isProgram}: Props) {

  const [barValue, setBarValue] = useState<number>(0)
  useEffect(() => {
    
    setBarValue(0)
    if (isProgram) {
      let courseComplete = 0
      data.programcourses.forEach((programcourse: any) => {
        if (programcourse.course !== null && (programcourse.course.status === ContentStatus.Completed || programcourse.course.status === ContentStatus.Perfect)) {
          courseComplete++;
        }
      })
      const finalProgress = Math.round((100 / data.programcourses.length) * courseComplete)

      if (!isNaN(finalProgress)) {
        setBarValue(finalProgress)
      }
    } else {
      let contentCounter = 0
      let progress = 0
      data.chapters.forEach((chapter: any) => {
        chapter.contents.forEach((content: any) => {
          contentCounter++;
          if (content.status === ContentStatus.Completed || content.status === ContentStatus.Perfect) {
            progress++;
          }
        })

      })
      const finalProgress = Math.round((100 / contentCounter) * progress)

      if (!isNaN(finalProgress)) {
        setBarValue(finalProgress)
      }
    }
  }, [data, isProgram]);

  return (
    <>
      <LinearProgress
        sx={{ height: 10, borderRadius: 5 }}
        variant="determinate"
        color="primary"
        value={barValue} // Pass the progression value here
      />
      <Typography variant="body2" align="center" color="text.secondary" sx={{ height: 15 }}>
        {`${barValue}%`}
      </Typography>
    </>
  );
}