export enum TypeInput {
  TXT = 'TXT_file',
  CSV_File = 'CSV_file',
  EXCEL ='Excel',
  STRING="String",
  CSV="CSV",
  NUMBER="Number",
  ARRAY_NUMBER="ArrayNumber",
  ARRAY_STRING="ArrayString",

}
