import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { UserInterface } from '../interfaces/UserInterface'
import MetadataApi from '../apis/metadata.api'

export const myContext = createContext<Partial<UserInterface>>({})

export default function Context(props: PropsWithChildren<any>) {

  const [user,setUser] = useState<UserInterface>()

  useEffect(() => {
    const token = localStorage.getItem('token');
    if(!token) return;
    MetadataApi.get("/auth/user", { 
      // withCredentials: true,
      headers: ({
        Authorization: 'Bearer ' + token
      })
    })
    .then((res: AxiosResponse) => {
      //console.log('login info',res.data)
      setUser(res.data);
    })
  }, []);

  return (
    <myContext.Provider value={user!}>{props.children}</myContext.Provider>
    )
}