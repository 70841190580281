import React, { useEffect, useState } from "react"
import { Box, Button, Grid, Pagination,  Typography } from "@mui/material"
import { ContentType } from "../../enums/typeContent.enum"
import * as DOMPurify from 'dompurify';
import MetadataApi from "../../apis/metadata.api";
import { AxiosResponse } from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';
type Props = {
    type_lesson: string,
    tabNumber: number,
    contentId: number,
    chapterId: number
    codelessonId: number,
    username: string,
    courseId: number
}

export function ChatLessonsTabs({ type_lesson, tabNumber, codelessonId, contentId, username, chapterId, courseId }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [jupyterSource, setJupyterSource] = useState<string>("")
    const [isJupyterLoaded, setIsJupyterLoaded] = useState<boolean>(false)
    const [jupyterLoading, setJupyterLoading] = useState<boolean>(true)
    const [jupyterText, setJupyterText] = useState<any[]>([])
    const [questionsText, setQuestionsText] = useState<any[]>([])
    const [readingsText, setReadingsText] = useState<any[]>([])
    const [page, setPage] = useState<number>(1)
    const cleanData = (html: string) => {
        return DOMPurify.sanitize(html)
    }
    const getStudentJupyterNotebookUrl = (chapterId: number, courseId: number, contentId: number, jupyterId: number, username: any) => {
        setJupyterLoading(true)
        const token = localStorage.getItem('token');
        MetadataApi.post("learncontents/getcopyjupyternotebookstudent", {
            chapterId: chapterId,
            courseId: courseId,
            contentId: contentId,
            username: username,
            jupyterId: jupyterId,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setJupyterLoading(false)
            if (res.data === "code error") {
                setIsJupyterLoaded(false)
            } else {
                setIsJupyterLoaded(true)
                setJupyterSource(res.data)
            }
        },)
    }
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    useEffect(() => {
        setIsLoading(false)
        if (tabNumber === 0) {
            const token = localStorage.getItem('token');
            if (type_lesson === ContentType.JupyterLesson) {
                MetadataApi.post("contents/getlessondescription", {
                    lessonId: codelessonId,
                    type_lesson: type_lesson
                }, {
                    headers: ({
                        Authorization: 'Bearer ' + token
                    }),
                }).then((res: AxiosResponse) => {
                    setJupyterText(res.data)
                    setIsLoading(true)
                })
            } else if (type_lesson === ContentType.Questions) {
                const token = localStorage.getItem('token');
                MetadataApi.post("contents/getlessondescription", {
                    contentId: contentId,
                    lessonId: 0,
                    type_lesson: type_lesson
                }, {
                    headers: ({
                        Authorization: 'Bearer ' + token
                    }),
                }).then((res: AxiosResponse) => {
                    setQuestionsText(res.data)
                    setIsLoading(true)
                })
            } else if (type_lesson === ContentType.Reading) {
                const token = localStorage.getItem('token');
                MetadataApi.post("contents/getlessondescription", {
                    contentId: contentId,
                    type_lesson: type_lesson,
                    lessonId: 0,
                }, {
                    headers: ({
                        Authorization: 'Bearer ' + token
                    }),
                }).then((res: AxiosResponse) => {
                    setReadingsText(res.data)
                    setIsLoading(true)
                })
            }
        } else if (tabNumber === 1) {
            getStudentJupyterNotebookUrl(chapterId, courseId, contentId, codelessonId, username)
            setIsLoading(true)
        }

    }, [tabNumber, chapterId, codelessonId, contentId, type_lesson, username, courseId])

    return (
        <>
            {isLoading ?
                tabNumber === 0 ?
                    <>
                        {type_lesson === ContentType.JupyterLesson ? <Typography variant='inherit' dangerouslySetInnerHTML={{ __html: cleanData(jupyterText[0].description) }} />
                            :
                            type_lesson === ContentType.Questions ?
                                <>
                                    <Grid container>
                                        <Grid item xs={12} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                                            <Pagination page={page} count={questionsText.length} onChange={handleChange}
                                                color="primary"></Pagination>
                                        </Grid>
                                        <Grid item xs={12} >
                                            {questionsText.map((item: any, index: number) => {
                                                return (
                                                    <Box  key={index} hidden={(index + 1) !== page}>
                                                        <Typography variant='h6'>Pregunta {page}</Typography>
                                                        <Box sx={{
                                                            overflow: 'auto', height: "463px"
                                                        }}>
                                                            <Typography variant='inherit' dangerouslySetInnerHTML={{ __html: cleanData(item.description) }}></Typography>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                type_lesson === ContentType.Reading ?
                                    <>
                                        <Grid container>
                                            <Grid item xs={12} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                                                <Pagination page={page} count={readingsText.length} onChange={handleChange}
                                                    color="primary"></Pagination>
                                            </Grid>
                                            <Grid item xs={12} >
                                                {readingsText.map((item: any, index: number) => {
                                                    return (
                                                        <Box key={index} hidden={(index + 1) !== page}>
                                                            <Typography variant='h6'>Pregunta {page}</Typography>
                                                            <Box sx={{
                                                                overflow: 'auto', height: "463px",
                                                            }}>
                                                                <Typography variant='inherit' dangerouslySetInnerHTML={{ __html: cleanData(item.description) }}></Typography>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    null
                        }
                    </>
                    :
                    jupyterLoading ?
                        <Box alignItems={"center"} display={"flex"} height={550} justifyContent={"center"}  >
                            <CircularProgress />
                        </Box>
                        :
                        isJupyterLoaded ?
                            <iframe src={jupyterSource} width="100%" title='notebook'
                                height="100%"></iframe>
                            :
                            <Box alignItems={"center"} display={"flex"} height={550} justifyContent={"center"}  >
                                <Grid container>
                                    <Grid item xs={12}><Typography sx={{ textAlign: "center" }}>Error al Cargar JupyterNotebook</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} ><Button variant='contained' onClick={() => { getStudentJupyterNotebookUrl(chapterId, courseId, contentId, codelessonId, username) }} >Recargar <ReplayIcon></ReplayIcon> </Button></Grid>
                                </Grid>
                            </Box>
                : null
            }
        </>
    )
}