import { allTypeData } from "../enums/typeContent.enum"
import { Dialog,DialogContent } from '@mui/material';
import { Chapter } from "./Chapter";
import { Content } from "./Content";
type Props = {
  openEdit: boolean,
  setOpenEdit:Function,
  typeEditSelected:any,
  editObject:any,
  setEditObject:Function,
  getAlldata:Function,
  courseId:any,
  handleUpdateSnackbar: Function,
  handleOpenSuccess: Function,
  handleOpenError: Function,
  selectedChapterId:any

}
export function TreeviewEdit({openEdit,setOpenEdit,typeEditSelected,editObject,setEditObject,getAlldata,courseId,handleUpdateSnackbar, handleOpenSuccess, handleOpenError,selectedChapterId}:Props){
    return(
        <>
        {<Dialog open={openEdit} onClose={()=>{setOpenEdit(false)} } maxWidth="xl" >
        <DialogContent>
          {typeEditSelected === allTypeData.Chapter ?
            <Chapter getChaptersByCourse={getAlldata} item={editObject} courseId={courseId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} treeview={true} handleClose={setOpenEdit} setEditObject={setEditObject} />
            :
            typeEditSelected === allTypeData.Content ? <Content item={editObject} chapterId={selectedChapterId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} getContentByChapter={getAlldata} treeview={true} handleClose={setOpenEdit} setEditObject={setEditObject}></Content>:null
              
          }
        </DialogContent>

      </Dialog>}
        </>
    )
}