import { useState, Fragment, useEffect, useContext } from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Collapse,
  Grid,
  Radio,
  Tooltip,
  Toolbar,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MetadataApi from "../apis/metadata.api";
import { AxiosError, AxiosResponse } from "axios";
import { myContext } from "../contexts/Context";
import { Box } from "@mui/system";
import { CourseDialog } from "./CourseDialog";
import { SnackBarSuccess } from "./SnackBarSuccess";
import { SnackBarError } from "./SnackBarError";
import OpenDialog from "./OpenDialog";
export default function TableCourses(props: any) {
  const ctx = useContext(myContext);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const handleUpdateSnackbar = (action: string) => {
    setSnackbarMessage(action);
  };
  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };
  const handleOpenError = () => {
    setOpenError(true);
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleCloseError = () => {
    setOpenError(false);
  };
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("");
  const [selectedCourseId, setSelectedCourseId] = useState<any>();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
  };

  const emptyRows =
    props.page >= 0
      ? Math.max(0, (1 + props.page) * rowsPerPage - props.data.length)
      : 0;

  const handlerChangeState = (courseId: number) => {
    const token = localStorage.getItem("token");
    MetadataApi.put(
      "courses/updatestate/" + courseId,
      { creatorUser: ctx.username, isProject: props.isProject },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res: AxiosResponse) => {
        if (props.isProject) {
          handleUpdateSnackbar("Proyecto Publicado");
        } else {
          handleUpdateSnackbar("Curso Publicado");
        }
        props.getCourses(props.isProject);
        handleOpenSuccess();
      })
      .catch((error: AxiosError) => {
        if (props.isProject) {
          handleUpdateSnackbar("Publicar Proyecto ");
        } else {
          handleUpdateSnackbar("Publicar Curso ");
        }
        handleOpenError();
      });
  };
  const publishCourse = (course: any) => {
    let courseToPublish = course;
    courseToPublish["isNewCourse"] = true;
    courseToPublish["creatorUser"] = ctx.username;

    const token = localStorage.getItem("token");
    MetadataApi.put(
      "courses/publish/",
      {
        courseToPublish,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res: AxiosResponse) => {
        if (props.isProject) {
          handleUpdateSnackbar("Proyecto Publicado");
        } else {
          handleUpdateSnackbar("Curso Publicado");
        }
        handleOpenSuccess();
        props.getCourses(props.isProject);
      })
      .catch((error: AxiosError) => {
        if (props.isProject) {
          handleUpdateSnackbar("Publicar Proyecto ");
        } else {
          handleUpdateSnackbar("Publicar Curso ");
        }
        handleOpenError();
      });
  };
  const handleChange = (value: string, id: number) => {
    setSelectedRadioButton(value);
    setSelectedCourseId(id);
  };
  function VersionTable(props: any) {
    const [open, setOpen] = useState(false);
    const [creation, setCreation] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const createDuplicate = (course: any) => {
      setIsCreating(true);
      const token = localStorage.getItem("token");
      MetadataApi.post(
        "courses/duplicatecourse/" + course.courseId,
        {
          username: ctx.username,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res: AxiosResponse) => {
          props.getCourses(props.isProject);

          if (props.isProject) {
            handleUpdateSnackbar("Borrador de Proyecto Creado");
          } else {
            handleUpdateSnackbar("Borrador de Curso Creado");
          }
          handleOpenSuccess();
          setIsCreating(false);
        })
        .catch((error: AxiosError) => {
          if (props.isProject) {
            handleUpdateSnackbar("Creación de Borrador de Proyectos ");
          } else {
            handleUpdateSnackbar("Creación de Borrador de Cursos ");
          }

          handleOpenError();
          setIsCreating(false);
        });
    };
    useEffect(() => {
      if (props.row.draft) {
        setCreation(true);
      }
      for (let data of props.row.version_history) {
        if (data.draft) {
          setCreation(true);
        }
      }
    }, [props.row.draft, props.row.version_history]);

    return (
      <Fragment>
        <TableRow hover tabIndex={-1} key={props.row.name}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            <Radio
              checked={selectedRadioButton === props.row.name}
              onChange={() => {
                handleChange(props.row.name, props.row.courseId);
              }}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
          </TableCell>
          <TableCell align="left">{props.row.name}</TableCell>
          <TableCell align="left">
            {props.row.draft ? "Borrador" : props.row.version}
          </TableCell>
          <TableCell align="left">
            {props.row.isActive ? "Publicado" : "Despublicado"}
          </TableCell>
          <TableCell align="left">
            <CourseDialog
              getCourses={props.getCourses}
              item={props.row}
              content={true}
              isProject={props.isProject}
              handleOpenSuccess={handleOpenSuccess}
              handleOpenError={handleOpenError}
              handleUpdateSnackbar={handleUpdateSnackbar}
            />

            {props.row.draft ? (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  publishCourse(props.row);
                }}
              >
                Crear
              </Button>
            ) : props.row.isActive ? (
              <Button
                onClick={() => {
                  handlerChangeState(props.row.courseId);
                }}
                size="small"
                variant="outlined"
                color="primary"
              >
                Despublicar
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handlerChangeState(props.row.courseId);
                }}
                size="small"
                variant="outlined"
                color="primary"
              >
                Publicar
              </Button>
            )}

            <OpenDialog courseId={props.row.courseId}></OpenDialog>
          </TableCell>
        </TableRow>
        {/* ----------TABLA HISTORICA--------- */}
        <TableRow sx={{ backgroundColor: "#dddddd" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography variant="h6" gutterBottom component="div">
                      Versiones históricas
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {isCreating ? (
                      <Button
                        variant="contained"
                        disabled={true}
                        onClick={() => {
                          createDuplicate(props.row);
                        }}
                      >
                        Creando borrador
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={creation}
                        onClick={() => {
                          createDuplicate(props.row);
                        }}
                      >
                        Crear borrador
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Nombre</TableCell>
                      <TableCell align="left">Versión</TableCell>
                      <TableCell align="left">Estado</TableCell>
                      <TableCell align="left">Opciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.row.version_history.map((versionsRow: any) => {
                      return (
                        <TableRow key={versionsRow.name}>
                          <TableCell align="left" sx={{ width: "20%" }}>
                            {versionsRow.name}
                          </TableCell>

                          <TableCell align="left">
                            {versionsRow.draft
                              ? "Borrador"
                              : versionsRow.version}
                          </TableCell>
                          <TableCell align="left">
                            {versionsRow.isActive
                              ? "Publicado"
                              : "Despublicado"}
                          </TableCell>

                          <TableCell align="left">
                            <CourseDialog
                              id_group={props.row.id_group}
                              getCourses={props.getCourses}
                              item={versionsRow}
                              isProject={props.isProject}
                              versionRow={true}
                              content={true}
                              handleOpenSuccess={handleOpenSuccess}
                              handleOpenError={handleOpenError}
                              handleUpdateSnackbar={handleUpdateSnackbar}
                            />

                            {versionsRow.draft ? (
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  publishCourse(versionsRow);
                                }}
                                color="primary"
                                sx={{ marginLeft: 1 }}
                              >
                                Crear
                              </Button>
                            ) : versionsRow.isActive ? (
                              <Button
                                sx={{ marginLeft: 1 }}
                                onClick={() => {
                                  handlerChangeState(versionsRow.courseId);
                                }}
                                size="small"
                                variant="outlined"
                                color="primary"
                              >
                                Despublicar
                              </Button>
                            ) : (
                              <Button
                                sx={{ marginLeft: 1 }}
                                onClick={() => {
                                  handlerChangeState(versionsRow.courseId);
                                }}
                                size="small"
                                variant="outlined"
                                color="primary"
                              >
                                Publicar
                              </Button>
                            )}

                            <OpenDialog
                              courseId={versionsRow.courseId}
                            ></OpenDialog>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  return (
    <>
      <Toolbar>
        <Grid container>
          <Grid item xs={9}></Grid>
          <Grid item xs={3} alignItems={"right"}>
            <CourseDialog
              getCourses={props.getCourses}
              add={true}
              isProject={props.isProject}
              handleOpenSuccess={handleOpenSuccess}
              handleOpenError={handleOpenError}
              handleUpdateSnackbar={handleUpdateSnackbar}
            />
            <Tooltip title="Filter list">
              <Button
                size="large"
                onClick={() => {
                  props.handleSwapTable(true, selectedCourseId);
                }}
                startIcon={<KeyboardArrowUpIcon />}
              />
            </Tooltip>
            <Tooltip title="Filter list">
              <Button
                size="large"
                onClick={() => {
                  props.handleSwapTable(false, selectedCourseId);
                }}
                startIcon={<KeyboardArrowDownIcon />}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer>
        <Table
          sx={{ minHeight: 400, height: 400 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Versión</TableCell>
              <TableCell align="left">Estado</TableCell>
              <TableCell align="left">Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(
                props.page * rowsPerPage,
                props.page * rowsPerPage + rowsPerPage
              )
              .map((row: any, index: number) => {
                return (
                  <VersionTable
                    key={row.name}
                    row={row}
                    getCourses={props.getCourses}
                    isProject={props.isProject}
                  ></VersionTable>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 70 * emptyRows,
                }}
              ></TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <SnackBarSuccess
        message={snackbarMessage}
        handleCloseSuccess={handleCloseSuccess}
        open={openSuccess}
      />
      <SnackBarError
        message={snackbarMessage}
        handleCloseError={handleCloseError}
        open={openError}
      />
    </>
  );
}
