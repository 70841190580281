import { TextField, Button, MenuItem, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import { ContentType } from '../enums/typeContent.enum';
export function Content(props: any) {

    const [content, setContent] = useState({
        contentId: 0,
        chapterId: props.chapterId,
        name: "",
        type: "",
        order: 0,
    });

    //open modal method

    const fillContentData = useCallback((data: any) => {
        const newContent =
        {
            contentId: props.item.contentId,
            chapterId: props.item.chapterId,
            name: props.item.name,
            type: props.item.type,
            order: props.item.order,
        }
        setContent(newContent)


    }, [props.item])
    const handleClose=()=>{
        props.handleClose(false)
    }

    useEffect(() => {
       
        if (props.add !== true) {
            fillContentData(props.item)
        }

    }, [props.add,fillContentData,props.item])

    //modify values by the input value
    const handleInputChange = (e: any) => {
        setContent({
            ...content,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const token = localStorage.getItem('token');
        const sendObject={contentId: content.contentId,
            chapterId: props.chapterId,
            name: content.name,
            type: content.type,
            order: content.order,}
        MetadataApi.post("contents/addcontent",sendObject, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            if (props.treeview) {
                props.setEditObject(sendObject)
                props.getContentByChapter()
            } else {
                props.getContentByChapter(props.chapterId)
            }

            if (props.add) {
                props.handleUpdateSnackbar("Contenido creado")
            } else {

                props.handleUpdateSnackbar("Contenido editado")
            }
            props.handleOpenSuccess()
            handleClose()
            
        }).catch((reason: AxiosError) => {
            if (props.add) {

                props.handleUpdateSnackbar("Crear Contenido")
            } else {

                props.handleUpdateSnackbar("Editar Contenido")
            }
            props.handleOpenError()
        })

    }


    return (
        <>
        <form onSubmit={handleSubmit}> 
            <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="name"
                label="Nombre del Contenido"
                type="text"
                value={content.name}
                onChange={handleInputChange}
                fullWidth
                variant="standard"
            />
            <TextField
                required
                autoFocus
                margin="dense"
                id="type"
                name="type"
                onChange={handleInputChange}
                label="Tipo de contenido"
                value={content.type}
                select
                fullWidth
                variant="standard"
            >{Object.values(ContentType).map((value: string) => (
                <MenuItem key={value} value={value}>
                    {value}
                </MenuItem>
            ))}</TextField>
            {props.treeview ?

                <Grid sx={{ marginTop: 2, textAlign: "right" }}>

                    {props.add ? <>
                    <Button variant='outlined' onClick={handleClose} sx={{ marginRight: 1 }} >Cerrar</Button><Button variant='contained' type='submit' >Crear</Button></> :
                        <Button variant='contained' onClick={handleSubmit} >Guardar</Button>}
                </Grid>
                :
                <Grid sx={{ marginTop: 2, textAlign: "right" }}>
                    {props.add? <>
                    <Button variant='outlined' onClick={handleClose} sx={{ marginRight: 1 }}>Cerrar</Button><Button variant='contained' type='submit' sx={{ marginRight: 1 }}>Crear</Button></>
                        :
                        <><Button variant='outlined' onClick={handleClose} sx={{ marginRight: 1 }}>Cerrar</Button> <Button variant='contained'  type='submit'sx={{ marginRight: 1 }}>Guardar</Button></>}
                    
                </Grid>}

                </form>

        </>
    )
}