
import { useEffect, useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { Grid, Typography, Container, Tab, Tabs, Box } from '@mui/material';

import TableSkeleton from '../components/TableSkeleton';
import { TableProgress } from '../components/progress/TableProgress';
export function Progress(props: any) {
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [select, setSelect] = useState<number>(0)
    const [page, setPage] = useState(0);
    const [arrayZeros, setArrayZeros] = useState<any[]>([])
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const getData = (route: string,tab:number) => {
        const token = localStorage.getItem('token');
        MetadataApi.get(route, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            let length: any[] = []
            res.data.forEach((element: any) => {
                length.push(0)
            })
            setArrayZeros(length)
            setData(res.data)
            
            if(tab===0){
                setSelect(0)
            }else{
                setSelect(1)
            }
            setPage(0)
            setLoading(false)
        })
    }
    const changeSelect = (event: React.SyntheticEvent, newValue: number) => {
        getData(getRoute(newValue),newValue)
    }

    const getRoute=(value:number)=>{
        if(value===1){
            return "learnCourses/getallcourses"
        }else{
            return "learnPrograms/getallprograms"
        }
    }
    useEffect(() => {
        const route = "learnPrograms/getallprograms"
        getData(route,0)
    }, [])

  /*   useEffect(() => {
        setLoading(true)
        setPage(0)
        let route = ""
        if (select === 0) {
            route = "learnPrograms/getallprograms"
        } else if (select === 1) {
            route = "learnCourses/getallcourses"
        }
        getData(route,)
    }, [select]) */

    return (
        <Container className='centered'>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item>
                    <Typography variant='h4' alignItems='center'>Progreso de los Usuarios</Typography>

                </Grid>
            </Grid>
            <Box sx={{ borderColor: 'divider' }}>
                <Tabs value={select} onChange={changeSelect} aria-label="basic tabs example">
                    <Tab label="Programas" value={0} />
                    <Tab label="Cursos" value={1} />
                </Tabs>
            </Box>
            {!loading ?
                <Grid >
                    {select === 0 ? <TableProgress zeros={arrayZeros} data={data} page={page} program={true} handleChangePage={handleChangePage} setPage={setPage} ></TableProgress>
                        : select === 1 ? <TableProgress zeros={arrayZeros} data={data} page={page} program={false} handleChangePage={handleChangePage} setPage={setPage}></TableProgress>
                            : null}
                </Grid>
                :
                <Grid >
                    <TableSkeleton ></TableSkeleton>
                </Grid>

            }

        </Container>
    )
}