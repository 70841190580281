import React from 'react';

export default function HomePage () {
    return (
        <div className="centered">
            HomePage Here!
                
        </div>

    )
}