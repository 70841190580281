import { Grid, Button, Card, TextField } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';

import { Wysiwyg } from './Wysiwyg';
import { SaveButton } from './SaveButton';

export function Readinglesson(props: any) {

    const [isCreating,setIsCreating]=useState<boolean>(false)
    const [editorValue, setEditorValue] = useState('<p><br></p>');
    const [initialTitle, setInitialTitle] = useState<string>("")
    const [initialEditor, setInitialEditor] = useState<string>("<p><br></p>")
    const [readinglesson, setReadinglesson] = useState({
        contentId: 0,
        id: 0,
        text: "",
        order: 0,
        title: ""
    });
    const fillReadingData = useCallback((data: any) => {
        setInitialTitle(data.title)
        setInitialEditor(data.text)
        setEditorValue(data.text)
        const newReading = {
            contentId: data.contentId,
            id: data.id,
            text: data.text,
            order: data.order,
            title: data.title,
        }
        setReadinglesson(newReading)
    }, [])
    const getReadingData = useCallback(() => {
        const token = localStorage.getItem('token');
        MetadataApi.get("readinglesson/getreadinglessonbyid/" + props.id, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            fillReadingData(res.data)

        })
    }, [props.id, fillReadingData])

    useEffect(() => {
        if (props.add !== true) {
            getReadingData()
        }

    }, [props.add, getReadingData])

    const saveReading = useCallback(() => {
        setIsSaving(true)
        setIsCreating(true)
        const token = localStorage.getItem('token');
        MetadataApi.post("readinglesson/addreadinglesson", {
            contentId: props.contentId,
            id: readinglesson.id,
            text: editorValue,
            title: readinglesson.title,
            order: readinglesson.order,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setUnsavedChanges(false)
            setFirstSaveDone(true)
            setInitialTitle(readinglesson.title)
            setInitialEditor(editorValue)
            props.setCloseDialog(false)
            /*    if (!props.treeview) {
                   props.setCloseDialog(false)
               } */
            props.getData()
            if (props.add) {
                props.handleUpdateSnackbar("Readinglesson creado")
            } else {

                props.handleUpdateSnackbar("Readinglesson editado")
            }
            setTextChanged(false)
            setIsSaving(false)
            setIsCreating(false)
            props.handleOpenSuccess()
            /* handleClose() */
        }).catch((reason: AxiosError) => {
             setIsSaving(false)
            if (props.add) {

                props.handleUpdateSnackbar("Crear Readinglesson")
            } else {

                props.handleUpdateSnackbar("Editar Readinglesson")
            }
            setIsCreating(false)
            props.handleOpenError()
        })
    }, [editorValue,props,readinglesson])
    const createReadingLesson = (e: any) => {
        e.preventDefault()
        saveReading()

    }



    //close modal method
    const handleClose = () => {
        setReadinglesson(
            {
                ...readinglesson, contentId: props.contentId,
                id: 0,
                text: "",
                order: 0,
                title: ""
            }
        )
        setInitialTitle("")
        setInitialEditor("<p><br></p>")
        setEditorValue("")
        props.setCloseDialog(false)
    };
    //modify values by the input value




    const handleInputChange = (e: any) => {
        setReadinglesson({
            ...readinglesson,
            [e.target.name]: e.target.value
        })
    }
    const handleOpenDiscart = () => {

        if (initialEditor === editorValue && initialTitle === readinglesson.title) {
            handleClose()
        } else {
            props.handleOpenDiscart()
        }
    }

    const customReactQuill = () => {

        let toolbarReadinglesson = "readinglesson"
        if (props.repeated) {
            toolbarReadinglesson = "questioreadinglessontreeview"
        }

        return (

            < Wysiwyg toolbarName={toolbarReadinglesson} height={props.height} value={editorValue} change={setEditorValue} courseId={props.courseId} />

        );
    }
    const [firstSaveDone, setFirstSaveDone] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [textChanged, setTextChanged] = useState<boolean>(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    useEffect(() => {
        if (unsavedChanges && !props.add) {
            const saveTimeout = setTimeout(() => {
                saveReading()
                setUnsavedChanges(false);
            }, Number(process.env.REACT_APP_AUTOSAVE_TIMER) || 60000);

            return () => clearTimeout(saveTimeout);
        }
    }, [unsavedChanges, saveReading, props.add]);

    useEffect(() => {
        if (editorValue === initialEditor&&initialTitle === readinglesson.title) {

            setUnsavedChanges(false)
            setTextChanged(false)
        } else {
            setTextChanged(true)
            setUnsavedChanges(true)
        }

    }, [editorValue, initialEditor, initialTitle,readinglesson.title])

    useEffect(() => {
        const handleUnload = (event: any) => {
            if (unsavedChanges) {
                event.preventDefault();

            }
        };
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [unsavedChanges]);
    return (<>
        <Grid container sx={{ width: "100%" }}>
            <form onSubmit={createReadingLesson} style={{ width: "100%" }} >


                <Grid item xs={12}>
                    <TextField
                        required
                        autoFocus
                        margin="dense"
                        id="title"
                        name="title"
                        label="Título"
                        type="text"
                        value={readinglesson.title}
                        onChange={handleInputChange}
                        fullWidth
                        variant="standard"
                    />
                    <Card>
                        {customReactQuill()}
                    </Card>
                    {props.treeview ?
                        <Grid sx={{ marginTop: 2, textAlign: "right" }}>
                            <SaveButton textChanged={textChanged} isSaving={isSaving} firstSaveDone={firstSaveDone} saveFunction={saveReading}></SaveButton>
                        </Grid>
                        :
                        <Grid sx={{ marginTop: 2, textAlign: "right" }}>
                            <Button variant='outlined' onClick={handleOpenDiscart} sx={{ marginRight: 1 }}>Cerrar</Button> <Button variant='contained' disabled={isCreating} onClick={createReadingLesson} sx={{ marginRight: 1 }}>{props.add ? "Crear" : "Guardar"}</Button>
                        </Grid>}

                </Grid>



            </form>
        </Grid>
    </>
    )
}