import { DialogContentText, DialogContent, DialogTitle, Dialog, Button, } from '@mui/material';
import { useState } from 'react';

import { Chapter } from './Chapter';

export function ChapterDialog(props: any) {
    
    const [open, setOpen] = useState<boolean>(false);
    const [chapter, setChapter] = useState({
        chapterId: 0,
        courseId: 0,
        name: "",
        description: "",
        isActive: true,
        order: 0,
    });

    //open modal method
    const handleClickOpen = () => {

        if (props.add !== true) {

            setChapter(
                {
                    ...chapter, chapterId: props.item.chapterId,
                    courseId: props.item.courseId,
                    name: props.item.name,
                    description: props.item.description,
                    isActive: true,
                    order: props.item.order,
                }
            )
        } else {
            setChapter({
                ...chapter, courseId: props.courseId
            })
        }

        setOpen(true);
    };
    //close modal method
    const handleClose = () => {
        setChapter(
            {
                ...chapter, chapterId: 0,
                courseId: props.courseId,
                name: "",
                description: "",
                isActive: true,
                order: 0,
            }
        )
        setOpen(false);
    };
    //modify values by the input value


    return (
        <>
            <Button onClick={handleClickOpen} size="small" variant='contained' color='primary'>
                {props.add ? "Crear nuevo" : "Editar"}
            </Button>
            {<Dialog open={open} onClose={handleClose}>
                <DialogTitle>{props.add ? "Crear Capítulo" : "Editar Capítulo"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Complete el siguiente formulario para añadir un nuevo capítulo al sistema
                    </DialogContentText>
                    <Chapter add={props.add} handleClose={setOpen} chapterId={props.chapterId} courseId={props.courseId} handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenError} handleUpdateSnackbar={props.handleUpdateSnackbar} getChaptersByCourse={props.getChaptersByCourse} item={props.item}></Chapter>
                </DialogContent>

            </Dialog>}
            
        </>
    )
}