import { useEffect, useState } from 'react';

import { Grid, Typography} from '@mui/material';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';

import TableProgram from '../components/TableProgram';
import TableSkeleton from '../components/TableSkeleton';

export default function Program () {

    const [program, setProgram] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [creation, setCreation] = useState(false)
    const changeCreation=()=>{
      setCreation(false)
    }

    const swapObjects = (up: boolean, auxArray: object[], index1: number) => {
        if (up) {
          if (index1 !== 0) {
            let index2 = index1 - 1
            let val1 = auxArray[index2]
            let val2 = auxArray[index1]
            auxArray[index1] = Object.assign({}, val1)
            auxArray[index2] = Object.assign({}, val2)
          }
        } else {
          if (index1 !== auxArray.length - 1) {
            let index2 = index1 + 1
            let val1 = auxArray[index2]
            let val2 = auxArray[index1]
            auxArray[index1] = Object.assign({}, val1)
            auxArray[index2] = Object.assign({}, val2)
          }
        }
        const newArray = program.map((element:any, i:number) => {
          return element = auxArray[i]
        })
        return newArray
      }
      const handleSwapTable = (up: boolean, selectedId: number) => {
        let auxArray = [...program]
        let index1 = 0
        for (let i = 0; i < program.length; i++) {
          if (program[i].programId === selectedId) {
            index1 = i
            break
          }
        }
        const newProgramArray=swapObjects(up, auxArray, index1)
        setProgram(newProgramArray);
        handdleUpdateOrder(newProgramArray)
      }
      const handdleUpdateOrder = (newProgramArray:any) => {
        let contador = 1
        let programUpdate: any[] = []
        for (const data of newProgramArray) {
          programUpdate.push({ id: data.programId, order: contador })
          for(const version of data.version_history){
            programUpdate.push({ id: version.programId, order: contador })
          }
          contador++
        }
        const token = localStorage.getItem('token');
        MetadataApi.put("programs/updateprogramsorder/", {
          programUpdate
        }, {
          headers: ({
            Authorization: 'Bearer ' + token
          })
        }).then((res: AxiosResponse) => {
        })
      }

    //crear metodo y pasar al props, "usecontext" 
    const getPrograms=()=>{
        const token = localStorage.getItem('token');
        MetadataApi.get("programs/getallprogramscreator", {
            headers: ({
            Authorization: 'Bearer ' + token
            })
        }).then((res : AxiosResponse) => {
            setProgram(res.data)
            setLoading(false) 
            for(let data of res.data){
                if(data.draft){
                    setCreation(true)
                }
                break
            }
        }, () => {
            window.location.href = "/"
        })
    }
    useEffect(() => {
        setLoading(true);
        getPrograms();
            
    },[])
    
    useEffect(()=>{
        changeCreation()
    },[program.length])
    return (
        <Grid sx={{marginLeft:2,marginRight:2,marginTop:1}}>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item>
                    <Typography variant='h4' alignItems='center'>Listado de Programas</Typography>
                    
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" pb={3}>
            </Grid>
            {!loading ?
                <Grid >
                    <TableProgram data={program} getPrograms={getPrograms} handleSwapTable={handleSwapTable}creation={creation}></TableProgram>
              
                </Grid>
            : 
                <Grid >
                    <TableSkeleton ></TableSkeleton>
                </Grid>
                
            }
            
        </Grid>
        
    );
    
}