import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
/* import Paper from "@mui/material/Paper"; */
import Tooltip from "@mui/material/Tooltip";

import { useState } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';
import { ContentDialog } from "./ContentDialog";
import { SnackBarSuccess } from "./SnackBarSuccess";
import { SnackBarError } from "./SnackBarError";


export default function TableContent(props: any) {

  const [selectedContentId, setSelectedContentId] = useState<number>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("");
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")

  const handleUpdateSnackbar = (message: string) => {
    setSnackbarMessage(message)
  }
  const handleOpenSuccess = () => {
    setOpenSuccess(true)
  }
  const handleOpenError = () => {
    setOpenError(true)
  }
  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const handleCloseError = () => {
    setOpenError(false)
  }




  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows =
    page >= 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  const handleChange = (value: string, id: number) => {
    setSelectedRadioButton(value)
    setSelectedContentId(id)
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Paper>
      <Toolbar >
        <Grid container>
          <Grid item xs={8}>
            <Typography

              variant="h5"
              id="tableTitle"

            >
              Contenidos
            </Typography>
          </Grid>
          <Grid item xs={4} alignItems={"right"}>
            <ContentDialog getContentByChapter={props.getContentByChapter} add={true} chapterId={props.chapterId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
            <Tooltip title="Filter list">
              <Button size='large' onClick={() => { props.handleSwapTable(true, selectedContentId) }} startIcon={<KeyboardArrowUpIcon />} />
            </Tooltip>
            <Tooltip title="Filter list">
              <Button size='large' onClick={() => { props.handleSwapTable(false, selectedContentId) }} startIcon={<KeyboardArrowDownIcon />} />
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer sx={{ width: "100%", height: "100%" }}>
        <Table
          sx={{ height: 400 }}
          aria-labelledby="tableTitle"
        >
          <TableHead >
            <TableRow >
              <TableCell  ></TableCell>
              <TableCell align='left'>Nombre</TableCell>
              <TableCell align='left'>Tipo</TableCell>
              <TableCell align='left'>Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                return (
                  <TableRow
                    sx={{ height: 30 }}
                    hover
                    role="button"
                    onClick={() => { handleChange(row.name, row.contentId) }}
                    tabIndex={-1}
                    key={row.contentId}
                  >
                    <TableCell sx={{ height: 30 }}><Radio sx={{ height: 30 }}
                      checked={selectedRadioButton === row.name}
                      onChange={() => { handleChange(row.name, row.contentId) }}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}

                    /></TableCell>
                    <TableCell sx={{ height: 30 }} align="left" >{row.name}</TableCell>
                    <TableCell sx={{ height: 30 }} align="left" >{row.type}</TableCell>
                    <TableCell sx={{ height: 30 }} align="left">{<ContentDialog getContentByChapter={props.getContentByChapter} item={row} add={false} contentButton={true} courseId={props.courseId} chapterId={props.chapterId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (64) * emptyRows
                }}
              >
                <TableCell colSpan={1} />
              </TableRow>
            )}
          </TableBody>


        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />
      </Paper>
      
    </Box>
  );
}