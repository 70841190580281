
import { AxiosResponse } from 'axios';
import React, { useState, Fragment, useContext, useEffect, useRef } from 'react';

import { Box, Dialog, Fab, Grid, IconButton, List, Tab, Tabs, Typography } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MetadataApi from '../../apis/metadata.api';
import { Chat } from './Chat';
import { myContext } from '../../contexts/Context';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import CloseIcon from '@mui/icons-material/Close';
import { ChatLessonsTabs } from './ChatLessonTabs';
import { ContentType } from '../../enums/typeContent.enum';
import { Role } from '../../enums/roles.enums';
import { ChatStatusDialog } from './ChatStatusDialog';
import { ChatStatus } from '../../enums/chatStatus.enum';

type Props = {
    contentId: number,
    codelessonId: number,
    username: string,
    content_name: string,
    codelesson_name: string,
    courseId: number,
    course_name: string,
    getAllChats: Function,
    type_lesson: string,
    chapterId: number,
    tabValue: ChatStatus,
    handleOpenSuccess:Function,
    handleOpenError:Function,
    handleUpdateSnackbar:Function
 


}
export function ChatDialog({ contentId, codelessonId, username, content_name, codelesson_name, courseId, course_name, getAllChats, type_lesson, chapterId, tabValue,handleOpenError,handleOpenSuccess,handleUpdateSnackbar }: Props) {
    const socket = useContext(WebSocketContext)
    const boxRef = useRef<HTMLDivElement | null>(null);
    const ctx = useContext(myContext)
    const [select, setSelect] = useState<number>(0)
    const [chat, setChat] = useState<any>([])
    const [open, setOpen] = useState<boolean>(false)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [isTeacherWriting, setIsTeacherWriting] = useState<boolean>(false)
  

    useEffect(() => {
        if(socket){
            socket.on('onMessageStudent', (data) => {
                if (open) {
                    data["user_type"] = Role.User
                    data["date"] = new Date().toJSON()
                    setChat((oldChat: any) => [...oldChat, data])
                }
            })
            socket.on('onWriting', (data) => {
                if (open && data.contentId === contentId && data.codelessonId === codelessonId) {
                    setIsTeacherWriting(data.writing)
                    if (boxRef.current) {
                        // Scroll to the bottom when content changes
                        boxRef.current.scrollTop = boxRef.current.scrollHeight;
                    }
                }
            })
    
            return () => {
                socket.off('connect')
                socket.off('onMessageStudent')
                socket.off('onWriting')
            }
        }
       
    }, [socket, open, codelessonId, contentId, ctx.username])


    const handleOpen = () => {
        setOpen(true)

        const token = localStorage.getItem('token');
        MetadataApi.post("learncomments/getchatuser", {
            contentId: contentId,
            username: username,
            codelessonId: codelessonId,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
            setChat(res.data)
            setIsLoaded(true)
        })
    }
    const handleClose = () => {
        setOpen(false)
        getAllChats(tabValue)

    }

    const changeSelect = (event: React.SyntheticEvent, newValue: number) => {
        setSelect(newValue)
    }

    useEffect(() => {
        if (boxRef.current) {
            // Scroll to the bottom when content changes
            boxRef.current.scrollTop = boxRef.current.scrollHeight;
        }
    }, [chat]);

    return (
        <>
            <Fab size='small' color='success' onClick={handleOpen}>
                <QuestionAnswerIcon />
            </Fab>
            {<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
                <Grid container>
                    <Grid item xs={5.5}>
                        <Box

                            sx={{
                                marginTop: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ marginLeft: 1 }}
                                variant='h6'>{content_name}{codelesson_name !== null ? "_" + codelesson_name : ""}</Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={6.5}>
                        <Box

                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Tabs value={select} onChange={changeSelect} aria-label="basic tabs example">
                                <Tab label="Descripción" value={0} />
                                {type_lesson === ContentType.JupyterLesson ? <Tab label="Notebook" value={1} /> : null}


                            </Tabs>
                            <IconButton onClick={handleClose}>
                                <CloseIcon></CloseIcon>
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={5.5}>
                        <Box sx={{ borderRadius: "10px" }}>
                            <Box sx={{ height: "530px", overflow: "auto", border: "solid", borderWidth: 1 }}>
                                <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                    {/* Content for the first DialogContent */}
                                    {isLoaded ? (
                                        <Box ref={boxRef} sx={{ width: '100%', height: "100%", overflow: "auto" }}>
                                            {chat.length === 0 ? "Conversación no empezada" : (
                                                <List sx={{ width: '100%' }}>
                                                    {chat.map((comment: any, index: number) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                <Chat index={index} comment={comment}></Chat>

                                                            </Fragment>
                                                        )
                                                    })}                                           
                                                </List>
                                            )}
                                            {isTeacherWriting ? <Typography align='right' sx={{ marginRight: 1 }}>
                                                Escribiendo...
                                            </Typography> : null}
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6.5}>

                        <Box height={"90%"} >
                            <ChatLessonsTabs type_lesson={type_lesson} courseId={courseId} contentId={contentId} codelessonId={codelessonId} chapterId={chapterId} tabNumber={select} username={username}></ChatLessonsTabs>
                        </Box>
                    </Grid>
                    <ChatStatusDialog tabValue={tabValue} codelessonId={codelessonId} contentId={contentId} getAllChats={getAllChats} username={username} course_name={course_name} content_name={content_name} setChat={setChat} courseId={courseId} setOpenDialog={setOpen}  handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}></ChatStatusDialog>
                </Grid>
            </Dialog >}
        </>
    )
}