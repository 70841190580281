import { useState, Fragment,useContext } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar } from '@mui/material';

import UserUpdateDialog from './UserUpdateDialog';
import UserRegistration from './UserRegistration';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import CourseSelectUser from './CourseSelectUser';
import { myContext } from '../contexts/Context';
import { SnackBarSuccess } from './SnackBarSuccess';
import { SnackBarError } from './SnackBarError';

type Props = {
  users: any,
  getUsers: Function
}


export default function TableUsers({ users, getUsers }: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const ctx=useContext(myContext)
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")

  const handleUpdateSnackbar = (action: string) => {
    setSnackbarMessage(action)
  }
  const handleOpenSuccess = () => {
    setOpenSuccess(true)
  }
  const handleOpenError = () => {
    setOpenError(true)
  }
  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const handleCloseError = () => {
    setOpenError(false)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows =
    page >= 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const changeUserState = (username: string,isActive:boolean) => {
    const token = localStorage.getItem('token');
    MetadataApi.post("/user/updatestate", {
      username,
      adminuser:ctx.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      if(!isActive){
        handleUpdateSnackbar("Usuario Activado")
      }else{
        handleUpdateSnackbar("Usuario Desactivado")
      }
        handleOpenSuccess()
      getUsers()
    }).catch((reason: AxiosError) => {
      if(!isActive){
        handleUpdateSnackbar("Activar Usuario")
      }else{
        handleUpdateSnackbar("Desactivar Usuario ")
      }

      handleOpenError()
    })
  }
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchItem = event.target.value
    setSearchTerm(searchItem);
    let actualPage = page
    if (searchItem === '') {

      setPage(actualPage);
    } else {
      setPage(0);
    }
  };
  const filteredData = users.filter((item: any) =>
    (item.firstname.toLowerCase().includes(searchTerm.toLowerCase()) || item.lastname.toLowerCase().includes(searchTerm.toLowerCase())) || item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Toolbar>
        <Grid container >
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4} justifyContent={"center"} display={"flex"}>
            <TextField
              label="Buscar"
              value={searchTerm}
              onChange={handleSearch}
              size="small"
              sx={{ height: "35px", display: "flex", justifyContent: "center", alignItems: "center" }}
              margin="normal"
            />
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
            <UserRegistration getUsers={getUsers}  handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}></UserRegistration>
          </Grid>

        </Grid>
      </Toolbar>
      <TableContainer>
        <Table
          sx={{ minHeight: 400, height: 400 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Nombre </TableCell>
              <TableCell align='left'>Apellido </TableCell>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Estado</TableCell>
              <TableCell align='left'>Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user: any, index: number) => {
                  return (
                    <Fragment key={user.name+user.email}>
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={user.name}
                      >
                        <TableCell align="left" >{user.firstname}</TableCell>
                        <TableCell align="left" >{user.lastname}</TableCell>
                        <TableCell align="left" >{user.email}</TableCell>
                        <TableCell align="left" >{user.isActive ? "Activo" : "Inactivo"}</TableCell>
                        <TableCell align="left" sx={{ width: "40%" }} >
                          <UserUpdateDialog userData={user} getUsers={getUsers} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}></UserUpdateDialog>
                          <Button sx={{ ml: 1, width: "100px" }} size='small' variant='outlined' onClick={() => { changeUserState(user.username,user.isActive) }}>{user.isActive ? "Desactivar" : "Activar"}</Button>
                          <CourseSelectUser username={user.username}></CourseSelectUser>
                        </TableCell>
                      </TableRow>
                    </Fragment>

                  );
                })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (75) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />

    </ >
  );
}