import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useState } from 'react';

import { ReviewDialog } from "./ReviewDialog";
export function TableReview(props:any){


  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
  };

  const emptyRows =
    props.page >= 0 ? Math.max(0, (1 + props.page) * rowsPerPage - props.data.length) : 0;
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <Toolbar >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            Proyectos {props.projectStatus}
          </Typography>
        </Toolbar>
        <TableContainer sx={{ width: "100%", height:"100%" }}>
          <Table
            sx={{height:400}}
            aria-labelledby="tableTitle"
          >
            <TableHead >
              <TableRow >
                <TableCell align='left'>Proyecto</TableCell>
                <TableCell align='left'>Sprint</TableCell>
                <TableCell align='left'>Contenido</TableCell>
                <TableCell align='left'>usuario</TableCell>
                <TableCell align='left'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data
                .slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return (
                    <TableRow
                    sx={{height:30}}
                      hover
                      role="button"
                      /* onClick={() => { handleChange(row.name, row.contentId) }} */
                      tabIndex={-1}
                      key={row.contentId}
                    >

                      <TableCell  align="left" >{row.courseName}</TableCell>
                      <TableCell align="left" >{row.chapterName}</TableCell>
                      <TableCell align="left">{row.contentName}</TableCell>
                      <TableCell align="left">{row.firstname} {row.lastname}</TableCell>
                      <TableCell align="left">{<ReviewDialog item={row} pending={props.pending} proyectName={row.courseName} contentId={row.contentId} getPendingProjects={props.getPendingProjects} handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenError} handleUpdateSnackbar={props.handleUpdateSnackbar} username={row.username} courseId={row.courseId} contentName={row.contentName} courseName={row.courseName}/>}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (64) * emptyRows
                  }}
                >
                  <TableCell colSpan={1} />
                </TableRow>
              )}
            </TableBody>
           {/*  <TableFooter>
              <TableRow >
                <TableCell colSpan={3} >
                  <Button size="small" onClick={handdleUpdateOrder} variant="contained">Guardar orden</Button>
                </TableCell>
                <TableCell >
                <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={props.data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </TableCell>
              </TableRow>
              
            </TableFooter> */}
          </Table>
        </TableContainer>
        <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={props.data.length}
                  rowsPerPage={rowsPerPage}
                  page={props.page}
                  onPageChange={props.handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
      </Paper>

    </Box>
  );
}