import { DialogContentText, DialogTitle, Dialog, DialogActions, Button, Grid, Typography } from '@mui/material';
import { useState, Fragment, useEffect } from 'react';
import { ContentStatus } from '../../enums/contentStatus.enum';
import MetadataApi from '../../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { LinearProgress } from "@mui/material";
type Props = {
    data: any,
}
export function ProgressCoursesDialog({ data }: Props) {
    const [open, setOpen] = useState<boolean>(false)
    const [progression, setProgression] = useState<any>([])

    useEffect(() => {
        let zeros: any[] = []
        data.programcourses.forEach((course: any) => {
            zeros.push(0)
        })
        setProgression(zeros)
    }, [data.programcourses])

    useEffect(() => {
        if (open) {
            const token = localStorage.getItem('token');
            MetadataApi.post("learnprograms/getprogrambyid/" + data.programId,{
                username:data.username
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
             
                let progressionArray: number[] = []
                res.data.programcourses.forEach((programcourse: any) => {
                    let contentCounter = 0
                    let progress = 0
                   /*  console.log(programcourse.course.chapters) */
                   if(programcourse.course){
                    programcourse.course.chapters.forEach((chapter: any) => {
                 
                        chapter.contents.forEach((content: any) => {

                            contentCounter++;
                            if (content.status === ContentStatus.Completed || content.status === ContentStatus.Perfect) {
                                progress++;
                            }

                        })
                    })
                   }
                    
                    const finalProgress = Math.round((100 / contentCounter) * progress)
                    if (isNaN(finalProgress)) {
                        progressionArray.push(0)
                    } else {
                        progressionArray.push(finalProgress)
                    }
                })
                setProgression(progressionArray)
               
                
            })
        }
    }, [open, data.programId,data.username])
    return (
        <>
            <Button variant='contained' onClick={() => { setOpen(true) }}>Cursos</Button>
            {<Dialog open={open} onClose={() => { setOpen(false) }} maxWidth="sm" fullWidth>
                <DialogTitle >Progreso de Cursos</DialogTitle>
                <DialogContentText ml={2} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    Resumen del progreso de cada curso perteneciente al programa
                </DialogContentText>
                {data.programcourses.map((course: any, index: number) => {

                    return (
                        <Fragment key={index}>
                            <Grid container display={"flex"} justifyContent={"center"} sx={{marginTop:2}} >
                                <Grid item xs={7}>
                                    <Typography >{course.course !== null ? course.course.name : "Proyecto sin seleccionar"}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <LinearProgress
                                        sx={{ height: 10, borderRadius: 5 }}
                                        variant="determinate"
                                        color="primary"
                                        value={progression[index]} // Pass the progression value here
                                    />
                                       <Typography variant="body2" align="center" color="text.secondary" sx={{ height: 15 }}>
                                        {`${progression[index]}%`}
                                    </Typography>
                                </Grid>
                            
                            </Grid>


                        </Fragment>
                    )
                })}
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }} variant='outlined'>Cerrar</Button>
                   
                </DialogActions>
            </Dialog>}
        </>
    )
}