export enum ContentType {
    //PythonLesson = 'PythonLesson',
    Questions = 'Questions',
    JupyterLesson = 'JupyterLesson',
    Reading='Reading'
  }

export enum allTypeData{
  Questions = 'Questions',
    JupyterLesson = 'JupyterLesson',
    Reading='Reading',
    Chapter='Chapter',
    Content='Content'
}