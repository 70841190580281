import { useState, useEffect, Fragment } from 'react';
import { DialogContentText, DialogContent, DialogTitle, Dialog, DialogActions, Button, Grid, List, Card, CardHeader, ListItem, ListItemText, ListItemIcon, Divider, Checkbox } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AxiosError, AxiosResponse } from 'axios';
import MetadataApi from '../apis/metadata.api';

function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly string[], b: readonly string[]) {
  return [...a, ...not(b, a)];
}

export default function CourseSelect(props: any) {
  const [checked, setChecked] = useState<readonly string[]>([]);
  const [left, setLeft] = useState<readonly any[]>([]);
  const [right, setRight] = useState<readonly any[]>([]);
  const [courses, setCourses] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [obligatoryChecked, setObligatoryChecked] = useState<string[]>([])

  //verify the number of checked to disabled the up and down button
  useEffect(() => {
    if (checked.length === 1) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }

  }, [checked.length])

  //add or remove the items checked 
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleToggleObligatory = (value: string) => () => {
    const currentIndex = obligatoryChecked.indexOf(value);
    const newChecked = [...obligatoryChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setObligatoryChecked(newChecked);
  };

  const numberOfChecked = (items: readonly string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly string[]) => () => {

    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));

    } else {
      setChecked(union(checked, items));
    }
  };
  //update the right list of courses
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    let auxRight = obligatoryChecked
    leftChecked.forEach(checked => {
      auxRight.push(checked[0])
    });

    setObligatoryChecked(auxRight)
  };
  //update the left list of courses
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const handleClickOpen = () => {
    getCourses()
    setOpen(true);
  };
  const handleClose = () => {
    setChecked([])
    resetLeft()
    resetRight()
    setOpen(false);
  };

  //let move the order of courses of the right list of courses
  const handleSwap = (up: boolean) => {
    let auxarray = [...right]
    let auxchecked: string[] = [...checked]
    auxchecked.forEach(element => {
      let index1 = auxarray.indexOf(element)
      if (up) {
        if (index1 !== 0) {
          let index2 = index1 - 1
          let val1 = auxarray[index2]
          auxarray.splice(index2, 1, element)
          auxarray.splice(index1, 1, val1)
        }
      } else {
        if (index1 !== auxarray.length - 1) {
          let index2 = index1 + 1
          let val1 = auxarray[index2]
          auxarray.splice(index2, 1, element)
          auxarray.splice(index1, 1, val1)
        }
      }
    })
    const newArray = right.map((element, i) => {
      return element = auxarray[i]
    })

    setRight(newArray);
  }
  const resetLeft = () => {
    setLeft(left.filter(items => items === ""))

  }
  const resetRight = () => {
    setRight(right.filter(items => items === ""))
  }
  const handleSaveCourse = (save: boolean) => {
    let contador = 1
    let coursesUpdate: any[] = []
    let obligatory = false
    for (const selected of right) {
      for (const course of courses) {
        if (course.name === selected[0]) {

          if (obligatoryChecked.indexOf(course.name) > -1) {
            obligatory = true
          }
          if (props.isProject) {
            obligatory = true
          }

          coursesUpdate.push({ programId: props.item.programId, courseId: course.courseId, order: contador, obligatory: obligatory })
          contador++
          obligatory = false
          break
        }
      }
    }

    const newData = [coursesUpdate, props.isProject]
    const token = localStorage.getItem('token');
    MetadataApi.put("programs/updateprogramcourses/" + props.item.programId, newData, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {

      if(props.isProject){
        props.handleUpdateSnackbar("Lista de Proyectos Editado")

      }else{
        props.handleUpdateSnackbar("Lista de Cursos Editado")
      }
      props.handleOpenSuccess()
      handleClose()
    }).catch((reason:AxiosError)=>{

      if(props.isProject){
        props.handleUpdateSnackbar("Editar Lista de Proyectos ")

      }else{
        props.handleUpdateSnackbar("Editar Lista de Cursos ")
      }
      handleClose()
      props.handleOpenError()
    })
    

  }

  const getCourses = () => {
    const token = localStorage.getItem('token');
    let allCourses: any[] = []
    let coursesById: any[] = []
    let obligatory: any[] = []
    let auxObligatory: any[] = []
    MetadataApi.post("programs/courses/" + props.item.programId, { isProject: props.isProject }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {

      coursesById = (res.data[0])
      allCourses = (res.data[1])
      obligatory = (res.data[2])


      let noTaken = [...allCourses]
      let contador = 0
      allCourses.forEach((course, index) => {
        coursesById.forEach((selected, index2) => {
          if (index === 0) {

            setRight(right => [...right, [String(selected.name), String(selected.version)]])
            if (obligatory[index2]) {
              auxObligatory.push(selected.name)
            }
          }
          if (course.name === selected.name) {
            noTaken.splice(contador, 1)
            contador--
          }
        });
        contador++
        setCourses(courses => [...courses, course])


      });

      setObligatoryChecked(auxObligatory)

      noTaken.forEach(item => {
        setLeft(left => [...left, [String(item.name), String(item.version)]])
      })

    })

  }

  const customList = (title: React.ReactNode, items: readonly string[], side: boolean, width: number) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        action={side ? <div><Button size='large' disabled={disabled} onClick={() => { handleSwap(true) }} startIcon={<KeyboardArrowUpIcon />} /><Button size='large' disabled={disabled} onClick={() => { handleSwap(false) }} startIcon={<KeyboardArrowDownIcon />} /></div> : null}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: width,
          height: 300,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >   <Grid container>
          {items.map((value: string, index: number) => {
            const labelId = `${value[0]}`;
            return (
              <Fragment key={index}>

                <ListItem
                  role="listitem"
                /*  button */
                /* onClick={handleToggle(value)} */
                >
                  <Grid item xs={2}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        onClick={handleToggle(value)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={10}>
                    <ListItemText id={labelId} primary={`${value[0]} (Version ${value[1]})`} />
                  </Grid>

                  {side && !props.isProject ? <>
                    <Grid item xs={1}>

                      <ListItemIcon>
                        <Checkbox
                          checked={obligatoryChecked.indexOf(value[0]) !== -1}
                          onClick={handleToggleObligatory(value[0])}

                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </ListItemIcon>
                    </Grid>
                    <Grid item xs={4}>
                      {side ? <ListItemText id={labelId} primary={`Obligatorio`} /> : null}
                    </Grid>
                  </> : null}
                </ListItem>

              </Fragment>

            );
          })}
        </Grid>
      </List>
    </Card>
  );
  return (
    <>
      {props.versionRow ?
        <Button sx={{ marginLeft: 1 }} onClick={handleClickOpen} size="small" variant='outlined' color='primary'>
          {props.type}
        </Button> :
        <Button sx={{ marginLeft: 1 }} onClick={handleClickOpen} size="small" variant='contained' color='primary'>
          {props.type}
        </Button>
      }
      {<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Gestión de cursos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione los cursos que se presentaran en este programa
          </DialogContentText>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList('Cursos', left, false, 340)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                {!props.item.isActive ? <><Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </> :
                  <>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={true}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={true}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </>}

              </Grid>
            </Grid>
            <Grid item>{customList('Elegidos', right, true, 500)}</Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} variant='outlined'>Cancelar</Button>
            {props.toPublish ? <Button onClick={() => { handleSaveCourse(true) }} variant='contained'>Guardar</Button> : <Button onClick={() => { handleSaveCourse(false) }} variant='contained'>Guardar</Button>}

          </DialogActions>

        </DialogContent>

      </Dialog>}
    </>
  );
}
