import { TextField, DialogContentText, DialogContent, DialogTitle, Dialog, DialogActions, Button, MenuItem, SelectChangeEvent, Chip, OutlinedInput, Box, Select, InputLabel,Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { Difficulties } from '../enums/difficulties.enums';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import validator from 'validator';
import { myContext } from '../contexts/Context';
export function ProgramDialog(props: any) {
  const ctx=useContext(myContext)
  const [urlError, setUrlError] = useState('')
  const [open, setOpen] = useState<boolean>(false);
  /*  const [openDiscart, setOpenDiscart] = useState<boolean>(false); */
  const [allProgramType, setAllProgramType] = useState<readonly string[]>([])
  const [programTypes, setProgramTypes] = useState<string[]>([]);
  const [program, setProgram] = useState({
    programId: 0,
    name: "",
    shortDescription: "",
    Description: "",
    type: "",
    difficulty: "",
    listPrice: 99999999,
    discountPrice: 0,
    isActive: 0,
    order: 0,
    version: 0,
    id_group: 0,
    draft: 1,
    professor_name: "",
    professor_link: "",
    hours:""
  });
  const validateUrl = (url: string) => {
    setProgram({ ...program, professor_link: url })


    if (validator.isURL(url,{protocols:['http','https'],require_protocol:true})) {

      setUrlError('')
    } else {

      setUrlError('Ingresar una url válida! (https o http)  ')
    }
  }
  //open modal method
  const handleClickOpen = () => {

    if (props.add !== true) {
      let active = 0
      if (props.item.isActive) {
        active = 1
      }
      let isDraft = 0
      if (props.item.draft) {
        isDraft = 1
      }

      setProgram(
        {
          ...program, programId: props.item.programId,
          name: props.item.name,
          shortDescription: props.item.shortDescription,
          Description: props.item.Description,
          type: props.item.type,
          difficulty: props.item.difficulty,
          listPrice: props.item.listPrice,
          discountPrice: props.item.discountPrice,
          isActive: active,
          order: props.item.order,
          version: props.item.version,
          id_group: props.item.id_group,
          draft: isDraft,
          professor_name: props.item.professor_name,
          professor_link: props.item.professor_link,
          hours:props.item.hours
        }
      )
      setProgramTypes(props.item.type.split(",").sort())
    }
    setOpen(true);
    getAllTypes()
  };
  const handleChangeType = (event: SelectChangeEvent<typeof programTypes>) => {
    const {
      target: { value },
    } = event;
    setProgramTypes(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  //close modal method
  const handleClose = () => {
    setProgram(
      {
        ...program, programId: 0,
        name: "",
        shortDescription: "",
        Description: "",
        type: "",
        difficulty: "",
        listPrice: 99999999,
        discountPrice: 0,
        isActive: 0,
        order: 0,
        version: 0,
        id_group: 0,
        draft: 1,
        professor_name: "",
        professor_link: "",
        hours:""
      }
    )
    setOpen(false);
  };
  //modify values by the input value
  const handleInputChange = (e: any) => {
    setProgram({
      ...program,
      [e.target.name]: e.target.value
    })
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const token = localStorage.getItem('token');
    let active = false
    let isDraft = false
    let idGroup = program.id_group
    let order = program.order
    let isNewProgram=false
    if (props.order > 0) {
      order = props.order
    }
    if (program.isActive === 1) {
      active = true;
    }
    if (program.draft === 1) {
      isDraft = true
    }
    if (props.id_group > 0) {
      idGroup = props.id_group
    }
    if(props.add){
      isNewProgram=true
    }
    const newTypes = programTypes.sort().join()
    MetadataApi.post("programs/addprogram", {
      programId: program.programId,
      name: program.name,
      shortDescription: program.shortDescription,
      Description: program.Description,
      type: newTypes,
      difficulty: program.difficulty,
      listPrice: program.listPrice,
      discountPrice: program.discountPrice,
      isActive: active,
      order: order,
      version: program.version,
      id_group: idGroup,
      draft: isDraft,
      professor_name: program.professor_name,
      professor_link: program.professor_link,
      hours:program.hours,
      isNewProgram:isNewProgram,
      creatorUser:ctx.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      
      handleClose()
      props.getPrograms()
      props.handleOpenSuccess()
      if(props.add){
        props.handleUpdateSnackbar("Programa Creado")

      }else{
        props.handleUpdateSnackbar("Programa Editado")
      }
      

    }).catch((reason:AxiosError)=>{
      if(props.add){
        props.handleUpdateSnackbar("Crear Programa ")

      }else{
        props.handleUpdateSnackbar("Editar Programa")
      }
      props.handleOpenError(true)
    })

  }
  const getAllTypes = () => {
    const token = localStorage.getItem('token');
    MetadataApi.get("types/getall", {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setAllProgramType(res.data)
    })

  }
  return (
    <>{
      props.versionRow ?
        <Button onClick={handleClickOpen} size="small" variant='outlined' color='primary' disabled={props.creation}>
          {props.add ? props.versions ? "Crear Borrador" : "Crear nuevo" : "Editar"}
        </Button>
        :
        <Button onClick={handleClickOpen} size="small" variant='contained' color='primary' disabled={props.creation}>
          {props.add ? props.versions ? "Crear Borrador" : "Crear nuevo" : "Editar"}
        </Button>
    }
      {<Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.add ? "Crear Programa" : "Editar Programa"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete el siguiente formulario para añadir un nuevo programa al sistema
          </DialogContentText>
          <form onSubmit={handleSubmit} id="form">
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="name"
              label="Nombre del programa"
              type="text"
              value={program.name}
              onChange={handleInputChange}
              fullWidth
              variant="standard"
            />
            <TextField
              required
              autoFocus
              margin="dense"
              id="shortDescription"
              name="shortDescription"
              label="Resumen del programa"
              type="text"
              value={program.shortDescription}
              onChange={handleInputChange}
              fullWidth
              variant="standard"
            />
            <TextField
              required
              autoFocus
              margin="dense"
              id="Description"
              name="Description"
              onChange={handleInputChange}
              label="Descripción del programa"
              multiline
              type="text"
              value={program.Description}
              fullWidth
              variant="standard"
            />
             <TextField
              required
              autoFocus
              margin="dense"
              id="hours"
              name="hours"
              onChange={handleInputChange}
              label="Duración del programa"
              type="text"
              value={program.hours}
              fullWidth
              variant="standard"
            />
            <InputLabel id="demo-multiple-chip-label">Tipos</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              fullWidth
              required
              value={programTypes}
              onChange={handleChangeType}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}

            >
              {allProgramType.map((type) => (
                <MenuItem
                  key={type}
                  value={type}

                >
                  {type}
                </MenuItem>
              ))}
            </Select>
            <TextField
              required
              autoFocus
              margin="dense"
              id="difficulty"
              name="difficulty"
              onChange={handleInputChange}
              label="Dificultad"
              value={program.difficulty}
              select
              fullWidth
              variant="standard"
            >{Object.keys(Difficulties).map((key: any) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}</TextField>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="professor_name"
                  name="professor_name"
                  onChange={handleInputChange}
                  label="Nombre profesor"
                  value={program.professor_name}
                  type='text'
                  fullWidth
                  variant="standard"
                ></TextField>
              </Grid>
              <Grid item xs={6} >
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="professor_link"
                  name="professor_link"
                  label="Link profesor"
                  type="text"
                  value={program.professor_link}
                  error={urlError !== ''}
                  helperText={urlError}
                  onChange={e => { validateUrl(e.target.value) }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleClose} variant='outlined'>Cancelar</Button>

              <Button type="submit" variant='contained'>{props.add ? "Crear" : "Editar"}</Button>
            </DialogActions>
          </form>
        </DialogContent>

      </Dialog>}
      {/* {<Dialog
        open={openDiscart}
        onClose={handleCloseDiscart}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea realmente descargar los cambios realizados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDiscart}>Cancelar</Button>
          <Button onClick={handleAcceptDiscart} autoFocus>
            Descartar
          </Button>
        </DialogActions>
      </Dialog>} */}
    
    </>
    
  )
}
