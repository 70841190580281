
import { Grid, } from '@mui/material';
import { useParams } from 'react-router-dom';
import ChaptersTreeView from '../components/ChaptersTreeView';


export default function TreeChapters() {
    const { courseId } = useParams();
    
    return (
        < Grid container sx={{paddingLeft:2,paddingRight:2,paddingTop:2}}>   
            <Grid item xs={4.5}>
            
            </Grid>                
            <Grid container spacing={1} item xs={12} >
                <Grid item xs={12} >
                    <ChaptersTreeView courseId={courseId} />
                </Grid>
            </Grid>
        </Grid>

    );

}