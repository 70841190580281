
import { useCallback, useEffect, useState } from 'react';
import { Grid,Box, Typography, Drawer, IconButton } from '@mui/material';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import TableSkeleton from './TableSkeleton';
import ListSkeleton from './ListSkeleton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Jupyterlesson } from './Jupyterlesson';
import { Questionlesson } from './Questionlesson';
import { Readinglesson } from './Readinglesson';
import { ContentType } from '../enums/typeContent.enum';
import { SnackBarError } from './SnackBarError';
import { SnackBarSuccess } from './SnackBarSuccess';
import { TreeviewList } from './TreeviewList';


export default function ChaptersTreeView(props: any) {
  const [openCreator, setOpenCreator] = useState<boolean>(false);
  const [type, setType] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [specificContentData, setSpecificContentData] = useState<any>()
  const [chapters, setChapters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [lessonStatus, setLessonStatus] = useState<boolean>(false)
  const [selectedChapterId, setSelectedChapterId] = useState<number>();
  const [selectedContentId, setSelectedContentId] = useState<number>();
  const [typeSelected, setTypeSelected] = useState<string>("Content")
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")
  
  const getAlldata = () => {
    const token = localStorage.getItem('token');
    MetadataApi.get("chapters/getallchaptersbycoursealldata/" + props.courseId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      if (res.data.length > 0) {
         setChapters(res.data)
      }
      setLoading(false)
    })
  }
  const getChaptersByCourse = useCallback(() => {
    const token = localStorage.getItem('token');
    MetadataApi.get("chapters/getallchaptersbycoursealldata/" + props.courseId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      if (res.data.length > 0) {
        setChapters(res.data)
      }
      setLoading(false)
    })
  }, [props.courseId])


  const handleUpdateSnackbar = (message: string) => {
    setSnackbarMessage(message)
  }
  const handleOpenSuccess = () => {
    setOpenSuccess(true)
  }
  const handleOpenError = () => {
    setOpenError(true)
  }
  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const handleCloseError = () => {
    setOpenError(false)
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    MetadataApi.get("courses/coursename/" + props.courseId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {

      if (res.data[1]) {
        setType("Proyecto")
      } else if (!res.data[1]) {
        setType("Curso")
      }
      setName(res.data[0])
    })
  }, [props.courseId])

  useEffect(() => {
    getChaptersByCourse()
  }, [props.courseId, getChaptersByCourse])

  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [openTreeview, setOpenTreeview] = useState(true)
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
    setOpenTreeview(true)
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setTimeout(() => { setOpenTreeview(false) }, 200)
  };
  const openedMixin = (theme: Theme): CSSObject => ({
    width: "30%",
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  }
  );
  const closedMixin = (theme: Theme): CSSObject => ({
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(6)} + 1px)`,
    },
  });
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  ///////LESSONS SECTION///////////
  return (
    <>
      {!loading ? <Box sx={{ display: "flex" }} >

        <Drawer variant="permanent" sx={{
          ...(openDrawer && { ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme), }),
          ...(!openDrawer && { ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme), }),
        }}>
          <DrawerHeader sx={{ marginTop: 8 }}>

            {openDrawer ? <><Typography variant='h6' marginRight={2} marginTop={2}> {type}: {name}</Typography><IconButton onClick={handleDrawerClose}><ChevronLeftIcon /></IconButton></>
              : <IconButton onClick={handleDrawerOpen}><ChevronRightIcon /></IconButton>
            }
          </DrawerHeader>
            <TreeviewList openDrawer={openDrawer} openTreeview={openTreeview} getChaptersByCourse={getChaptersByCourse} courseId={props.courseId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}  chapters={chapters} setTypeSelected={setTypeSelected} typeSelected={typeSelected} setLessonStatus={setLessonStatus} setSpecificContentData={setSpecificContentData} setChapters={setChapters} selectedChapterId={selectedChapterId} setSelectedChapterId={setSelectedChapterId} setSelectedContentId={setSelectedContentId} selectedContentId={selectedContentId} getAlldata={getAlldata}setOpenCreator={setOpenCreator} openCreator={openCreator} ></TreeviewList>
        </Drawer>
        {lessonStatus ?
          (typeSelected === ContentType.JupyterLesson ? <Jupyterlesson openDrawer={openDrawer} height={330} chapterId={selectedChapterId}  courseId={props.courseId} treeview={true} contentId={selectedContentId} item={specificContentData} add={false} getData={getAlldata} id={specificContentData.id} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} setCloseDialog={setOpenCreator}/>
          :
            typeSelected === ContentType.Questions ? <Questionlesson height={490} chapterId={selectedChapterId} courseId={props.courseId} contentId={selectedContentId} id={specificContentData.id} add={false} getData={getAlldata} treeview={true} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} setCloseDialog={setOpenCreator}/>
            :
              typeSelected === ContentType.Reading ? <Readinglesson height={440} chapterId={selectedChapterId} courseId={props.courseId} contentId={selectedContentId} id={specificContentData.id} add={false} getData={getAlldata} treeview={true} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} setCloseDialog={setOpenCreator}/> 
              :
                null
          )
          : null}
      </Box>
        :
        <Box>
          <Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={3.5}><ListSkeleton /></Grid>
              <Grid item xs={8.5}><TableSkeleton /></Grid>
            </Grid>
          </Grid>
        </Box>
      }
      <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />
    </>
  );
}