import { useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton,Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Tooltip, Grid } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { ReadingDialog } from './ReadingDialog';
import { SnackBarSuccess } from './SnackBarSuccess';
import { SnackBarError } from './SnackBarError';


export default function TableReading(props: any) {
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [snackbarMessage,setSnackbarMessage]=useState<string>("")

  const handleUpdateSnackbar=(message:string)=>{
    setSnackbarMessage(message)
  }
  const handleOpenSuccess = () => {
    setOpenSuccess(true)
  }
  const handleOpenError = () => {
    setOpenError(true)
  }
  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const handleCloseError = () => {
    setOpenError(false)
  }
  const [selectedReadingLessonId, setSelectedReadingLessonId] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [readingsLessons, setReadingsLessons] = useState<any[]>([])

  const getReadingsLessonsByContent = () => {
    const token = localStorage.getItem('token');
    MetadataApi.get("readinglesson/getreadinglessonbycontent/"+props.contentId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setReadingsLessons(res.data)
    })
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    getReadingsLessonsByContent()
    setOpen(true);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows =
    page >= 0 ? Math.max(0, (1 + page) * rowsPerPage - readingsLessons.length) : 0;

  const handleChange = (value: string, id: number) => {
    setSelectedRadioButton(value)
    setSelectedReadingLessonId(id)
  }

  const swapObjects = (up: boolean, auxArray: object[], index1: number) => {
    if (up) {
      if (index1 !== 0) {
        let index2 = index1 - 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
      }
    } else {
      if (index1 !== auxArray.length - 1) {
        let index2 = index1 + 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
      }
    }
    const newArray = readingsLessons.map((element, i) => {
      return element = auxArray[i]
    })
    return newArray
  }
  const handleSwapTable = (up: boolean, selectedId: number) => {
    
    let auxArray = [...readingsLessons]
    let index1 = 0
    for (let i = 0; i < readingsLessons.length; i++) {
      if (readingsLessons[i].id === selectedId) {
        index1 = i
        break
      }
    }
    const newReadingArray=swapObjects(up, auxArray, index1)
    setReadingsLessons(newReadingArray);
    handdleUpdateOrder(newReadingArray)
  }
  const handdleUpdateOrder = (array:any) => {
    let contador = 1
    let orderUpdate: any[] = []
    for (const readinglesson of array) {
      orderUpdate.push({ id: readinglesson.id, order: contador })
      contador++
    }
    const token = localStorage.getItem('token');
    MetadataApi.put("readinglesson/updatereadinlessongorder/", {
      orderUpdate
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
    })
  }

  return (
    <>

      <Button onClick={handleClickOpen} size="small" variant='contained' color='primary'>
        Ver Readings
      </Button>
      {<Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogContent>
          <DialogTitle fontSize={30} >Readings

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
         
          <Toolbar >
          <Grid container>
              <Grid  item xs={9.5} >
              </Grid>
              <Grid item xs={2.5} >
              <ReadingDialog getReadingsLessonsByContent={getReadingsLessonsByContent} add={true} contentId={props.contentId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}/>
            <Tooltip title="Filter list">
              <Button size='large' onClick={() => { handleSwapTable(true, selectedReadingLessonId)}} startIcon={<KeyboardArrowUpIcon />} />
            </Tooltip>
            <Tooltip title="Filter list">
              <Button size='large' onClick={() => { handleSwapTable(false, selectedReadingLessonId)}} startIcon={<KeyboardArrowDownIcon />} />
            </Tooltip>
              </Grid>
              </Grid>
         
          </Toolbar>
          <TableContainer sx={{  height:"100%" }}>
            <Table
              sx={ {minHeight: 470, height: 470 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow >
                  <TableCell ></TableCell>
                  <TableCell align='left'>Título</TableCell>
                  <TableCell align='left'>Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {readingsLessons
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="button"
                        onClick={() => { handleChange(row.text, row.id) }}
                        tabIndex={-1}
                        key={row.id}
                        
                      >
                        <TableCell ><Radio
                          checked={selectedRadioButton === row.title}
                          onChange={() => { handleChange(row.title, row.id) }}
                          value="a"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}

                        /></TableCell>
                        <TableCell align="left" >{row.title}</TableCell>
                        <TableCell align="left" ><ReadingDialog getReadingsLessonsByContent={getReadingsLessonsByContent} id={row.id} add={false} contentId={props.contentId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}/></TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows
                    }}
                  >
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
             
            </Table>
            <TablePagination
                      rowsPerPageOptions={[5]}
                      component="div"
                      count={readingsLessons.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
          </TableContainer>

          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>

          </DialogActions>
        </DialogContent>
      </Dialog>}
      <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />
    </ >
  );
}