import {  Fragment, useState } from 'react';
import { ProgressBar } from "./ProgressBar";
import { IconButton, TextField, Toolbar, Box, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Paper, TableContainer, Dialog, DialogContent, Typography, DialogActions, DialogTitle, Button ,Link} from "@mui/material";
import { ProgressView } from "./ProgressView";
import { ProgressCoursesDialog } from "./ProgressCoursesDialog";
import MetadataApi from "../../apis/metadata.api";
import {AxiosResponse } from "axios";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import VideocamIcon from '@mui/icons-material/Videocam';

export function TableProgress(props: any) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open,setOpen]=useState<boolean>(false)
  const [selectedVideos,setSelectedVideos]=useState<any>([])
  const [selectedCourse,setSelectedCourse]=useState<string>("")
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchItem = event.target.value
    setSearchTerm(searchItem);
    let actualPage = props.page
    if (searchItem === '') {

      props.setPage(actualPage);
    } else {
      props.setPage(0);
    }
  };
  const filteredData = props.data.filter((item: any) =>
    item.username.toLowerCase().includes(searchTerm.toLowerCase()) || item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const changeDateFormat = (date: Date) => {
    if (date) {
      const dateObject = new Date(date);
      return dateObject.toISOString().replace("T", " ").replace("Z", " ").replace(".000", " ")
    } else {
      return " "
    }


  }
  const exportExcel = (row: any) => {
    const token = localStorage.getItem('token');
    MetadataApi.post("datalake/exportcourseexcel/" + row.courseId, {
      row
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      const link = document.createElement('a');
      link.href = res.data;
      link.click();
    })
  }
  const openVideos=(row:any,courseName:string)=>{
    const data_formatted:any[]=groupByDate(row)
    setSelectedVideos(data_formatted)
    setSelectedCourse(courseName)
    setOpen(true)
    
  }
  function formatDate(dateString:string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
/*     const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0'); */
 
    return `${year}-${month}-${day}`;
}
function groupByDate(data:any) {
  const grouped:any = {};

  data.forEach((item:any) => {
      const date = new Date(item.creation_date);
      const key = date.toISOString().split('T')[0]; // Obtener solo la parte de la fecha (YYYY-MM-DD)
      
      if (!grouped[key]) {
          grouped[key] = [];
      }

      // Formatear la fecha dentro del objeto antes de agregarlo al grupo
      const formattedItem = {
          ...item,
          creation_date: formatDate(item.creation_date)
      };

      grouped[key].push(formattedItem);
  });

  return Object.values(grouped);
}
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <Toolbar sx={{ alignItems: "flex-start", display: "flex", justifyContent: "center" }} >
          <TextField
            label="Buscar"
            value={searchTerm}
            onChange={handleSearch}
            size="small"
            sx={{ alignSelf: "center", display: "flex" }}
            margin="normal"
          />
        </Toolbar>

        <TableContainer sx={{ width: "100%" }}>
          <Table
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>Usuario</TableCell>
                <TableCell align='left'>{props.program ? "Programa" : "Curso"}</TableCell>
                <TableCell align='left'>Estado</TableCell>
                {props.program ? null : <TableCell align='center'>¿Es Proyecto?</TableCell>}
                <TableCell sx={{ width: "15%" }} align='center'>Avance</TableCell>
                <TableCell align='center'>Fecha último avance</TableCell>
                <TableCell align="center" sx={{ width: "15%" }}>Contenidos</TableCell>
                {props.program ? null : (
                  <>
                    <TableCell align='center'>Exportar</TableCell>
                    <TableCell align='center'>Videos</TableCell>
                  </>
                )}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return (
                    <TableRow
                      sx={{ height: 30 }}
                      hover
                      role="button"
                      key={index}
                    >
                      <TableCell align="left">{row.username}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      {props.program ? null : <TableCell align='center'>{row.isProject ? "Si" : "No"}</TableCell>}
                      <TableCell align="center"><ProgressBar data={row} isProgram={props.program}></ProgressBar></TableCell>
                      <TableCell align="center">{changeDateFormat(row.lastDate)}</TableCell>
                      <TableCell align="center">{props.program ? <ProgressCoursesDialog data={row}></ProgressCoursesDialog> : <ProgressView username={row.username} courseId={row.courseId}></ProgressView>}</TableCell>
                      {props.program ? null : (
                        <>
                          <TableCell align="center">
                            <IconButton color="success" onClick={() => { exportExcel(row) }}>
                              <SimCardDownloadIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            
                       
                          {row.sessions&&row.sessions.length>0 ? 
                          <IconButton color="success" onClick={()=>{openVideos(row.sessions,row.name)}} >
                              <VideocamIcon />
                            </IconButton> :null}
                            </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    
      {<Dialog open={open} onClose={() => { setOpen(false) }} >
        <DialogTitle>
        Lista de videos: {selectedCourse}
        </DialogTitle>
                <DialogContent>
                  <TableContainer sx={{ width: "100%" }}>
                    <Table>
                      <TableHead>
                      <TableRow>
                        <TableCell align='left' width={"30%"}>Videos</TableCell>
                        <TableCell align='left'>Fecha</TableCell>
                      </TableRow>
                     </TableHead>
                     <TableBody>
                     {selectedVideos.map((video:any,index:number)=>{
      
                      return(
                        <TableRow
                        role="button"
                        key={index}
                        >
                          <TableCell align="left">
                            {video.map((data:any,index2:number)=>{
                              return(
                                
                                <Typography key={data.url}><Link href={data.url} target="_blank">{index2+1}</Link></Typography> 
                              )
                            
                            })}
                          </TableCell>
                          {video[0]? <TableCell align="left" sx={{verticalAlign:"top"}}>{video[0].creation_date}</TableCell>:null}
                         
                        </TableRow>
                      )
                    })}
                     </TableBody>
                    </Table>
                  </TableContainer>
                   
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setOpen(false) }}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>}
    </Box >
  )
}