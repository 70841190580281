import { DialogContentText, DialogContent, DialogTitle, Dialog, DialogActions, Button} from '@mui/material';
import { useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import {AxiosResponse } from 'axios';
import { ContentType } from '../enums/typeContent.enum';
import TableCodeLesson from './TableCodeLesson';
import TableQuestions from './TableQuestions';
import TableReading from './TableReading';
import { Content } from './Content';

export function ContentDialog(props: any) {

    const [open, setOpen] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [content, setContent] = useState({
        contentId: 0,
        chapterId: props.chapterId,
        name: "",
        type: "",
        order: 0,
    });

    //open modal method
    const handleClickOpen = () => {
        if (props.add !== true) {
            setContent(
                {
                    ...content, contentId: props.item.contentId,
                    chapterId: props.item.chapterId,
                    name: props.item.name,
                    type: props.item.type,
                    order: props.item.order,
                }

            )
        } else {
            setContent({
                ...content, chapterId: props.chapterId
            })
        }
        setOpen(true);
    };
    //close modal method
    const handleClose = () => {
        setContent(
            {
                ...content, contentId: 0,
                chapterId: props.chapterId,
                name: "",
                type: "",
                order: 0,
            }
        )
        setOpen(false);
    };
    //modify values by the input value
    const handdleDelete = () => {
        const token = localStorage.getItem('token');
        MetadataApi.delete("contents/deletecontent/" + props.item.contentId, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            props.getContentByChapter(props.chapterId)
        })
    }

    const handleOpenDelete = () => {
        setOpenDelete(true)
    }
    const handleCloseDelete = () => {
        setOpenDelete(false)
    }
    return (
        <>
           
            {props.treeview?null: <Button sx={{ marginRight: 1 }} onClick={handleClickOpen} size="small" variant='contained' color='primary'>
                {props.add ? "Crear nuevo" : "Editar"}
            </Button>}
            {props.add ? null : <Button sx={{ marginRight: 1 }} onClick={handleOpenDelete} size="small" variant='contained' color='primary' >
                Eliminar
            </Button>}
            {<Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea realmente desea eliminar este contenido?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancelar</Button>
                    <Button onClick={handdleDelete} autoFocus >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>}
            {props.contentButton && props.item.type === ContentType.Questions ?
                <TableQuestions contentId={props.item.contentId} courseId={props.courseId} /> : null}
            {/* {props.contentButton && props.item.type === ContentType.PythonLesson ?
                <TableCodeLesson courseId={props.courseId} contentId={props.item.contentId} /> : null} */}
            {props.contentButton && props.item.type === ContentType.JupyterLesson ?
                <TableCodeLesson courseId={props.courseId} chapterId={props.chapterId} contentId={props.item.contentId} /> : null}
            {props.contentButton && props.item.type === ContentType.Reading ?
                <TableReading courseId={props.courseId} contentId={props.item.contentId} /> : null}
            {<Dialog open={open} onClose={handleClose}>
                <DialogTitle>{props.add ? "Crear Contenido" : "Editar Contenido"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Complete el siguiente formulario para añadir un nuevo contenido al capítulo
                    </DialogContentText>
                    <Content add={props.add} handleClose={setOpen} chapterId={props.chapterId} handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenError} handleUpdateSnackbar={props.handleUpdateSnackbar} getContentByChapter={props.getContentByChapter} item={props.item}></Content>
                </DialogContent>

            </Dialog>}
   
        </>
    )
}