import React, { useContext } from 'react';
import { AxiosResponse } from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context'
import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography,useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import MenuIcon from "@mui/icons-material/Menu";
import { Role } from '../enums/roles.enums';

export default function NavBar() {
  const ctx = useContext(myContext);
  const theme = useTheme();
  const logout = () => {
    const token = localStorage.getItem('token');
    MetadataApi.get("/auth/logout", {
      // withCredentials: true,
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      if (res.data === "success") {
        localStorage.removeItem('token');
        window.location.href = "/"
      }
    }, () => {
      window.location.href = "/"
    })
  }

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
      
    <AppBar position="relative" sx={{zIndex:theme.zIndex.drawer + 1}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            DetacodeCreator
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key="Home" component={RouterLink} to="/">
                <Typography textAlign="center">Home</Typography>
              </MenuItem>
              {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.Creator) ? (
                <MenuItem key="Program" component={RouterLink} to='/program'>
                  <Typography textAlign="center">Programas</Typography>
                </MenuItem>
              ):(null)}
              {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.Creator) ? (
                <MenuItem key="Courses" component={RouterLink} to='/courses'>
                  <Typography textAlign="center">Cursos</Typography>
                </MenuItem>
              ):(null)}
              {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.TeacherAssistant) ? (
                <MenuItem key="Reviews" component={RouterLink} to='/reviews'>
                  <Typography textAlign="center">Revisión</Typography>
                </MenuItem>
              ):(null)}
              {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.TeacherAssistant) ? (
                <MenuItem key="Chat" component={RouterLink} to='/chat'>
                  <Typography textAlign="center">Chat</Typography>
                </MenuItem>
              ):(null)}
              {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.TeacherAssistant) ? (
                <MenuItem key="Progress" component={RouterLink} to='/progress'>
                  <Typography textAlign="center">Progreso</Typography>
                </MenuItem>
              ):(null)}
              {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.Admin) ? (
                <MenuItem key="Admin" component={RouterLink} to='/admin'>
                  <Typography textAlign="center">Administración</Typography>
                </MenuItem>
              ):(null)}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            DetacodeCreator
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
                key='Home'
                component={RouterLink}
                to='/'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >Home
            </Button>
            {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.Creator) ? (
              <Button
                key='Program'
                component={RouterLink}
                to='/program'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >Programas
              </Button>
            ):(null)}
            {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.Creator) ? (
              <Button
                key='Courses'
                component={RouterLink}
                to='/courses'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >Cursos
              </Button>
            ):(null)}
            {ctx && Object.keys(ctx).length !== 0 && (ctx.roles?.includes(Role.TeacherAssistant)) ? (
              <Button
                key='Reviews'
                component={RouterLink}
                to='/reviews'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >Revisión
              </Button>
            ):(null)}
             {ctx && Object.keys(ctx).length !== 0 && (ctx.roles?.includes(Role.TeacherAssistant)) ? (
              <Button
                key='Chats'
                component={RouterLink}
                to='/chat'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >Chat
              </Button>
            ):(null)}
            {ctx && Object.keys(ctx).length !== 0 && (ctx.roles?.includes(Role.TeacherAssistant)) ? (
              <Button
                key='Progress'
                component={RouterLink}
                to='/progress'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >Progreso
              </Button>
            ):(null)}
            {ctx && Object.keys(ctx).length !== 0 && (ctx.roles?.includes(Role.Admin)) ? (
              <Button
                key='Admin'
                component={RouterLink}
                to='/admin'
                sx={{ my: 2, color: 'white', display: 'block' }}
              >Administración
              </Button>
            ):(null)}
          </Box>

          {ctx && Object.keys(ctx).length !== 0 ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ bgcolor: grey[500] }}>{ctx!==undefined && ctx.firstname!==undefined?ctx.firstname.charAt(0).toUpperCase():"U"}</Avatar> 
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="Profile" component={RouterLink} to='/profile'>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem key="Logout" onClick={logout} component={RouterLink} to="/logout">
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 0, display: 'flex' }}>
                <Button
                  key='Login'
                  component={RouterLink}
                  to='/login'
                  sx={{ my: 2, color: 'white' }}
                >Login
                </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>

  )
}