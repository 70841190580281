import { TextField, Button,Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';

export function Chapter(props: any) {
    const [chapter, setChapter] = useState({
        chapterId: 0,
        courseId: 0,
        name: "",
        description: "",
        isActive: true,
        order: 0,
    });
    const handleClose = () => {
        props.handleClose(false)
    }
    //modify values by the input value
    const handleInputChange = (e: any) => {
        setChapter({
            ...chapter,
            [e.target.name]: e.target.value
        })
    }
    const fillContentData = useCallback((data: any) => {
        const newChapter =
        {
            chapterId: props.item.chapterId,
        courseId: props.item.courseId,
        name: props.item.name,
        description: props.item.description,
        isActive: props.item.isActive,
        order: props.item.order,
        }
        setChapter(newChapter)


    }, [props.item])
    useEffect(() => {
       
        if (props.add !== true) {
            fillContentData(props.item)
        }

    }, [props.add,fillContentData,props.item])
    const handleSubmit = (e: any) => {
        e.preventDefault()
        const token = localStorage.getItem('token');
        const sendObject={
            chapterId: chapter.chapterId,
            courseId: props.courseId,
            name: chapter.name,
            description: chapter.description,
            isActive: chapter.isActive,
            order: chapter.order,
        }
        MetadataApi.post("chapters/addchapter", sendObject, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            handleClose()
            if (props.treeview) {
                props.setEditObject(sendObject)
                props.getChaptersByCourse()
            } else {
                props.getChaptersByCourse(props.courseId)
            }
            if (props.add) {
                props.handleUpdateSnackbar("Capítulo creado")
            } else {

                props.handleUpdateSnackbar("Capítulo editado")
            }
            props.handleOpenSuccess()

        }).catch((reason: AxiosError) => {

            if (props.add) {

                props.handleUpdateSnackbar("Crear Capítulo")
            } else {

                props.handleUpdateSnackbar("Editar Capítulo")
            }
            props.handleOpenError()
        })

    }


    return (
        <>
            <form onSubmit={handleSubmit} id="form">
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Nombre del Capítulo"
                    type="text"
                    value={chapter.name}
                    onChange={handleInputChange}
                    fullWidth
                    variant="standard"
                />
                <TextField
                    required
                    multiline
                    margin="dense"
                    id="description"
                    name="description"
                    label="Descripción del capítulo"
                    type="text"
                    value={chapter.description}
                    onChange={handleInputChange}
                    fullWidth
                    variant="standard"
                />
                {props.treeview ?

                    <Grid sx={{ marginTop: 2, textAlign: "right" }}>

                        {props.add ? <>
                            <Button variant='outlined' onClick={handleClose} sx={{ marginRight: 1 }} >Cerrar</Button><Button variant='contained' type='submit' >Crear</Button></> :
                            <Button variant='contained' onClick={handleSubmit} >Guardar</Button>}
                    </Grid>
                    :
                    <Grid sx={{ marginTop: 2, textAlign: "right" }}>
                        {props.add ? <>
                            <Button variant='outlined' onClick={handleClose} sx={{ marginRight: 1 }}>Cerrar</Button><Button variant='contained' type='submit' sx={{ marginRight: 1 }}>Crear</Button></>
                            :
                            <><Button variant='outlined' onClick={handleClose} sx={{ marginRight: 1 }}>Cerrar</Button> <Button variant='contained' type='submit' sx={{ marginRight: 1 }}>Guardar</Button></>}

                    </Grid>}
            </form>
        </>
    )
}