import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
export function SnackBarError(props:any){
    return (
        <> 
            <Snackbar open={props.open} autoHideDuration={2000} onClose={props.handleCloseError}>
            <Alert onClose={props.handleCloseError} severity="error" sx={{ width: '100%' }}>
              Error al {props.message}
            </Alert>
          </Snackbar>
        </>
        )
}