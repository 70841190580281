
import { useCallback , useMemo, useRef } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import BlotFormatter from 'quill-blot-formatter';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./styles/editor.css";
import "./styles/iframe.css"

export function Wysiwyg(props: any) {
    var Font = Quill.import('formats/font');
    // We do not add Aref Ruqaa since it is the default
    Font.whitelist = ["courier", "arial"];
    Quill.register(Font, true);

    Quill.register('modules/blotFormatter', BlotFormatter);
    Quill.debug('error')
    const BlockEmbed = Quill.import('blots/block/embed');
    const reactQuillRef: any = useRef();

    const inputOpenVideoRef: any = useRef();

    const videoHandler = useCallback(() => {
        inputOpenVideoRef.current.click();
    }, []);

    class ButtonBlot extends BlockEmbed {

        static create(value: any) {
            if (value && value.src) {
                const buttonTag = super.create();
                buttonTag.setAttribute('onlick', alert("hola"));
                buttonTag.setAttribute("title", value.title)

                return buttonTag;
            } else {
                const iframeTag = document.createElement('iframe');
                iframeTag.setAttribute('src', value);
                iframeTag.setAttribute('frameborder', '0');
                iframeTag.setAttribute('allowfullscreen', "true");
                iframeTag.setAttribute('width', '100%');
                return iframeTag;
            }
        }

        static value(node: any) {
            if (node.getAttribute('title')) {
                return { onclick: node.getAttribute('onclick') };
            } else {
                return node.getAttribute('src');
            }
            // return { src: node.getAttribute('src'), alt: node.getAttribute('title') };
        }

    }

    ButtonBlot.blotName = 'button';
    ButtonBlot.tagName = 'button';
    Quill.register(ButtonBlot);

    class VideoBlot extends BlockEmbed {

        static create(value: any) {
            if (value && value.src) {
                const videoTag = super.create();
                videoTag.setAttribute('src', value.src);
                videoTag.setAttribute('title', value.title);
                videoTag.setAttribute('width', '100%');
                videoTag.setAttribute('controls', 'true');

                return videoTag;
            } else {
                const iframeTag = document.createElement('iframe');
                iframeTag.setAttribute('src', value);
                iframeTag.setAttribute('frameborder', '0');
                iframeTag.setAttribute('allowfullscreen', "true");
                iframeTag.setAttribute('width', '100%');
                return iframeTag;
            }
        }

        static value(node: any) {
            if (node.getAttribute('title')) {
                return { src: node.getAttribute('src'), title: node.getAttribute('title') };
            } else {
                return node.getAttribute('src');
            }
            // return { src: node.getAttribute('src'), alt: node.getAttribute('title') };
        }

    }

    VideoBlot.blotName = 'video';
    VideoBlot.tagName = 'video';
    Quill.register(VideoBlot);
    const insertVideo = (e: any) => {
        e.preventDefault();
        const token = localStorage.getItem('token');


        if (e.currentTarget && e.currentTarget.files && e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0];
            let formData = new FormData();
            formData.append("file", file);
            MetadataApi.post("datalake/upload/" + props.courseId, {
                "myfile": file
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                })
            }).then((res: AxiosResponse) => {

                const quill = reactQuillRef?.current?.getEditor();
                quill.focus();
                const range = quill.getSelection()
                let position = range ? range.index : 0;
                quill.editor.insertEmbed(position, 'video', { src: res.data, title: "video" }, "user")
                props.change(quill.container.firstChild.innerHTML)

            })
        }

    }
    const courseId = props.courseId
    const change=props.change
    const imageHandler = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            var file: any = input && input.files ? input.files[0] : null;
            //var file: any = input.files[0];
            var formData = new FormData();
            formData.append('image', file);
            const quill = reactQuillRef?.current?.getEditor();
            const range = quill.getSelection()

            const token = localStorage.getItem('token');
            MetadataApi.post("datalake/upload/" + courseId, {
                "myfile": file
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                })
            }).then((res: AxiosResponse) => {
                quill.editor.insertEmbed(range.index, 'image', res.data);
                change(quill.root.innerHTML)
            })

        };
    }, [courseId,change])
    const modules = useMemo(
        () => ({
            blotFormatter: {},
            clipboard: { matchVisual: false },
            toolbar: {
                container: "#" + props.toolbarName,

                handlers: {
                    image: imageHandler,
                    insertVideo: videoHandler
                },
            }
        }
        ),
        [imageHandler, videoHandler, props.toolbarName]);

    return (
        <>

            <div id={props.toolbarName}>
                <select className="ql-font">
                    <option value="arial">Arial</option>
                    <option value="courier">Courier</option>

                </select>
                <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
                    <option value="1" />
                    <option value="2" />
                    <option value="" />
                </select>
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-code-block" />
                <select className="ql-align"></select>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
                <button className="ql-image" />
                <button className="ql-insertVideo">
                    V
                </button>
                <button className="ql-video" />
            </div>

            <ReactQuill ref={reactQuillRef} style={{ height: props.height }} modules={modules} theme="snow" value={props.value} onChange={props.change} />
            <input type="file" accept="video/*" ref={inputOpenVideoRef} style={{ display: "none" }} onChange={insertVideo} />

        </>
    )
}