import { Typography, ListItem, ListItemText, Box } from '@mui/material';

import { Fragment } from 'react';

import { Role } from '../../enums/roles.enums';
import { ChatMessage } from '../../enums/chatStatus.enum';
import * as DOMPurify from 'dompurify';
type Props = {
    index: number,
    comment: any
}
export function Chat({ index, comment }: Props) {
    const cleanData = (html: string) => {
        return DOMPurify.sanitize(html)
    }
    const changeDateFormat = (date: Date) => {
        if (date) {
            const dateObject = new Date(date);
            const day = dateObject.getDate().toString().padStart(2, '0'); // Day (with leading zero)
            const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month (add 1 because months are zero-based)/*  */
            const hours = dateObject.getHours().toString().padStart(2, '0'); // Hours
            const minutes = dateObject.getMinutes().toString().padStart(2, '0'); // Minutes

            // Format the desired output
            const formattedDate = `${day}/${month} ${hours}:${minutes}`;/*  */
            return formattedDate
        } else {
            return " "
        }


    }
    return (
        <>
            {comment.comment === ChatMessage.Endend ? <Typography color={"gray"} align='center' >
                {ChatMessage.Endend}</Typography> :
                comment.comment === ChatMessage.Taked ? <Typography color={"gray"} align='center' >
                    {ChatMessage.Taked}</Typography> :
                    comment.comment === ChatMessage.Reactivate ? <Typography color={"gray"} align='center' >
                        {ChatMessage.Reactivate}</Typography> :
                        <ListItem sx={{ width: "100%" }}
                            key={index}

                        >

                            <Box sx={{
                                backgroundColor: comment.user_type === Role.TeacherAssistant ? 'primary.light' : '#EAEAEA',
                                color: comment.user_type === Role.TeacherAssistant ? '#fff' : '#333',
                                borderRadius: '10px',
                                padding: '10px',
                                maxWidth: '80%', // Adjust the width as needed
                                marginLeft: comment.user_type === Role.TeacherAssistant ? 'auto' : '0', // Push to the right for other user's messages
                            }}>
                                <ListItemText
                                    disableTypography

                                    primary={

                                        <Typography
                                            key={"name" + index}
                                            variant="body2"
                                            color="text.primary"
                                            sx={{
                                                width: "inherit",
                                                overflowWrap: 'break-word', // Add this property to enable text wrapping
                                            }}
                                            align={comment.user_type === Role.TeacherAssistant ? "right" : "left"}
                                        >
                                            {comment.user_comment} {changeDateFormat(comment.date)}
                                        </Typography>


                                    }
                                    secondary={
                                        <Fragment>
                                            {/* <Typography
                                    key={"date" + index}
                                    variant="body2"
                                    color="text.primary"

                                    align={comment.user_type === Role.TeacherAssistant ? "left" : "right"}
                                >
                                    {comment.date}:
                                </Typography> */}
                                            <Typography
                                                key={"comment" + index}
                                                variant="body1"
                                                sx={{
                                                    width: "inherit",
                                                    overflowWrap: 'break-word', // Add this property to enable text wrapping
                                                }}
                                                color="text.primary"
                                                align={comment.user_type === Role.TeacherAssistant ? "right" : "left"}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                   cleanData(comment.comment) 
                                            }}
                                            >
                                           
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                            </Box>
                        </ListItem>}

        </>
    )
}