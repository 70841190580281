
import { Button, DialogContent, DialogTitle, Dialog, Checkbox, DialogActions, Typography, Select, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, Paper, TableContainer, Grid, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useState, useEffect, useContext } from 'react';
import MetadataApi from '../../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import { ContentStatus } from '../../enums/contentStatus.enum';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Wysiwyg } from '../Wysiwyg';
import { myContext } from '../../contexts/Context';
import { ChatMessage } from '../../enums/chatStatus.enum';
import { WebSocketContext } from '../../contexts/WebSocketContext';

type Props = {
    chapterId: number,
    courseId: number,
    username: string,
    handleOpenSuccess: Function,
    handleOpenError: Function,
    handleUpdateSnackbar: Function,
    approve: boolean,
    contentId: number,
    getPendingProjects: Function,
    courseName:string,
    contentName:string,
    codelesson_id:number
}

export function MultipleReviewDialog({ getPendingProjects, courseId, chapterId, handleOpenError, handleOpenSuccess, handleUpdateSnackbar, username, approve, contentId,contentName,courseName,codelesson_id }: Props) {
    const [open, setOpen] = useState<boolean>(false)
    const [chapters, setChapters] = useState<any>([])
    const [contents, setContents] = useState<any>([])
    const [checked, setChecked] = useState<readonly any[]>([]);
    const [numberCorrects, setNumberCorrects] = useState<number[]>([])
    const [disabledApprove, setDisabledApprove] = useState<boolean>(false)
    const [reproveIndex, setReproveIndex] = useState<number>(0)
    const [editorValue, setEditorValue] = useState<string>("<p><br></p>");
    const ctx = useContext(myContext);
    const handleOpen = () => {
        getContentsChapters()
        /*  props.createTeacherAssistantComment(ContentStatus.Completed) */
        setOpen(true)
    }
    const createTeacherAssistantComment = (newStatus: string) => {
        const token = localStorage.getItem('token');
        
        MetadataApi.post("learncomments/addteacherassistantcomments", {
            contentId: contentId,
            username: username,
            comment: editorValue,
            file: "Notebook1.ipynb",
            type: "TeacherAssistant",
            user_comment: ctx.username,
            courseId: courseId,
            chapterId: chapterId,
            newStatus: newStatus
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            if (socket) {
                socket.emit('newMessageTeacher', {
                    notification: {
                        courseId: courseId,
                        content_name: contentName,
                        course_name: courseName,
                        codelessonId: codelesson_id,
                        contentId: contentId,
                    },
                    message: {
                        user_comment: ctx?.username,
                        comment: ChatMessage.Reactivate,
                    },
                    username: username,
                })
            }
            getPendingProjects(ContentStatus.Pending)
            handleClose()

        },)
    }
    const getContentsChapters = () => {
        const token = localStorage.getItem('token');
        MetadataApi.post(`learncourses/getcourse`, {
            courseId: courseId,
            username: username
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
            let allContents: any = []
            let allChapters: any = []
            let numFalse: any = []
            let initialCorrectsArray: number[] = []
            res.data.chapters.forEach((chapter: any) => {
                chapter.contents.forEach((content: any, index: number) => {
                    if (content.contentId === contentId) {
                        setReproveIndex(index)
                    }
                    if (content.status === ContentStatus.Pending || content.status === ContentStatus.Completed || content.status === ContentStatus.Perfect) {
                        numFalse.push(true)
                    } else {
                        numFalse.push(false)
                    }
                    if (content.test_corrects === null || content.test_corrects === undefined || content.test_corrects === 0) {
                        let defaultScore = 1
                        if (approve) defaultScore = 4
                        initialCorrectsArray.push(defaultScore)
                    } else {
                        let score = content.test_corrects
                        if (approve && content.test_corrects < 4) score = 4
                        initialCorrectsArray.push(score)
                    }
                    allContents.push(content)
                    allChapters.push(chapter.name)
                })
            })
            setNumberCorrects(initialCorrectsArray)
            setContents(allContents)
            setChecked(numFalse)
            setChapters(allChapters)
        })
    }
    const handleCheckboxChange = (index: number) => {

        const newCheckedState = [...checked];
        newCheckedState[index] = !checked[index];
        setChecked(newCheckedState);
        if (checked[index]) {
            for (let i = index + 1; i < checked.length; i++) {
                if (checked[i]) {
                    newCheckedState[i] = false;
                    setChecked(newCheckedState);
                }
            }
        }
    }
    const handleCorrectsNumberChange = (value: number, index: number) => {

        const numberCorrectsCopy = [...numberCorrects]
        numberCorrectsCopy[index] = value
        setNumberCorrects(numberCorrectsCopy)
    }
    const handleClose = () => {
        /* setChecked([]) */
        setOpen(false)
    };
    const socket = useContext(WebSocketContext)
    const saveNewStatus = () => {
        const token = localStorage.getItem('token');

        if (approve) {
            createTeacherAssistantComment(ContentStatus.Completed)
            for (let i = 0; i < contents.length; i++) {
                if (checked[i] && contents[i].status !== ContentStatus.Completed) {

                    MetadataApi.post(`learncontents/reviewsavecontent`, {
                        contentId: contents[i].contentId,
                        username: username,
                        status: ContentStatus.Completed,
                        courseId: courseId,
                        numberCorrects: numberCorrects[i],
                        comment:editorValue
                    }, {
                        headers: ({
                            Authorization: 'Bearer ' + token
                        }),
                    }).then((res: AxiosResponse) => {
                        if (socket) {
                            socket.emit('newMessageTeacher', {
                                notification: {
                                    courseId: courseId,
                                    content_name: contentName,
                                    course_name: courseName,
                                    codelessonId: -1,
                                    contentId: contentId,
                                },
                                message: {
                                    user_comment: ctx?.username,
                                    comment: ChatMessage.Reactivate,
                                },
                                username: username,
                            })
                        }
                        handleUpdateSnackbar("Contenido del Proyecto Aprobado")
                        handleOpenSuccess()
                        handleClose()
                        getPendingProjects(ContentStatus.Pending)
                    }).catch((error: AxiosError) => {
                        handleUpdateSnackbar("Aprobar Contenido del Proyecto")
                        handleOpenError()

                    })
                }
            }
        } else {
            createTeacherAssistantComment(ContentStatus.Failed)
            MetadataApi.post(`learncontents/reviewsavecontent`, {
                contentId: contentId,
                username: username,
                status: ContentStatus.Failed,
                courseId: courseId,
                numberCorrects: numberCorrects[reproveIndex]
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                }),
            }).then((res: AxiosResponse) => {
                handleUpdateSnackbar("Contenido del Proyecto Reprobado")
                handleOpenSuccess()
                handleClose()
                getPendingProjects(ContentStatus.Pending)
            }).catch((error: AxiosError) => {
                handleUpdateSnackbar("Reprobar Contenido del Proyecto")
                handleOpenError()
            })
        }
    }
    useEffect(() => {
        if (editorValue === "<p><br></p>") {
            setDisabledApprove(true)
        } else {
            setDisabledApprove(false)
        }
    }, [editorValue])


    return (
        <>
            {<Button variant='outlined' onClick={handleOpen} color={approve ? 'success' : 'error'} sx={{ mr: 1 }}>
                {approve ? <CheckIcon /> : <CloseIcon />}
                <Typography variant='body2' sx={{ mr: 1 }}>{approve ? "APROBAR" : "REPROBAR"} </Typography>
            </Button>}
            {<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle>
                    {approve ?
                        "Seleccione los contenidos a aprobar y su respectiva nota"
                        :
                        "Reprobar Contenido"
                    }
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={5.5}>
                            <Typography>Nuevo Comentario</Typography>
                            <Box sx={{ width: '100%', overflow: 'auto', maxHeight: 375 }}>
                                <Wysiwyg toolbarName={"Review"} height={305} value={editorValue} change={setEditorValue} courseId={courseId} />
                            </Box>
                        </Grid>
                        <Grid item xs={0.5} />
                        <Grid item xs={6}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 400 }}>
                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow >
                                                <TableCell >Capítulo</TableCell>
                                                <TableCell align='left'>Contenido</TableCell>
                                                <TableCell align='left'>{approve ? 'Aprobar/nota' : "Nota"}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {contents.map((content: any, index: number, row: any) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ width: "30%" }}>{chapters[index]}</TableCell>
                                                        <TableCell sx={{ width: "30%" }}>{content.name}</TableCell>
                                                        <TableCell sx={{ width: "40%" }}>
                                                            {approve ?
                                                                index === 0 ?
                                                                    <Checkbox checked={checked[index]} onChange={() => {
                                                                        handleCheckboxChange(index)
                                                                    }} disabled={content.status === ContentStatus.Completed ? true : false} />
                                                                    :
                                                                    <Checkbox checked={checked[index]} onChange={() => {
                                                                        handleCheckboxChange(index)
                                                                    }} disabled={!checked[index - 1] || content.status === ContentStatus.Completed ? true : false} />
                                                                : null}
                                                            {approve ? <>
                                                                <Select
                                                                    sx={{ width: "30%" }}
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={numberCorrects[index]}
                                                                    label="Age"
                                                                    size='small'
                                                                    disabled={
                                                                        approve ?
                                                                            content.status === ContentStatus.Completed ? true : false
                                                                            :
                                                                            index === reproveIndex ? false : true
                                                                    }
                                                                    onChange={(event: any) => { handleCorrectsNumberChange(Number(event.target.value), index) }}
                                                                >
                                                                    {Array.from({ length: 4 }).map((value: any, index: number) => {
                                                                        return (
                                                                            <MenuItem key={index + 4} value={index + 4}>
                                                                                {index + 4}
                                                                            </MenuItem>
                                                                        )

                                                                    })}
                                                                </Select>
                                                                {content.status === ContentStatus.Pending ?
                                                                    <Typography sx={{ width: "20%", ml: 1, display: "inline-block" }} >{content.test_corrects} </Typography>

                                                                    : null
                                                                }
                                                            </>
                                                                :
                                                                index <= reproveIndex ?
                                                                    <Select
                                                                        autoFocus
                                                                        sx={{ width: "30%" }}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={numberCorrects[index]}
                                                                        label="Age"
                                                                        size='small'
                                                                        disabled={

                                                                            index === reproveIndex ? false : true
                                                                        }
                                                                        onChange={(event: any) => { handleCorrectsNumberChange(Number(event.target.value), index) }}
                                                                    >
                                                                        {index !== reproveIndex ?
                                                                            <MenuItem key={numberCorrects[index]} value={numberCorrects[index]}>
                                                                                {numberCorrects[index]}
                                                                            </MenuItem>

                                                                            : null}
                                                                        {Array.from({ length: 3 }).map((value: any, index: number) => {
                                                                            return (
                                                                                <MenuItem key={index + 1} value={index + 1}>
                                                                                    {index + 1}
                                                                                </MenuItem>
                                                                            )

                                                                        })}
                                                                    </Select>
                                                                    : null
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' sx={{ marginRight: 1 }} onClick={handleClose}>Cerrar</Button>
                    <Button variant='outlined' disabled={disabledApprove} onClick={saveNewStatus} color={approve ? 'success' : 'error'}>
                        {approve ? <CheckIcon /> : <CloseIcon />}<Typography variant='body2' sx={{ mr: 1 }}>{approve ? "APROBAR" : "REPROBAR"} </Typography>
                    </Button>
                </DialogActions>
            </Dialog >}
        </>
    )
}