import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, IconButton, Tooltip, Grid } from '@mui/material';
import { myContext } from '../contexts/Context';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';

type Props = {courseId:string}

const OpenDialog : React.FC<Props> = ({courseId}) => {

    const ctx = useContext(myContext);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleRefresh = () => {
        // console.log('refresh '+courseId)
        setLoading(true);
        const token = localStorage.getItem('token');
        MetadataApi.post(`learncourses/activatecourse`, {
        courseId: courseId,
        username: ctx.username
        }, {
        headers: ({
            Authorization: 'Bearer ' + token
        }),
        }).then((res: AxiosResponse) => {
            // console.log('activatedcourse:', res.data)
            handleOpenNewWindow();
            setLoading(false);
        })
    };

    const handleOpenNewWindow = () => {
        window.open(process.env.REACT_APP_MAIN_APP+'/learn/'+courseId, "_blank");
        setOpen(false);
    }

    return (
        <>
            <Tooltip title={'Abrir curso en aplicación'}>
                <IconButton size='small' onClick={handleClickOpen}>
                    <OpenInNewIcon fontSize='medium' color='primary'/>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            > <DialogTitle id="alert-dialog-title">
                {"Previsualizar Curso"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container direction={'column'} alignItems={'center'} spacing={1}>
                        <Grid item>
                            <Button size="small" variant='contained' onClick={handleOpenNewWindow}>Ir a Curso</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" variant='outlined' onClick={handleRefresh} disabled={loading}>Activar/Reiniciar</Button>
                        </Grid>
                    </Grid>
                </DialogContentText>
                </DialogContent> 
            </Dialog>
        </>
        
  );
}
export default OpenDialog;
