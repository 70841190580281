import { DialogContentText, DialogContent, DialogTitle, Dialog, DialogActions, Button,  } from '@mui/material';
import {  useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';

import { Questionlesson } from './Questionlesson';


export function QuestionsDialog(props: any) {
  
    const [open, setOpen] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [openDiscart, setOpenDiscart] = useState<boolean>(false);

   
    
    //close modal method
    const handleClose = () => {
      
        setOpen(false);
    };
    //modify values by the input value
   

   
    const handleOpenDelete = () => {
        setOpenDelete(true)
    }
    const handleCloseDelete = () => {
        setOpenDelete(false)
    }
    const handdleDelete = () => {
        const token = localStorage.getItem('token');
        MetadataApi.delete("questions/deletequestion/" + props.id, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            props.getContentQuestionByContent()
            handleCloseDelete()

        })
    }
    const handleOpenDiscart = () => {
            setOpenDiscart(true)
        
       
    }
    const handleCloseDiscart = () => {
        setOpenDiscart(false)
    }
    const handleAcceptDiscart = () => {

        handleCloseDiscart()
        setOpen(false)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Button onClick={handleClickOpen} size="small" variant='contained' color='primary'>
                {props.add ? "Crear nuevo" : "Editar"}
            </Button>
            {props.add ? null : <Button sx={{ marginLeft: 1 }} onClick={handleOpenDelete} size="small" variant='contained' color='primary' >
                Eliminar
            </Button>}
            {<Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea realmente desea eliminar esta pregunta?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancelar</Button>
                    <Button onClick={handdleDelete} autoFocus >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>}
            {<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
                <DialogTitle>{props.add ? "Crear Pregunta" : "Editar Pregunta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Complete el siguiente formulario para añadir un nueva pregunta al contenido
                    </DialogContentText>
                    <Questionlesson handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenSuccess} handleUpdateSnackbar={props.handleUpdateSnackbar} height={350} handleOpenDiscart={handleOpenDiscart} setCloseDialog={setOpen} getData={props.getContentQuestionByContent} id={props.id} contentId={props.contentId} add={props.add}> 

                    </Questionlesson>       
                </DialogContent>
            </Dialog>}
            {<Dialog
                open={openDiscart}
                onClose={handleCloseDiscart}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea realmente descartar los cambios realizados?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDiscart} variant='outlined'>Cancelar</Button>
                    <Button onClick={handleAcceptDiscart} variant='contained' >
                        Descartar
                    </Button>
                </DialogActions>
            </Dialog>}
            
        </>
    )
}