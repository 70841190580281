import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import Routing from './routing/Routing';
function App() {
  return (
    <BrowserRouter>
      <NavBar/>
      <Routing/>
    </BrowserRouter>
  );
}

export default App;
