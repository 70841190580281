import { useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import { myContext } from '../contexts/Context';
import HomePage from '../pages/HomePage';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import { Role } from '../enums/roles.enums';
import Program from '../pages/Program';
import Courses from '../pages/Courses';
import Chapters from '../pages/Chapters'
import NewTreeChapters from '../pages/Treeview';
import { Reviews } from '../pages/Reviews';
import { Progress } from '../pages/Progress';
import { AdminPanel } from '../pages/AdminPanel';
import { Chat } from '../pages/Chat';
export default function Routing () {
    const ctx = useContext(myContext);

    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage/>}></Route>
                { ctx && Object.keys(ctx).length !== 0  ? (
                <>
                    {ctx.roles?.includes(Role.Creator) ? <Route path="/program" element={<Program/>}></Route> : null}
                    {ctx.roles?.includes(Role.Creator) ? <Route path="/courses" element={<Courses/>}></Route> : null}
                    {ctx.roles?.includes(Role.Creator) ? <Route path="/chapters/:courseId" element={<Chapters/>}></Route> : null}
                    {ctx.roles?.includes(Role.Creator) ? <Route path="/treechapters/:courseId" element={<NewTreeChapters/>}></Route> : null}
                    {ctx.roles?.includes(Role.TeacherAssistant)? <Route path="/reviews/" element={<Reviews/>}></Route> : null}
                    {ctx.roles?.includes(Role.TeacherAssistant)? <Route path="/chat/" element={<Chat/>}></Route> : null}
                    {ctx.roles?.includes(Role.TeacherAssistant)? <Route path="/progress/" element={<Progress/>}></Route> : null}
                    {ctx.roles?.includes(Role.Admin)? <Route path="/admin/" element={<AdminPanel/>}></Route> : null}
                    <Route path="/profile" element={<Profile/>}></Route>
                </>
                ) : (
                <>
                    <Route path="/login" element={<Login/>}></Route>
                </>  
                )
                }

            </Routes>
        </>
    );
}