import { DialogContentText, DialogTitle, Dialog, DialogActions, Button, Grid, Typography, Slider, DialogContent } from '@mui/material';
import { useState, Fragment, useEffect } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';


type Props = {
    courseId: number,
    variant: string,
    handleOpenSuccess:Function,
    handleOpenError:Function,
    handleUpdateSnackbar:Function
}

export function TestDialog({ courseId, variant,handleOpenError,handleOpenSuccess,handleUpdateSnackbar }: Props) {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [newTestsRules, setNewTestsRules] = useState<any[]>([])
    const [sliderValue, setSliderValue] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0]);
    const [sliderFeedbackValue,setSliderFeedbackValue]=useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0]);
    const handleChangeSlider = (value: number, index: number,numberTest:number) => {
        if(value<=numberTest){
            let sliderCopy = [...sliderValue]
            sliderCopy[index] = value
            let sliderFeedbackCopy =[...sliderFeedbackValue]
            
            if((sliderFeedbackCopy[index]<(value/2))===false){
                sliderFeedbackCopy[index]=sliderFeedbackCopy[index]-1
            }
            
            
            setSliderFeedbackValue(sliderFeedbackCopy)
            setSliderValue(sliderCopy);
            handleChangeRuleValue(value, index)
        }
       
    };
    const handleChangeFeedbackSlider = (value: number, index: number) => {
        const percentageCase = sliderValue[index] >= 10 && value < 5;
        const numberCase = sliderValue[index] < 10 && value < (sliderValue[index] / 2);
        if (percentageCase || numberCase) {
            let sliderCopy = [...sliderFeedbackValue];
            sliderCopy[index] = value;
            setSliderFeedbackValue(sliderCopy);
            handleChangeRuleValueFeedback(value, index);
        }
    
    };
    const percentageMarkers = [{ value: 10, label: "10%" },
    { value: 20, label: "" },
    { value: 30, label: "" },
    { value: 40, label: "" },
    { value: 50, label: "" },
    { value: 60, label: "" },
    { value: 70, label: "" },
    { value: 80, label: "" },
    { value: 90, label: "" },
    { value: 100, label: "100%" }]

    const handleChangeRuleValue = (value: number, index: number) => {
        let testRulesCopy = [...newTestsRules]
        testRulesCopy[index].approveNumber = value
        setNewTestsRules(testRulesCopy)
    }
    const handleChangeRuleValueFeedback = (value: number, index: number) => {
        let testRulesCopy = [...newTestsRules]
        testRulesCopy[index].numberFeedback = value
        setNewTestsRules(testRulesCopy)
    }

    const openRulesTests = () => {
        const token = localStorage.getItem('token');
        /*  console.log('course',course,'props',props.isProject) */
        MetadataApi.get("courses/getrulestests/" + courseId, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setNewTestsRules(res.data)
            let newSliderValues: number[] = []
            let newFeedbackSladerValues: number[] = []
            res.data.forEach((data: any, index: number) => {
            
                newSliderValues.push(data.approveNumber)
                newFeedbackSladerValues.push(data.numberFeedback)
                
            })
            setSliderValue(newSliderValues)
            setSliderFeedbackValue(newFeedbackSladerValues)
            setOpenDialog(true)
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const token = localStorage.getItem('token');
        MetadataApi.post("courses/addrulestests/", {
            newTestsRules
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            handleUpdateSnackbar("Tests editados")
            handleOpenSuccess()
            setOpenDialog(false)

        }).catch((error:AxiosError)=>{
            handleUpdateSnackbar("Editar Tests")
            handleOpenError()
            setOpenDialog(false)
        })
    }
    const valueLabelFormatPercentage = (value: number) => {
        return `${value}% `;
    }

    const renderFeedbackSliders = (data: any, index: number) => {

        const marks = Array.from({ length: data.numberTest }, (_, i) => ({
            value: i ,
            label: (i ).toString()
        }));

        marks.push({ value: data.numberTest, label: data.numberTest.toString() });

        return (<>
            <Slider 
                defaultValue={data.numberFeedback}
                value={sliderFeedbackValue[index]}
                min={0}
                onChange={(e: any) => { handleChangeFeedbackSlider(e.target.value, index,) }}
                max={10}
                step={1}
                marks={marks}
            />
        </>
        );
    };
    useEffect(()=>{

    },[sliderValue])
    const renderSliders = (data: any, index: number) => {

        const marks = Array.from({ length: data.numberTest - 1 }, (_, i) => ({
            value: i + 1,
            label: (i + 1).toString()
        }));

        marks.push({ value: data.numberTest, label: data.numberTest.toString() });

        return (<>
            <Slider 
                defaultValue={data.approveNumber}
                value={sliderValue[index]}
                min={1}
                onChange={(e: any) => { handleChangeSlider(e.target.value, index,data.numberTest) }}
                max={10}
                step={1}
                marks={marks}
            />
        </>
        );
    };
    return (
        <>
            <Button onClick={() => { openRulesTests() }} sx={{ marginRight: 1 }} size="small" variant={variant==="contained"?"contained":"outlined"}>
                Tests
            </Button>
            {<Dialog open={openDialog} fullWidth maxWidth="xl" >
                <DialogTitle >Definicion de Tests</DialogTitle>
                <DialogContentText ml={2} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    Seleccione la cantidad de test necesarios para completar los jupyter notebooks
                </DialogContentText>
                <DialogContent>
                <form onSubmit={handleSubmit} id="form">
                    <Grid container spacing={3} display={"flex"} textAlign={"center"} >
                        <Grid item xs={1.5}>

                        </Grid>
                        <Grid item xs={5}>
                            <Typography>Tests para aprobar</Typography>
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5}>
                            <Typography> N° de Feedback</Typography>
                        </Grid>
                        {/*     {renderSliders()} */}

                        {newTestsRules.map((testRules: any, index: number) => {
                            return (
                              
                                <Fragment key={index} >
                                 {testRules.numberTest===1? null:<>
                                 <Grid item xs={1.5}  >
                                        <Typography >Cantidad de test: {testRules.numberTest}</Typography>
                                    </Grid>
                                    <Grid item xs={5}  >
                                        {testRules.numberTest === 10 ? <><Slider
                               
                                            value={sliderValue[index]}
                                            min={10}
                                            step={10}
                                            max={100}
                                            marks={percentageMarkers}
                                            getAriaValueText={valueLabelFormatPercentage}
                                            valueLabelFormat={valueLabelFormatPercentage}
                                            onChange={(e: any) => { handleChangeSlider(e.target.value, index,100) }}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="non-linear-slider"
                                        /></>

                                            :
                                           
                                                renderSliders(testRules, index)
                                        }

                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xs={5}  >         
                                               { renderFeedbackSliders(testRules, index)}
                                    </Grid>
                                   </>}
                                    
                                </Fragment>
                            )
                        })}
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => { setOpenDialog(false) }} variant='outlined'>Cancelar</Button>
                        <Button type="submit" variant='contained'>Guardar</Button>
                    </DialogActions>
                </form>
                </DialogContent>
            </Dialog>}
        </>
    )

}