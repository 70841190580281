
import { useState, useContext, useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogActions, Fab, Grid, TextField, Typography, Button } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import MetadataApi from '../../apis/metadata.api';
import { ChatMessage, ChatStatus } from '../../enums/chatStatus.enum';

import SendIcon from '@mui/icons-material/Send';
import { myContext } from '../../contexts/Context';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { Role } from '../../enums/roles.enums';
type Props = {
    tabValue: ChatStatus,
    contentId: number,
    username: string,
    codelessonId: number,
    getAllChats: Function,
    course_name: string,
    content_name: string,
    setChat: Function,
    courseId: number,
    setOpenDialog: Function,
    handleOpenSuccess: Function,
    handleOpenError: Function,
    handleUpdateSnackbar: Function
}
export function ChatStatusDialog({ tabValue, codelessonId, contentId, username, getAllChats, content_name, course_name, setChat, courseId, setOpenDialog, handleOpenError, handleOpenSuccess, handleUpdateSnackbar }: Props) {
    const [openFinishDialog, setOpenFinishDialog] = useState<boolean>(false)
    const [openReactivateDialog, setOpenReactivateDialog] = useState<boolean>(false)
    const [openReviewDialog, setOpenReviewteDialog] = useState<boolean>(false)
    const [openAlreadyTakenDialog, setOpenAlreadyTakenDialog] = useState<boolean>(false)
    const [finalTabValue, setFinalTabValue] = useState<ChatStatus>(tabValue)
    const [sendDisabled, setSendDisabled] = useState<boolean>(true)
    const [newComment, setNewComment] = useState<string>("")

    const ctx = useContext(myContext)
    const socket = useContext(WebSocketContext)
    useEffect(() => {
        if (socket) {
            let isWriting = false
            if (newComment !== "") {
                isWriting = true
            }
            socket.emit("writing", { username: username, writing: isWriting, contentId: contentId, codelessonId: codelessonId, role: Role.TeacherAssistant })
        }

    }, [newComment, ctx.username, socket, codelessonId, contentId, username])

    const updateStatusChat = (state: ChatStatus) => {
        const token = localStorage.getItem('token');
        setNewComment("")
        MetadataApi.post("learncomments/updatestatuschatteacher", {
            contentId: contentId,
            username: username,
            codelessonId: codelessonId,
            state: state,
            teacher_reviewing: ctx.username
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
            if (res.data) {
                if (finalTabValue === ChatStatus.Completed) {
                    if (socket) {
                        socket.emit('newMessageTeacher', {
                            notification: {
                                courseId: courseId,
                                content_name: content_name,
                                course_name: course_name,
                                codelessonId: codelessonId,
                                contentId: contentId,
                            },
                            message: {
                                user_comment: ctx?.username,
                                comment: ChatMessage.Reactivate,
                            },
                            username: username,
                        })
                    }

                    const newChat = {
                        comment: ChatMessage.Reactivate,
                        contentId: +contentId,
                        codelessonId: +codelessonId,
                        user_comment: ctx.username,
                        username: username,
                        date: new Date().toJSON(),
                        user_type: Role.TeacherAssistant
                    }
                    setChat((oldChat: any) => [...oldChat, newChat])
                    setOpenReactivateDialog(false)

                    setFinalTabValue(ChatStatus.Reviewing)
                
                } else if (finalTabValue === ChatStatus.Reviewing) {
                    if (socket) {
                        socket.emit('newMessageTeacher', {
                            notification: {
                                courseId: courseId,
                                content_name: content_name,
                                course_name: course_name,
                                codelessonId: codelessonId,
                                contentId: contentId,
                            },
                            message: {
                                user_comment: ctx?.username,
                                comment: ChatMessage.Endend,
                            },
                            username: username,
                        })
                    }

                    const newChat = {
                        comment: ChatMessage.Endend,
                        contentId: +contentId,
                        codelessonId: +codelessonId,
                        user_comment: ctx.username,
                        username: username,
                        date: new Date().toJSON(),
                        user_type: Role.TeacherAssistant
                    }
                    handleUpdateSnackbar("ChatStatus actualizado")
                    handleOpenSuccess()
                    setChat((oldChat: any) => [...oldChat, newChat])
                    setOpenFinishDialog(false)
                    setOpenDialog(false)
                    getAllChats(tabValue)
                    setFinalTabValue(ChatStatus.Completed)
                    
                } else if (finalTabValue === ChatStatus.Started) {
                    if (socket) {
                        socket.emit('newMessageTeacher', {
                            notification: {
                                courseId: courseId,
                                content_name: content_name,
                                course_name: course_name,
                                codelessonId: codelessonId,
                                contentId: contentId,
                            },
                            message: {
                                user_comment: ctx?.username,
                                comment: ChatMessage.Taked,
                            },
                            username: username,
                        })
                    }
                    const newChat = {
                        comment: ChatMessage.Taked,
                        contentId: +contentId,
                        codelessonId: +codelessonId,
                        user_comment: ctx.username,
                        username: username,
                        date: new Date().toJSON(),
                        user_type: Role.TeacherAssistant
                    }
                    setChat((oldChat: any) => [...oldChat, newChat])
                    setOpenReviewteDialog(false)
                    setFinalTabValue(ChatStatus.Reviewing)
             
                }
                handleUpdateSnackbar("ChatStatus actualizado")
                handleOpenSuccess()
            } else {
                setOpenAlreadyTakenDialog(true)
            }

        }).catch((error: AxiosError) => {
            handleUpdateSnackbar("Actualizar ChatStatus")
            handleOpenError()
        })
    }
    const addChat = (e: any) => {
        e.preventDefault()
        setSendDisabled(true)
        const token = localStorage.getItem('token');
        MetadataApi.post("learncomments/addchatteacherassistant", {
            contentId: contentId,
            username: username,
            codelessonId: codelessonId,
            comment: newComment,
            user_comment: ctx?.username

        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
            setChat(res.data)
            setNewComment("")
            if (socket) {
                socket.emit('newMessageTeacher', {
                    notification: {
                        courseId: courseId,
                        content_name: content_name,
                        course_name: course_name,
                        codelessonId: codelessonId,
                        contentId: contentId,
                    },
                    message: {
                        user_comment: ctx?.username,
                        comment: newComment,
                    },
                    username: username,
                })
            }

        })
    }
    const openDialog = () => {
        if (finalTabValue === ChatStatus.Started) {
            setOpenReviewteDialog(true)
        } else if (finalTabValue === ChatStatus.Reviewing) {
            setOpenFinishDialog(true);
        } else if (finalTabValue === ChatStatus.Completed) {
            setOpenReactivateDialog(true)
        }
    }
    const handleInputChange = (e: any) => {
        setNewComment(
            e.target.value
        )
    }

    useEffect(() => {
        if (newComment !== "") {
            setSendDisabled(false);
        } else {
            setSendDisabled(true);
        }
    }, [newComment])
    return (
        <>
            <Grid container>
                <Grid item xs={5.5}>
                    <Box sx={{
                        backgroundColor: "#d3d3d3",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "8px",
                    }}>
                        <form onSubmit={addChat}>
                            <Box display="flex" alignItems="flex-end">
                                <TextField
                                    required
                                    size='small'
                                    id="newComment"
                                    name="newComment"
                                    label={finalTabValue === ChatStatus.Completed ? "Chat Finalizado" :
                                        finalTabValue === ChatStatus.Started ? "Chat sin iniciar" : "Nuevo mensaje"}
                                    type="text"
                                    disabled={finalTabValue === ChatStatus.Completed || finalTabValue === ChatStatus.Started}
                                    sx={{ bgcolor: "white" }}
                                    value={newComment}
                                    onChange={handleInputChange}
                                    multiline
                                    fullWidth
                                />
                                <Fab color='success' size='small' type='submit' disabled={sendDisabled}>
                                    <SendIcon />
                                </Fab>
                            </Box>
                        </form>
                    </Box>
                </Grid>
                <Grid item xs={6.5}>

                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100%' }} marginRight={1}>
                        <Button size={"medium"} variant='contained' onClick={openDialog}>
                            {finalTabValue === ChatStatus.Started ? "Iniciar"
                                : finalTabValue === ChatStatus.Reviewing ? "Finalizar"
                                    : finalTabValue === ChatStatus.Completed ? "Reactivar"
                                        : null
                            }
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {<Dialog open={openFinishDialog} onClose={() => { setOpenFinishDialog(false) }}>
                <DialogContent>
                    <Typography> ¿Desea dar por finalizado el chat?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setOpenFinishDialog(false) }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => { updateStatusChat(ChatStatus.Completed) }}>
                        Finalizar
                    </Button>
                </DialogActions>
            </Dialog>}
            {<Dialog open={openReactivateDialog} onClose={() => { setOpenReactivateDialog(false) }}>
                <DialogContent>
                    <Typography>¿Desea reactivar la conversación?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setOpenReactivateDialog(false) }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => { updateStatusChat(ChatStatus.Reviewing) }}>
                        Reactivar
                    </Button>
                </DialogActions>
            </Dialog>}
            {<Dialog open={openReviewDialog} onClose={() => { setOpenReviewteDialog(false) }}>
                <DialogContent>
                    <Typography>Desea Iniciar la conversación?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setOpenReviewteDialog(false) }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => { updateStatusChat(ChatStatus.Reviewing) }}>
                        Iniciar
                    </Button>
                </DialogActions>
            </Dialog>}
            {<Dialog open={openAlreadyTakenDialog} onClose={() => { setOpenAlreadyTakenDialog(false) }}>
                <DialogContent>
                    <Typography>El chat ya ha sido iniciado por otro Profesor</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setOpenAlreadyTakenDialog(false) }}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>}
        </>

    )
}