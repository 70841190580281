import { Button, Grid, Typography, DialogContent, DialogTitle, Dialog, List, Paper, Fab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, Fragment, useEffect, useContext, useRef } from 'react';
import MetadataApi from '../../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { Box } from '@mui/system';
import { MultipleReviewDialog } from './MultipleReviewDialog';
import ReplayIcon from '@mui/icons-material/Replay';
import { Chat } from './Chat';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { Role } from '../../enums/roles.enums';
import { myContext } from '../../contexts/Context';

type Props = {
    item: any,
    pending: boolean,
    proyectName: string,
    contentId: number,
    getPendingProjects: Function,
    handleOpenSuccess: Function,
    handleOpenError: Function,
    handleUpdateSnackbar: Function,
    username:string,
    courseId:string,
    courseName:string,
    contentName:string
}

export function ReviewDialog({ contentId, getPendingProjects, handleOpenError, handleOpenSuccess, handleUpdateSnackbar, item, pending, proyectName,username,courseId,contentName,courseName }: Props) {

    const [jupyterSource, setJupyterSource] = useState<string>("")
    const [jupyterLoading, setJupyterLoading] = useState<boolean>(true)
    const [open, setOpen] = useState<boolean>(false)
    const [comments, setComments] = useState<any>([])
    const [isJupyterLoaded, setIsJupyterLoaded] = useState<boolean>(false)
    const [jupyterNotebookId, setJupyterNotebookId] = useState<number>(0)
    const socket = useContext(WebSocketContext)
    const boxRef = useRef<HTMLDivElement | null>(null);
    const ctx = useContext(myContext)
    useEffect(() => {
        if(socket){
            socket.on('onMessageStudent', (data) => {
                if (open) {
                    data["user_type"] = Role.User
                    data["date"] = new Date().toJSON()
                    setComments((oldChat: any) => [...oldChat, data])
                }
            })
            socket.on('onWriting', (data) => {
                if (open && data.contentId === contentId) {
                    if (boxRef.current) {
                        // Scroll to the bottom when content changes
                        boxRef.current.scrollTop = boxRef.current.scrollHeight;
                    }
                }
            })
    
            return () => {
                socket.off('connect')
                socket.off('onMessageStudent')
                socket.off('onWriting')
            }
        }
       
    }, [socket, open, contentId, ctx.username])

    const handleClose = () => {
        setJupyterLoading(true)
        setOpen(false)


    };
    const getComments = () => {
        const token = localStorage.getItem('token');
        MetadataApi.get("learncomments/getlearncommentsbycontentid/" + item.contentId, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            let allData = res.data

            allData.map((data: any, index: number) => {
                let date = data.date.slice(0, 19)
                date = date.split("")
                date[10] = " "
                date = date.join("")
                allData[index].date = date
                return true
            })


            setComments(allData)

        },)
    }

    const handleOpen = () => {
        setOpen(true)
        getJupyterLessonId()

        getComments()
    }
    const getJupyterLessonId = () => {
        const token = localStorage.getItem('token');
        MetadataApi.get("codelesson/getcodelessonbycontent/" + item.contentId, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {

            setJupyterNotebookId(res.data[0].id)
            getStudentJupyterNotebookUrl(item.chapterId, item.courseId, contentId, res.data[0].id, item.username)
        })
    }
    const getStudentJupyterNotebookUrl = (chapterId: number, courseId: number, contentId: number, jupyterId: number, username: any) => {

        setJupyterLoading(true)
        const token = localStorage.getItem('token');
        MetadataApi.post("learncontents/getcopyjupyternotebookstudent", {
            chapterId: chapterId,
            courseId: courseId,
            contentId: contentId,
            username: username,
            jupyterId: jupyterId,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setJupyterLoading(false)
            if (res.data === "code error") {
                setIsJupyterLoaded(false)

            } else {
                setIsJupyterLoaded(true)
                setJupyterSource(res.data)
            }
        },)

    }

    useEffect(()=>{
        if(open){
            const token = localStorage.getItem('token');
            MetadataApi.post("sessionrewind/getallsessions", {
                username: username,
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
               
            },) 
        }
      
    },[courseId,username,open])

    return (
        <>
            <Button onClick={handleOpen} sx={{ marginRight: 1 }} size="small" variant='contained' color='primary'>
                {pending ? "Revisar" : "Ver"}
            </Button>
            {<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" >
                <DialogTitle>Revisión proyecto: {item.courseName}</DialogTitle>
                <DialogContent>
                    <Box height={540}>
                        <Grid container justifyContent="center" sx={{ height: 400 }}>
                            <Grid item xs={5} sx={{ width: "100%", height: "110%" }}>
                                <Typography sx={{ marginTop: 2 }} variant='h6' >Comentarios historico</Typography>
                                <Paper sx={{ width: '100%', height: "100%", bgcolor: 'background.paper', overflow: "auto" }}>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        {comments.map((comment: any, index: number) => {
                                            return (
                                                <Fragment key={index}>
                                                    <Chat index={index} comment={comment}></Chat>

                                                </Fragment>
                                            )
                                        }
                                        )}

                                    </List>
                                </Paper>

                               {/*  {pending ? <><Typography sx={{ marginTop: 2 }} variant='h5' >Nuevo Comentario</Typography><Wysiwyg toolbarName={"Review"} height={100} value={editorValue} change={setEditorValue} courseId={item.courseId} /></> : null} */}

                                <Box sx={{ flexGrow: 1, textAlign: "right", marginTop: 2 }}>
                                    <Button variant='outlined' sx={{ textAlign: "right", marginRight: 1 }} onClick={handleClose}>Cancelar</Button>
                                    {pending ? <>
                                        <MultipleReviewDialog getPendingProjects={getPendingProjects} approve={false} contentId={item.contentId} chapterId={item.chapterId} courseId={item.courseId} username={item.username} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} contentName={contentName} courseName={courseName} codelesson_id={item.codelesson_id}
                                        ></MultipleReviewDialog>
                                        <MultipleReviewDialog getPendingProjects={getPendingProjects} approve={true} contentId={item.contentId} chapterId={item.chapterId}courseId={item.courseId} username={item.username} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} contentName={contentName} courseName={courseName} codelesson_id={item.codelesson_id}></MultipleReviewDialog>
                                    </> : null}

                                </Box>

                            </Grid>
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={6.5} >
                                {
                                    jupyterLoading ?
                                        <Box alignItems={"center"} border={"solid"} sx={{ borderWidth: 1, height: "550px" }} display={"flex"} justifyContent={"center"}>
                                            <CircularProgress />
                                        </Box>
                                        :
                                        isJupyterLoaded ?
                                            <iframe src={jupyterSource} width="100%" title='notebook'
                                                height="550"></iframe> : <Box alignItems={"center"} display={"flex"} height={550} justifyContent={"center"} border={"solid"} sx={{ borderWidth: "1px" }} >
                                                <Grid container>
                                                    <Grid item xs={12}><Typography sx={{ textAlign: "center" }}>Error al Cargar JupyterNotebook</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} ><Button variant='contained' onClick={() => { getStudentJupyterNotebookUrl(item.chapterId, item.courseId, contentId, jupyterNotebookId, item.username) }} >Recargar <ReplayIcon /> </Button></Grid>
                                                </Grid>
                                            </Box>}
                                <Fab onClick={() => { getStudentJupyterNotebookUrl(item.chapterId, item.courseId, contentId, jupyterNotebookId, item.username) }} /* onClick={handleOpen} */ aria-label="add" size="small" color="primary" sx={{ position: "absolute", right: 30, top: 70 }} >
                                    <ReplayIcon /></Fab>
                            </Grid>
                        </Grid>
                    </Box>


                </DialogContent>
            </Dialog>}

        </>
    )
}