import { useState, useContext, Fragment } from 'react';
import { DialogContentText, DialogContent, DialogTitle, Dialog, DialogActions, Button, Grid, List, Card, CardHeader, ListItem, ListItemText, Divider, IconButton, Box, Tabs, Tab, Typography, TableRow, TableCell, TableContainer } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context';
import AddIcon from '@mui/icons-material/Add';
import { SnackBarSuccess } from './SnackBarSuccess';
import { SnackBarError } from './SnackBarError';
import TableCoursePrograms from './TableCoursesProgram';
import CircularProgress from '@mui/material/CircularProgress';
type Props = {
  username: string
}

export default function CourseSelectUser({ username }: Props) {
  const ctx = useContext(myContext)
  const [page, setPage] = useState(0);
  const [left, setLeft] = useState<readonly any[]>([]);
  const [right, setRight] = useState<readonly any[]>([]);
  const [tabValue, setTabValue] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false);
  const [myProgramsCoursesId, setMyProgramsCoursesId] = useState<any[]>([])
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
  const [openResetStartDateDialog, setOpenResetStartDateDialog] = useState<boolean>(false)
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false)
  const [openCoursesNotRemovedDialog, setOpenCoursesNotRemovedDialog] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<any>({
    name: "",
    id: "",
    activation_date: "",
  })
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")
  const [programCourses, setProgramCourses] = useState<any>([])
  const [coursesNotRemoved, setCoursesNotRemoved] = useState<string[]>([])
  const [removedDisabled, setRemovedDisabled] = useState<boolean>(false)
  const [loadingTab, setLoadingTab] = useState<boolean>(true)
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)

  const handleClickOpen = () => {
    getCourses()
    setOpen(true);
  };
  const handleClose = () => {
    setTabValue(0)
    setLoadingTab(true)
    setRight([])
    setLeft([])
    setOpen(false)
  }

  const handleOpenAdd = (name: string, id: number) => {
    setSelectedData({ name: name, id: id })
    setOpenAddDialog(true)
  }

  const handleOpenResetStartDate = (name: string, id: number) => {
    setSelectedData({ name: name, id: id })
    setOpenResetStartDateDialog(true)
  }

  const handlerOpenRemove = (name: string, id: number, activation_date: string, programCourses?: any) => {
    if (programCourses) {
      setProgramCourses(programCourses)
    }
    setSelectedData({ name: name, id: id, activation_date: activation_date })
    setOpenRemoveDialog(true)
  }



  const handlerRemove = () => {
    setRemovedDisabled(true)
    let route = ""
    if (tabValue === 0) {
      route = "learncourses/removecourseadmin/"
    } else if (tabValue === 1) {
      route = "learnprograms/removeprogramadmin/"
    }
    const token = localStorage.getItem('token');
    MetadataApi.post(route, {
      id: selectedData.id,
      username: username,
      adminuser: ctx.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setRemovedDisabled(false)
      setCoursesNotRemoved(res.data)
      setOpenRemoveDialog(false)
      handleOpenSuccess()
      setRight([])
      setLeft([])
      if (tabValue === 0) {
        getCourses()
        handleUpdateSnackbar("Curso eliminado")
      } else if (tabValue === 1) {
        setOpenCoursesNotRemovedDialog(true)
        getPrograms()
        handleUpdateSnackbar("Programa eliminado")
      }

    }).catch((reason: AxiosError) => {
      setRemovedDisabled(false)
      handleOpenError()
      if (tabValue === 0) {
        getCourses()
        handleUpdateSnackbar("Eliminar Curso")
      } else if (tabValue === 1) {
        getPrograms()
        handleUpdateSnackbar("Eliminar Programa ")
      }
    })

  }
  const handlerAdd = () => {
    let route = ""
    if (tabValue === 0) {
      route = "learncourses/addcourseadmin/"
    } else if (tabValue === 1) {
      route = "learnprograms/addprogramadmin/"
    }
    const token = localStorage.getItem('token');
    MetadataApi.post(route, {
      id: selectedData.id,
      username: username,
      adminuser: ctx.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      handleOpenSuccess()
      setOpenAddDialog(false)
      setRight([])
      setLeft([])
      if (tabValue === 0) {
        getCourses()
        handleUpdateSnackbar("Curso añadido")
      } else if (tabValue === 1) {
        getPrograms()
        handleUpdateSnackbar("Programa añadido")
      }
    }).catch((reason: AxiosError) => {
      setRight([])
      setLeft([])
      handleOpenError()
      setOpenAddDialog(false)
      if (tabValue === 0) {
        getCourses()
        handleUpdateSnackbar("Añadir Curso")
      } else if (tabValue === 1) {
        getPrograms()
        handleUpdateSnackbar("Añadir Programa ")
      }
    })
  }

  const handlerResetStartDate = () => {
    
    const token = localStorage.getItem('token');
    MetadataApi.post("learncourses/resetdatecourse", {
      courseId: selectedData.id,
      username: username,
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      handleOpenSuccess()
      setOpenResetStartDateDialog(false)
      setRight([])
      setLeft([])   
      getCourses()
      handleUpdateSnackbar("Fecha de inicio del curso reiniciada")
  
    }).catch((reason: AxiosError) => {
      setRight([])
      setLeft([])
      handleOpenError()
      setOpenResetStartDateDialog(false)
      getCourses()
      handleUpdateSnackbar("Reiniciar fecha de inicio del curso")

    })
  }

  const getPrograms = () => {
    const token = localStorage.getItem('token');
    let myPrograms: any[] = []
    let allPrograms: any[] = []
    MetadataApi.get("programs/getallprogramsadmin/", {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res1: AxiosResponse) => {
      allPrograms = res1.data
      MetadataApi.post("learnprograms/getallprogramsbyuseradmin/", { username: username, adminuser: ctx.username }, {
        headers: ({
          Authorization: 'Bearer ' + token
        })
      }).then((res2: AxiosResponse) => {
        myPrograms = res2.data
        let noTaken = [...allPrograms]
        let contador = 0
        allPrograms.forEach((program, index) => {
          myPrograms.forEach((selected) => {
            if (index === 0) {
              setRight(right => [...right, [String(selected.name), String(selected.version), String(selected.programId), String(changeDateFormat(selected.activate_date)), selected.programcourses]])
            }
            if (program.name === selected.name) {
              noTaken.splice(contador, 1)
              contador--
            }
          });
          contador++
        });
        noTaken.forEach(item => {
          setLeft(left => [...left, [String(item.name), String(item.version), String(item.programId)]])
        })
        setLoadingTab(false)
      })
    })
  }

  const getCourses = () => {
    const token = localStorage.getItem('token');
    let myCourses: any[] = []
    let allCourses: any[] = []
    MetadataApi.post("learncourses/getallcoursesbyuseradmin/", {
      username: username,
      adminuser: ctx.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res1: AxiosResponse) => {

      myCourses = res1.data.courses
      setMyProgramsCoursesId(res1.data.myProgramsCoursesId)
      MetadataApi.get("courses/getallcoursesadmin/", {
        headers: ({
          Authorization: 'Bearer ' + token
        })
      }).then((res2: AxiosResponse) => {
        allCourses = res2.data
        let noTaken = [...allCourses]
        let contador = 0
        allCourses.forEach((course, index) => {
          myCourses.forEach((selected) => {
            if (index === 0) {
              setRight(right => [...right, [String(selected.name), String(selected.version), String(selected.courseId), String(selected.activate_date),String(selected.start_date),selected.hours_limit]])
            }
            if (course.courseId === selected.courseId) {
              noTaken.splice(contador, 1)
              contador--
            }
          });
          contador++

        });
        noTaken.forEach(item => {
          setLeft(left => [...left, [String(item.name), String(item.version), String(item.courseId)]])
        })
        setLoadingTab(false)
      })


    })
  }
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setLeft([])
    setRight([])
    setPage(0)
    setTabValue(newValue);
    setLoadingTab(true)
    if (newValue === 0) {
      getCourses()
    } else if (newValue === 1) {
      getPrograms()
    }
  };

  const [openKillNotebook, setOpenKillNotebook] = useState<boolean>(false)
  const openKillDialog = () => {
 
    setOpenKillNotebook(true)
  }
  const killJupyterNotebook = () => {
    const token = localStorage.getItem('token');
    MetadataApi.post("codelesson/killjupyternotebookstudent", {
      studentName: username,
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setOpenKillNotebook(false)
      handleUpdateSnackbar("Se han matado los procesos de los Notebooks ")
      handleOpenSuccess()
    }).catch((reason: AxiosError) => {
      setOpenKillNotebook(false)

      handleUpdateSnackbar("Matar los procesos de los Notebooks ")

      handleOpenError()
    })
  }

  const handleUpdateSnackbar = (action: string) => {
    setSnackbarMessage(action)
  }
  const handleOpenSuccess = () => {
    setOpenSuccess(true)
  }
  const handleOpenError = () => {
    setOpenError(true)
  }
  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const handleCloseError = () => {
    setOpenError(false)
  }

  const changeDateFormat = (date: any) => {
    if (date === "null" || date === null || date === undefined) {
      return " "
    } else {
      const dateObject = new Date(date);
      return dateObject.toISOString().replace("T", " ").replace("Z", " ").replace(".000", " ")
    }
  }
  const customList = () => (
    <Card>
      <CardHeader
        title="Disponibles"
        sx={{ height: 10 }}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <Divider />
      <List
        sx={{
          width: 300,
          height: 350,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
         <Grid container >
        {left.map((value: string, index: number) => {
          const labelId = `${value[0]}`;
          return (
            <Fragment key={index}>
             
                <ListItem

                  role="listitem"
                >
                  <Grid item xs={10}>
                    <ListItemText id={labelId} primary={`${value[0]} (Version ${value[1]})`} />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton color='success' onClick={() => { handleOpenAdd(value[0], parseInt(value[2])) }}><AddIcon /></IconButton>
                  </Grid>
                </ListItem>
             
            </Fragment>

          );
        })}
         </Grid>
      </List>
    </Card>
  );
  return (
    <>
      <Button sx={{ marginLeft: 1 }} onClick={handleClickOpen} size="small" variant='contained' color='primary'>
        Cursos y Programas
      </Button>
      {<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Gestión de Cursos y Programas de: {username}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6.5}>
              <Tabs value={tabValue} onChange={handleChangeTab} >
                <Tab label="Cursos" value={0} disabled={loadingTab} />
                <Tab label="Programas" value={1} disabled={loadingTab} />
              </Tabs>
            </Grid>
            <Grid item xs={5.5}>
              <Typography variant='h5'>{tabValue === 1 ? "Programas" : "Cursos"} Adquiridos</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center" >
            <Grid item xs={3.3}>{customList()}</Grid>
            <Grid item xs={0.2}>
            </Grid>
            <Grid item xs={8.5}><TableCoursePrograms right={right} username={username} tabValue={tabValue} handlerOpenRemove={handlerOpenRemove} myProgramsCoursesId={myProgramsCoursesId} page={page} setPage={setPage} openCoursesNotRemovedDialog={openCoursesNotRemovedDialog} setOpenCoursesNotRemovedDialog={setOpenCoursesNotRemovedDialog} coursesNotRemoved={coursesNotRemoved} handleOpenResetStartDate={handleOpenResetStartDate} openKillDialog={openKillDialog}/></Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>}
      {/* ADD DIALOG */}
      {<Dialog open={openAddDialog} onClose={() => { setOpenAddDialog(false) }} maxWidth="lg">
        <DialogContent>
          <DialogContentText>
            ¿Desea agregar el {tabValue === 0 ? "Curso" : "Programa"} : {selectedData.name}?
          </DialogContentText>
          <DialogActions>
            <Button variant='outlined' onClick={() => { setOpenAddDialog(false) }}>Cancelar</Button>
            <Button variant="contained" onClick={handlerAdd}>Añadir</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>}
      {/* RESET START_DATE DIALOG */}
      {<Dialog open={openResetStartDateDialog} onClose={() => { setOpenResetStartDateDialog(false) }} maxWidth="lg">
        <DialogContent>
          <DialogContentText>
            ¿Desea reiniciar la fecha de inicio del curso: {selectedData.name}?
          </DialogContentText>
          <DialogActions>
            <Button variant='outlined' onClick={() => { setOpenResetStartDateDialog(false) }}>Cancelar</Button>
            <Button variant="contained" onClick={handlerResetStartDate}>Reiniciar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>}
      {/*KILL JUPYTER */}
      {<Dialog
        open={openKillNotebook}
        onClose={() => { setOpenKillNotebook(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>

          <Typography variant='h5'>¿Desea matar los procesos de los Jupyter Notebooks?</Typography>
          <DialogActions>
            <Button onClick={() => { setOpenKillNotebook(false) }}>Cerrar</Button>
            <Button onClick={killJupyterNotebook} variant='contained'>Matar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>}
      {/* REMOVE DIALOG */}
      {<Dialog open={openRemoveDialog} onClose={() => { setOpenRemoveDialog(false) }} >
        <DialogContent>
          <Typography variant='h5'>¿Está seguro de eliminar el {tabValue === 0 ? "Curso" : "Programa"}: "{selectedData.name}"?</Typography>

          <DialogContentText>

            {tabValue === 1 ? <>
              <Typography display={"flex"} justifyContent={"center"} color={"error"} variant='subtitle1'>*Dentro del programa con fecha de activación  " {selectedData.activation_date}" se presentan los siguientes Cursos y sus correspondientes fechas de activación</Typography>
              <TableContainer sx={{ border: "solid", borderWidth: 0.5, marginTop: 1 }}>

                {programCourses.map((course: any) => {
                  return (
                    <TableRow >
                      <TableCell width={"70%"}>{course.course.name}</TableCell>
                      <TableCell width={"30%"}>{changeDateFormat(course.course.activate_date)}</TableCell>
                    </TableRow>
                  )
                })}</TableContainer>
            </>
              : null}
            {removedDisabled ? <Box alignItems={"center"} display={"flex"} justifyContent={"center"}  >
              <CircularProgress />
            </Box> : null}
          </DialogContentText>
          <DialogActions>
            <Button variant='outlined' onClick={() => { setOpenRemoveDialog(false) }} disabled={removedDisabled}>Cancelar</Button>
            <Button variant='contained' onClick={handlerRemove} disabled={removedDisabled}>Eliminar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>}
      <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />
    </>
  );
}
