
import { Fab, IconButton, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
type Props = {
    ShowButtonResponses: boolean,
    handleOpenWindow: any,
    isWindowReponsesOpen: boolean,
    handleCloseWindow: any,
    tests:any,
    openDrawer:boolean

}

export function TestWindow({ ShowButtonResponses, handleOpenWindow, isWindowReponsesOpen, handleCloseWindow,tests,openDrawer}: Props) {
   
    const theme = useTheme();
    const [hasManyCharacters,setHasManyCharacters]=useState<boolean>(false)

    const openedMixin = (theme: Theme): CSSObject => ({
        width: "30%",
        height: '100%',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
      }
      );
      const closedMixin = (theme: Theme): CSSObject => ({
        height: '100%',

        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(85)} + 15px)`,
        [theme.breakpoints.up('sm')]: {
          width: `calc(${theme.spacing(85)} + 15px)`,
        },
      });

      useEffect(()=>{
        if(tests){
            for(var i=0;i<tests.length;i++){
                let tempValue=tests[i].output.toString()
                if(tempValue.length>20){
                    setHasManyCharacters(true)
                    break
                }else{
                    setHasManyCharacters(false)
                }
            }
        }
    },[tests])
    return (
        <>
            {ShowButtonResponses ?
                <Fab onClick={handleOpenWindow} variant='extended'
                    sx={{ position: "fixed",  bottom: 0, right: 150, backgroundColor: "#e0e0e0", borderRadius: 0, width:(openDrawer?"30%":"45.5%") , alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                    <Typography  textTransform="none" variant='body1' >Resultados de Test </Typography><ExpandLess fontSize='small' />
                </Fab> : null}
            {isWindowReponsesOpen ?
                <Grid item xs={12}>
                    <Box
                        overflow={"hidden"}
                        sx={{
                            '&::-webkit-scrollbar': {
                                width: 0
                              },
                            position: 'fixed',
                            bottom: 0, right: 155,
                           
                                ...(openDrawer && { ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme), }),
                                ...(!openDrawer && { ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme), }),
                            height: "400px",
                        }}
                    >
                        <Paper>
                            <Box

                                sx={{
                                    '&::-webkit-scrollbar': {
                                        width: 0
                                      },
                                    
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    p: 1,
                                    backgroundColor: "#e0e0e0"
                                }}
                            >
                                <Typography sx={{marginLeft:1}} variant="body1">Resultados de Tests</Typography>
                                <IconButton onClick={handleCloseWindow}>
                                    <ExpandMore />
                                </IconButton>
                            </Box>
                            <Box sx={{ height: "330px", maxHeight: "100%", overflow: "auto",margin:1}}>

                                    {tests[0].status==="Error"?
                                    <Typography marginBottom={1}>
                                            
                                    ERROR: <strong>{tests[0].output}</strong>
                                </Typography>      
                                    :
                                    tests.map((test: any, index: number) => (
                                        <Fragment key={index}>
                                            <Grid item xs={hasManyCharacters?12:6}>
                                            <Typography marginBottom={1}>
                                            
                                            Output {index+1}: <strong>{test.output}</strong>
                                        </Typography>             
                                            </Grid>
                                                                
                                        </Fragment>
                                    ))
                                    }
                                    
                             
                            </Box>
                        </Paper>
                    </Box></Grid>
                : null}
        </>
    )
}