import { useContext, useEffect, useState } from 'react';
import { Grid, Typography,Tab,Tabs, Box} from '@mui/material';
import { myContext } from '../contexts/Context';
import { Role } from '../enums/roles.enums';
import TableCourses from '../components/TableCourses';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import TableSkeleton from '../components/TableSkeleton'
export default function Courses() {
  const [data, setData] = useState<any>([]);
  const ctx = useContext(myContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [select,setSelect] = useState<number>(0)
  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const swapObjects = (up: boolean, auxArray: object[], index1: number) => {
    if (up) {
      if (index1 !== 0) {
        let index2 = index1 - 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
      }
    } else {
      if (index1 !== auxArray.length - 1) {
        let index2 = index1 + 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
      }
    }
    const newArray = data.map((element:any, i:number) => {
      return element = auxArray[i]
    })
    return newArray
  }
  const handleSwapTable = (up: boolean, selectedId: number) => {
    let auxArray = [...data]
    let index1 = 0
    for (let i = 0; i < data.length; i++) {
      if (data[i].courseId === selectedId) {
        index1 = i
        break
      }
    }
    const newCourseArray=swapObjects(up, auxArray, index1)
    setData(newCourseArray);
    handdleUpdateOrder(newCourseArray)
  }
  const handdleUpdateOrder = (array:any) => {
    let contador = 1
    let courseUpdate: any[] = []
    for (const data of array) {
      courseUpdate.push({ id: data.courseId, order: contador })
      for (const version of data.version_history) {
        courseUpdate.push({ id: version.courseId, order: contador })
      }
      contador++
    }
    const token = localStorage.getItem('token');
    MetadataApi.put("courses/updatecourseorder/", {
      courseUpdate
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
    })
  }
  const changeSelect=(event: React.SyntheticEvent,newValue: number)=>{
    setSelect(newValue)
  }

  
  const getCourses = (isProject:boolean) => {
    const token = localStorage.getItem('token');
    MetadataApi.post("courses/allcourses",{
      isProject:isProject
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      /* setData(res.data[0])
      setDataProject(res.data[1]) */
      setData(res.data)
      setLoading(false)
    } )
  }

  
  useEffect(()=>{
    setLoading(true)
    setPage(0)
    let isProject=false
    if(select===1){
      isProject=true
    }
    getCourses(isProject)
  },[select])
  return (
    <Grid sx={{marginRight:2,marginLeft:2}}>
      <Grid container spacing={2} justifyContent="center" pb={5}>
        <Grid item>
          {ctx && Object.keys(ctx).length !== 0 && ctx.roles?.includes(Role.Creator) ?
            <Typography variant='h4' alignItems='center'>Listado de Cursos y Proyectos</Typography>
            : ''}
        </Grid>
      
      </Grid>
      <Box sx={{ borderColor: 'divider' }}>
                    <Tabs value={select} onChange={changeSelect} aria-label="basic tabs example">
                        <Tab label="Curso" value={0} />
                        <Tab label="Proyecto" value={1} />
                    </Tabs>
                </Box>
      {!loading? 
      <Grid>
        {select===0?<TableCourses data={data} getCourses={getCourses}  handleChangePage={handleChangePage} page={page} isProject={false} handleSwapTable={handleSwapTable} />
      :select===1? <TableCourses data={data} isProject={true} getCourses={getCourses} handleSwapTable={handleSwapTable} handleChangePage={handleChangePage} page={page}/>
    :null}
      </Grid>:
      <Grid>
        <TableSkeleton ></TableSkeleton>
      </Grid>
      
      }
    
    </Grid>

  )
}