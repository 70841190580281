import { useEffect, useState, Fragment } from "react"
import { Button, Dialog, DialogContent, Grid, Card, Box, Typography } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import MetadataApi from "../../apis/metadata.api";
import { AxiosResponse } from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import { allTypeData } from "../../enums/typeContent.enum";
import ReplayIcon from '@mui/icons-material/Replay';
type Props = {
    username: string,
    courseId: number
}

export function ProgressView({ username, courseId }: Props) {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [jupyterLoading, setJupyterLoading] = useState<boolean>(true)
    const [isJupyterLoaded, setIsJupyterLoaded] = useState<boolean>(false)
    const [course, setCourse] = useState<any>([])
    const [allNodeIds, setAllNodeIds] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [jupyterSource, setJupyterSource] = useState<any>()
    const [selectedItem, setSelectedItem] = useState<any>({
        type: "",
        test_corrects: 0,
        test_total: 0,

    })
    const [dataToGetJupyter, setDataToGetJupyter] = useState<any>({
        chapterId: 0,
        contentId: 0,
        jupyterId: 0,
        courseId: 0,
        username: ""
    })
    const getChaptersByCourse = () => {
        const token = localStorage.getItem('token');
        MetadataApi.post("learncourses/getcourse/", {
            courseId: courseId,
            username: username
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {

            setCourse(res.data)
            getAllnodeIds(res.data.chapters)

        })
    }

    const getAllnodeIds = (chapters: any) => {
        let nodes: string[] = []
        chapters.forEach((chapter: any, indexChapter: number) => {
            nodes.push(("chapter" + String(chapter.chapterId)))
            chapter.contents.forEach((content: any, indexContent: number) => {
                nodes.push(("content" + String(content.contentId)))
                if (content.codelesson.length > 0) {
                    content.codelesson.forEach((codelesson: any, indexJupyter: number) => {
                        nodes.push(("codelesson" + String(codelesson.id)))
                    })
                }

            })
        })
        setLoading(true)
        setAllNodeIds(nodes)
    }

    const handlerType = (type: string, id: number, test_corrects: number, test_total: number, contentId?: number, chapterId?: number) => {
        setJupyterLoading(true)
        setIsJupyterLoaded(false)
        setSelectedItem({ type: type, test_total: test_total, test_corrects: test_corrects })
        if (type === allTypeData.JupyterLesson) {
            const dataJupyter = {
                chapterId: chapterId,
                contentId: contentId,
                jupyterId: id,
                courseId: course.courseId,
                username: username
            }
            setDataToGetJupyter(dataJupyter)
            getStudentJupyterNotebookUrl(false, dataJupyter)
        }

    }

    const openTreeview = () => {
        getChaptersByCourse()

        setOpenDialog(true)

    }

    const closeTreeview = () => {
        setJupyterLoading(true)
        setSelectedItem({})
        setOpenDialog(false)
        setLoading(false)
        setAllNodeIds([])
    }


    const getStudentJupyterNotebookUrl = (refresh: boolean, dataJupyter?: any) => {
        let sendObject
        if (refresh) {
            sendObject = dataToGetJupyter
        } else {
            sendObject = dataJupyter
        }
        /* setJupyterLoading(true) */
        const token = localStorage.getItem('token');
        MetadataApi.post("learncontents/getjupyternotebookstudent", {
            chapterId: sendObject.chapterId,
            courseId: sendObject.courseId,
            contentId: sendObject.contentId,
            username: sendObject.username,
            jupyterId: sendObject.jupyterId,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setJupyterLoading(false)
            if (res.data === "code error") {
                setIsJupyterLoaded(false)

            } else {
                setIsJupyterLoaded(true)
                setJupyterSource(res.data)
            }
        },)

    }
    useEffect(() => {
        setJupyterLoading(true)
    }, [selectedItem.type])
    return (
        <>
            <Button variant="contained" onClick={openTreeview}>Contenidos</Button>

            {<Dialog open={openDialog} onClose={closeTreeview} fullWidth maxWidth="xl">
                <DialogContent sx={{ height: 600 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}><Typography variant="h5" >Usuario: {course.username} </Typography><Typography variant="h5" >Curso: {course.name} </Typography><Typography variant="h6">Progreso: {course.test_corrects === null ? 0 : course.test_corrects}/{course.test_total === null ? 0 : course.test_total}</Typography></Box>
                    <Grid container>
                        <Grid item xs={4}>
                            <Box sx={{ display: "flex", height: 550 }}>
                                <Grid container>

                                    {loading ?
                                        <Card sx={{ width: "100%", height: "100%", border: "solid", borderWidth: 0.1 }}>
                                            <TreeView defaultExpanded={allNodeIds}
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}


                                            >
                                                {
                                                    course.chapters.map((chapter: any, indexChapter: number) => {
                                                        return (
                                                            <Fragment key={"chapter" + String(chapter.chapterId)}>
                                                                <TreeItem sx={{ backgroundColor: "#bdbdbd" }} key={"chapter" + String(chapter.chapterId)} nodeId={"chapter" + String(chapter.chapterId)} label={"Capitulo " + (indexChapter + 1) + ": " + chapter.name}

                                                                >
                                                                    {chapter.contents.map((content: any, indexContent: number) => {

                                                                        return (
                                                                            <Fragment key={indexContent}>
                                                                                <Grid container>

                                                                                    {content.type === "JupyterLesson" || content.type === "Questions" ?
                                                                                        <><Grid item xs={10.5} >
                                                                                            <TreeItem sx={{ backgroundColor: "#e0e0e0" }} key={"content" + String(content.contentId)} nodeId={"content" + String(content.contentId)} onClick={() => { handlerType(allTypeData.Content, content.contentId, content.test_corrects, content.test_total) }} label={
                                                                                                <Typography> Contenido {indexContent + 1}: {content.name} {content.type}
                                                                                                </Typography>
                                                                                            }
                                                                                            >
                                                                                                {content.codelesson.length > 0 ?
                                                                                                    content.codelesson.map((codelesson: any, indexJupyter: number) => {

                                                                                                        return (
                                                                                                            <Grid container>
                                                                                                                <Grid item xs={10.5}>   <TreeItem sx={{ fontStyle: "italic", backgroundColor: "white" }} key={"codelesson" + String(codelesson.id)} nodeId={"codelesson" + String(codelesson.id)} label={"Jupyter lesson " + (indexJupyter + 1) + ": " + codelesson.title}
                                                                                                                    onClick={() => {
                                                                                                                        handlerType(allTypeData.JupyterLesson, codelesson.id, codelesson.test_corrects, codelesson.test_total, content.contentId, chapter.chapterId)
                                                                                                                    }}
                                                                                                                ></TreeItem> </Grid>                     <Grid item xs={1.5} sx={{ backgroundColor: "white" }}>                        <Typography align="right"><Box sx={{ fontWeight: "bold", display: "inline" }}>{codelesson.test_corrects === null ? "" : codelesson.test_corrects + "/"}{codelesson.test_total === null ? "" : codelesson.test_total}</Box></Typography>                   </Grid>                       </Grid>
                                                                                                        )
                                                                                                    }) :
                                                                                                    null}
                                                                                            </TreeItem>
                                                                                        </Grid>
                                                                                            <Grid item xs={1.5} sx={{ backgroundColor: "#e0e0e0" }}>
                                                                                                <Typography sx={{ textAlign: "right" }}>
                                                                                                    <span style={{ fontWeight: "bold", display: "inline" }}>
                                                                                                        {content.test_corrects === null ? "" : content.test_corrects + "/"}
                                                                                                        {content.test_total === null ? "" : content.test_total}
                                                                                                    </span>
                                                                                                </Typography>
                                                                                            </Grid> </> : null}
                                                                                </Grid>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                    }
                                                                </TreeItem>
                                                            </Fragment>

                                                        )
                                                    })
                                                }
                                            </TreeView>

                                        </Card>
                                        : null}
                                </Grid>
                            </Box>



                        </Grid>
                        <Grid item xs={0.2} />
                        <Grid item xs={7.8}>
                            <Grid container>
                                {
                                    selectedItem.type === allTypeData.JupyterLesson ?
                                        <>
                                            {jupyterLoading ?
                                                <Grid item xs={12}>
                                                    <Box alignItems={"center"} display={"flex"} height={550} justifyContent={"center"} border={"solid"} sx={{ borderWidth: "1px" }} >
                                                        <CircularProgress />
                                                    </Box>
                                                </Grid>


                                                :
                                                isJupyterLoaded ?
                                                    <iframe src={jupyterSource} width="100%" title='notebook'
                                                        height="550"></iframe>
                                                    :
                                                    <Box alignItems={"center"} display={"flex"} height={550} width={"100%"} justifyContent={"center"} sx={{ borderWidth: "1px" }} >
                                                        <Grid container>
                                                            <Grid item xs={12}><Typography sx={{ textAlign: "center" }}>Error al Cargar JupyterNotebook</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} ><Button variant='contained' onClick={() => { getStudentJupyterNotebookUrl(true) }} >Recargar <ReplayIcon></ReplayIcon> </Button></Grid>
                                                        </Grid>
                                                    </Box>
                                            }
                                        </>
                                        : null}
                            </Grid>



                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>}
        </>
    )
}