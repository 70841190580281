import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { useState, useEffect, useContext } from 'react';
import { ChatDialog } from "./ChatDialog";
import { TextField, IconButton, Grid } from "@mui/material";
import { ChatStatus } from "../../enums/chatStatus.enum";
import { myContext } from "../../contexts/Context";
import RefreshIcon from '@mui/icons-material/Refresh';
type Props = {
  page: number,
  handleChangePage: any,
  data: any,
  getAllChats: Function,
  completed: boolean,
  tabValue: ChatStatus,
  setTabNumber: Function,
  handleOpenSuccess: Function,
  handleOpenError: Function,
  handleUpdateSnackbar: Function
}

export function TableChat({ page, handleChangePage, data, getAllChats, tabValue ,handleOpenError,handleOpenSuccess,handleUpdateSnackbar }: Props) {
  const ctx = useContext(myContext)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
  };

  const changeDateFormat = (date: Date) => {
    if (date) {
      const dateObject = new Date(date);
      const day = dateObject.getDate().toString().padStart(2, '0'); // Day (with leading zero)
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month (add 1 because months are zero-based)/*  */
      const hours = dateObject.getHours().toString().padStart(2, '0'); // Hours
      const minutes = dateObject.getMinutes().toString().padStart(2, '0'); // Minutes

      // Format the desired output
      const formattedDate = `${day}/${month} ${hours}:${minutes}`;/*  */
      return formattedDate
    } else {
      return " "
    }
  }
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchItem = event.target.value
    setSearchTerm(searchItem);
    let actualPage = page
    if (searchItem === '') {

      handleChangePage(null, actualPage);
    } else {
      handleChangePage(null, 0);
    }
  };
  const filteredData = data.filter((item: any) =>
    item.teacher_reviewing.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if ((tabValue === ChatStatus.Reviewing || tabValue === ChatStatus.Completed) && ctx.username) {
      setSearchTerm(ctx.username.toString())
    }
  }, [tabValue, ctx.username])

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>

          <Toolbar >
            <Grid container>
              <Grid item xs={2} />
              <Grid item xs={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {tabValue === ChatStatus.Started ? null :
                  <TextField
                    label="Buscar Profesor"
                    value={searchTerm}
                    onChange={handleSearch}
                    size="small"
                    sx={{ alignSelf: "center" }}
                    margin="normal"
                  />
                }
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                <IconButton color="primary" onClick={() => { getAllChats(tabValue) }}>
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
          <TableContainer sx={{ width: "100%", height: "100%" }}>
            <Table
              sx={{ height: 400 }}
              aria-labelledby="tableTitle"
            >
              <TableHead >
                <TableRow >
                  <TableCell align='left'>Curso</TableCell>
                  <TableCell align='left'>Contenido</TableCell>
                  <TableCell align='left'>Codelesson</TableCell>
                  <TableCell align='left'>Lección</TableCell>
                  <TableCell align='left'>Usuario</TableCell>
                  <TableCell align='left'>Profesor</TableCell>
                  <TableCell align='center'>Ultima Modificación</TableCell>
                  <TableCell align='center'>Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index * (page + 1)}
                      ><TableCell align="left" >{row.course_name}</TableCell>
                        <TableCell align="left" >{row.content_name}</TableCell>
                        <TableCell align="left" >{row.codelesson_name ? row.codelesson_name : ""}</TableCell>
                        <TableCell align="left" >{row.type}</TableCell>
                        <TableCell align="left">{row.username}</TableCell>
                        <TableCell align="left">{row.teacher_reviewing}</TableCell>
                        <TableCell align="center">{changeDateFormat(row.last_update)}</TableCell>
                        <TableCell align="center">{<ChatDialog contentId={row.contentId} codelessonId={row.codelessonId === 0 ? -1 : row.codelessonId} username={row.username} codelesson_name={row.codelesson_name} content_name={row.content_name} courseId={row.courseId} course_name={row.course_name} getAllChats={getAllChats} type_lesson={row.type} chapterId={row.chapterId} tabValue={tabValue}
                          handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}
                        ></ChatDialog>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>


      </Box>
     
    </>

  );
}