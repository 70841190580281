import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { allTypeData } from "../enums/typeContent.enum"
import MetadataApi from "../apis/metadata.api"
import { AxiosError, AxiosResponse } from "axios"

type Props = {
    openDelete: boolean,
    setOpenDelete: Function,
    deleteType: string,
    deleteId: number,
    getAlldata: Function,
    setDeleteId: Function,
    setLessonStatus: Function,
    handleUpdateSnackbar: Function,
    handleOpenSuccess: Function,
    handleOpenError: Function,

}

export function TreeviewDelete({ openDelete, setOpenDelete, deleteType, deleteId, getAlldata, setDeleteId, setLessonStatus, handleUpdateSnackbar, handleOpenSuccess, handleOpenError }: Props) {
    const handleDelete = () => {
        let route = ""
        switch (deleteType) {
            case allTypeData.Chapter:
                route = "chapters/deletechapter/"
                break
            case allTypeData.Content:
                route = "contents/deletecontent/"
                break
            case allTypeData.JupyterLesson:
                route = "codelesson/deletejupyterlesson/"
                break
            case allTypeData.Reading:
                route = "readinglesson/deletereadinglesson/"
                break
            case allTypeData.Questions:
                route = "questions/deletequestion/"
                break
        }
        const token = localStorage.getItem('token');

        MetadataApi.delete(route + deleteId, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            getAlldata()
            setDeleteId(0)
            setOpenDelete(false)
            setLessonStatus(false)
            handleUpdateSnackbar(String(deleteType) + " Eliminado")
            handleOpenSuccess()
        }).catch((reason: AxiosError) => {

            handleUpdateSnackbar("Eliminar " + String(deleteType))
            handleOpenError()
        })

    }
    return (<>
        {<Dialog open={openDelete} onClose={() => { setOpenDelete(false) }} maxWidth="md">
            <DialogContent>
                ¿Desea realmente elminar el
                {deleteType === allTypeData.Chapter ? " Capítulo " :
                    deleteType === allTypeData.Content ? " Contenido " :
                        deleteType === allTypeData.JupyterLesson ? " Jupyter lesson " :
                            deleteType === allTypeData.Questions ? " Question lesson " :
                                deleteType === allTypeData.Reading ? " Reading lesson " : null}

                seleccionado?
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setOpenDelete(false) }} variant="outlined">Cancelar</Button>
                <Button onClick={handleDelete} variant="contained">Eliminar</Button>
            </DialogActions>
        </Dialog>}</>)
}