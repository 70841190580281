import { Button } from '@mui/material';
type Props = {
    textChanged: boolean,
    isSaving: boolean,
    firstSaveDone: boolean,
    saveFunction: Function
}

export function SaveButton({ textChanged, isSaving, firstSaveDone, saveFunction }: Props) {
    return (
        <>
            {textChanged ?
                (isSaving ?
                    <Button variant='contained' disabled={true} >Guardando</Button>
                    : <Button variant='contained' disabled={false} onClick={() => {
                        saveFunction()
                    }} > Guardar</Button>)
                :
                <Button variant='contained' disabled={true}  >{!firstSaveDone ? "Guardar" : "Guardado"}</Button>

            }
        </>
    )
}