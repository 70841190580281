import { useState, Fragment, useEffect,useContext} from 'react';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography, Tooltip, Collapse, Grid } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import { ProgramDialog } from './ProgramDialog';
import CourseSelect from './CourseSelect';
import { Box } from '@mui/system';
import { SnackBarSuccess } from './SnackBarSuccess';
import { SnackBarError } from './SnackBarError';
import { myContext } from '../contexts/Context';
export default function TableProgram(props: any) {
  const ctx=useContext(myContext)
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")

  const handleUpdateSnackbar = (action: string) => {
    setSnackbarMessage(action)
  }
  const handleOpenSuccess = () => {
    setOpenSuccess(true)
  }
  const handleOpenError = () => {
    setOpenError(true)
  }
  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const handleCloseError = () => {
    setOpenError(false)
  }
  const [selectedProgramId, setSelectedProgramId] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("");
  const handlerChangeState = (programId: number) => {
    const token = localStorage.getItem('token');

    MetadataApi.put("programs/updateState/" + programId, {creatorUser:ctx.username}, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      
      handleUpdateSnackbar("Programa Publicado ")
      props.getPrograms()
 
      handleOpenSuccess()

    }).catch((reason: AxiosError) => {


      handleUpdateSnackbar("Publicar el Programa ")

      handleOpenError()
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows =
    page >= 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  const handleChange = (value: string, id: number) => {
    setSelectedRadioButton(value)
    setSelectedProgramId(id)
  }


  
  const publishProgram = (program: any) => {
    /* delete program.version_history */
    const token = localStorage.getItem('token');
    MetadataApi.put("programs/publish/", {
      program
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      
      handleUpdateSnackbar("Programa Publicado ")
      props.getPrograms()
 
      handleOpenSuccess()

    }).catch((reason: AxiosError) => {


      handleUpdateSnackbar("Publicar el Programa ")

      handleOpenError()
    })
  }
  //TABLA DE VERSIONES
  function VersionTable(props: any) {
    const [creation, setCreation] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [open, setOpen] = useState(false);
    const createDuplicate = (program: any) => {
    
      const token = localStorage.getItem('token');
      setIsCreating(true)
      MetadataApi.post("programs/duplicateprogram/" + program.programId, {
        programId: 0,
        name: program.name,
        shortDescription: program.shortDescription,
        Description: program.Description,
        type: program.type,
        difficulty: program.difficulty,
        listPrice: program.listPrice,
        discountPrice: program.discountPrice,
        isActive: false,
        order: props.order,
        version: 0,
        id_group: program.id_group,
        draft: true,
        professor_link: program.professor_link,
        professor_name: program.professor_name,
        hours: program.hours
      }, {
        headers: ({
          Authorization: 'Bearer ' + token
        })
      }).then((res: AxiosResponse) => {
        props.getPrograms()
        handleUpdateSnackbar("Borrador de Programa Creado")
        handleOpenSuccess()
        setIsCreating(false)
      }).catch((reason: AxiosError) => {
        handleUpdateSnackbar("Crear Borrador del Programa ")
        handleOpenError()
        setIsCreating(false)
      })
  
    }
    useEffect(() => {
      if (props.row.draft) {
        setCreation(true)
      }
      for (let data of props.row.version_history) {
        if (data.draft) {
          setCreation(true)
        }
      }

    }, [props.row.draft, props.row.version_history])
  
    return (
      <Fragment>
        <TableRow
          hover
          tabIndex={-1}
          key={props.row.name}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell ><Radio
            checked={selectedRadioButton === props.row.name}
            onChange={() => { props.handleChange(props.row.name, props.row.programId) }}
            value="a"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
          /></TableCell>
          <TableCell align="left" >{props.row.name}</TableCell>
          <TableCell align="left" >{props.row.draft ? "Borrador" : props.row.version}</TableCell>
          <TableCell align="left" >{props.row.difficulty}</TableCell>
          <TableCell align="left" >{props.row.isActive ? "Publicado" : "Despublicado"}</TableCell>
          <TableCell align="left" >
            <ProgramDialog getPrograms={props.getPrograms} item={props.row} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
            <CourseSelect toPublish={false} item={props.row} type={"Cursos"} isProject={false} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
            <CourseSelect toPublish={false} item={props.row} type={"Proyectos"} isProject={true} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
            {props.row.draft ?
              <Button size="small" variant='outlined' color='primary' onClick={() => { publishProgram(props.row) }} sx={{ marginLeft: 1 }}>Publicar</Button>
              :
              props.row.isActive ?
                <Button sx={{ marginLeft: 1 }} onClick={() => { handlerChangeState(props.row.programId) }} size="small" variant='outlined' color='primary' >Despublicar</Button>
                :
                <Button sx={{ marginLeft: 1 }} onClick={() => { handlerChangeState(props.row.programId) }} size="small" variant='contained' color='primary'>Publicar</Button>}

          </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: "#dddddd" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography variant="h6" gutterBottom component="div">
                      Versiones históricas
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                 
                  {isCreating ? (
                      <Button
                        variant="contained"
                        disabled={true}
                        onClick={() => {
                          createDuplicate(props.row);
                        }}
                      >
                        Creando borrador
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={creation}
                        onClick={() => {
                          createDuplicate(props.row);
                        }}
                      >
                        Crear borrador
                      </Button>
                    )}
                    </Grid>
                </Grid>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'></TableCell>
                      <TableCell align='left'></TableCell>
                      <TableCell align='left'>Nombre</TableCell>
                      <TableCell align='left'>Versión</TableCell>
                      <TableCell align='left'>Dificultad</TableCell>
                      <TableCell align='left'>Estado</TableCell>
                      <TableCell align='left'>Opciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.row.version_history.map((versionsRow: any, index: number) => {

                      return (
                        <TableRow key={versionsRow.name + index}>
                          <TableCell align='left'></TableCell>
                          <TableCell align='left'></TableCell>
                          <TableCell align="left" >{versionsRow.name}</TableCell>
                          <TableCell align="left" >{versionsRow.draft ? "Borrador" : versionsRow.version}</TableCell>
                          <TableCell align="left" >{versionsRow.difficulty}</TableCell>
                          <TableCell align="left" >{versionsRow.isActive ? "Publicado" : "Despublicado"}</TableCell>
                          <TableCell align="left" >
                            <ProgramDialog id_group={props.row.id_group} getPrograms={props.getPrograms} item={versionsRow} versionRow={true} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
                            <CourseSelect versionRow={true} toPublish={true} item={versionsRow} type={"Cursos"} isProject={false} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
                            <CourseSelect versionRow={true} toPublish={true} item={versionsRow} type={"Proyectos"} isProject={true} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
                            {versionsRow.draft ?
                              <Button size="small" variant='outlined' onClick={() => { publishProgram(versionsRow) }} color='primary' sx={{ marginLeft: 1 }}>Crear</Button>
                              :
                              versionsRow.isActive ?
                                <Button sx={{ marginLeft: 1 }} onClick={() => { handlerChangeState(versionsRow.programId) }} size="small" variant='outlined' color='primary' >Despublicar</Button>
                                :
                                <Button sx={{ marginLeft: 1 }} onClick={() => { handlerChangeState(versionsRow.programId) }} size="small" variant='outlined' color='primary'>Publicar</Button>}
                          </TableCell>
                        </TableRow>
                      )
                    })}

                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }

  return (
    <>
      <Toolbar>
        <Grid container>
          <Grid item xs={9}>

          </Grid>
          <Grid item xs={3} alignItems={"right"}>
            <ProgramDialog handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} getPrograms={props.getPrograms} add={true} id_group={0} alignItems="center" handleUpdateSnackbar={handleUpdateSnackbar} />
            <Tooltip title="Filter list">
              <Button size='large' onClick={() => { props.handleSwapTable(true, selectedProgramId) }} startIcon={<KeyboardArrowUpIcon />} />
            </Tooltip>
            <Tooltip title="Filter list">
              <Button size='large' onClick={() => { props.handleSwapTable(false, selectedProgramId) }} startIcon={<KeyboardArrowDownIcon />} />
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer>
        <Table
          sx={{ minHeight: 400, height: 400 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell ></TableCell>
              <TableCell ></TableCell>
              <TableCell align='left'>Nombre</TableCell>
              <TableCell align='left'>Versión</TableCell>
              <TableCell align='left'>Dificultad</TableCell>
              <TableCell align='left'>Estado</TableCell>
              <TableCell align='left'>Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                return (
                  <VersionTable key={row.name} row={row} getPrograms={props.getPrograms} handleChange={handleChange} handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenError}></VersionTable>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (75) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />

    </ >
  );
}