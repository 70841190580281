import { useEffect, useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { Grid, Typography, Container, Tab, Tabs, Box } from '@mui/material';

import TableSkeleton from '../components/TableSkeleton';
import { ContentStatus } from '../enums/contentStatus.enum';
import { TableReview } from '../components/reviews/TableReview';
import { SnackBarSuccess } from '../components/SnackBarSuccess';
import { SnackBarError } from '../components/SnackBarError';
export function Reviews() {
    const [pendingProjects, setPendingProjects] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [select, setSelect] = useState<number>(0)
    const [page, setPage] = useState(0);
    const [openSuccess, setOpenSuccess] = useState<boolean>(false)
    const [openError, setOpenError] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const handleUpdateSnackbar = (action: string) => {
        setSnackbarMessage(action)
    }
    const handleOpenSuccess = () => {
        setOpenSuccess(true)
    }
    const handleOpenError = () => {
        setOpenError(true)
    }
    const handleCloseSuccess = () => {
        setOpenSuccess(false)
    }
    const handleCloseError = () => {
        setOpenError(false)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };




    const getPendingProjects = (commentStatus: ContentStatus) => {
        const token = localStorage.getItem('token');
        MetadataApi.post("learncomments/getlearncommentsbystatus", {
            commentStatus: commentStatus
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setPendingProjects(res.data)
            setLoading(false)
        })
    }
    const changeSelect = (event: React.SyntheticEvent, newValue: number) => {
        setSelect(newValue)
    }
    useEffect(() => {
        getPendingProjects(ContentStatus.Pending)
    }, [])

    useEffect(() => {
        setLoading(true)
        setPage(0)
        let status = ContentStatus.Pending

        if (select === 1) {
            status = ContentStatus.Completed
        } else if (select === 2) {
            status = ContentStatus.Failed
        }
        getPendingProjects(status)



    }, [select])

    return (

        <Container className='centered'>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item>
                    <Typography variant='h4' alignItems='center'>Listado de Proyectos</Typography>

                </Grid>
            </Grid>
            <Box sx={{ borderColor: 'divider' }}>
                <Tabs value={select} onChange={changeSelect} aria-label="basic tabs example">
                    <Tab label="Pendientes" value={0} />
                    <Tab label="Completados" value={1} />
                    <Tab label="Fallidos" value={2} />

                </Tabs>
            </Box>
            {!loading ?
                <Grid >
                    {select === 0 ? <TableReview projectStatus={"Pendientes"} pending={true} data={pendingProjects} getPendingProjects={getPendingProjects} page={page} handleChangePage={handleChangePage} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}></TableReview>
                        : select === 1 ? <TableReview projectStatus={"Completados"} pending={false} data={pendingProjects} getPendingProjects={getPendingProjects} page={page} handleChangePage={handleChangePage} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}></TableReview>
                            : select === 2 ? <TableReview projectStatus={"Fallidos"} pending={false} data={pendingProjects} getPendingProjects={getPendingProjects} page={page} handleChangePage={handleChangePage} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}></TableReview>
                                :

                                null}


                </Grid>
                :
                <Grid >
                    <TableSkeleton ></TableSkeleton>
                </Grid>

            }
            <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
            <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />

        </Container>

    )
}