import { Button, Grid, Typography, TextField, Select, MenuItem } from '@mui/material';
import { useState, useEffect, Fragment, ChangeEvent } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';

import { Box } from '@mui/system';
import validator from 'validator';
import { TypeInput } from '../enums/typeInputs.enum';

type Props={
  handleUpdateSnackbar:Function,
  id:number,
  handleOpenSuccess:Function,
  handleOpenError:Function,
  treeview:boolean,
  checkHasValidate:boolean,
  courseId:number

}
export function InputTab({handleOpenError,handleOpenSuccess,handleUpdateSnackbar,id,treeview,checkHasValidate,courseId}:Props){
    const [isLoading,setIsLoading]=useState<boolean>(true)
    const [numberInputs,setNumberInputs]=useState<number>(1)
    const [typeInputToRead,setTypeInputToRead]=useState<string>('String')
    const [inputsTextValues,setInputsTextValues]=useState<string[]>([""])
    const [inputsNumberValues,setInputsNumberValues]=useState<any[]>([""])
    const [inputsArrayNumberValues,setInputsArrayNumberValues]=useState<string[]>([""])
    const [inputsArrayStringValues,setInputsArrayStringValues]=useState<string[]>([""])
    const [inputsCSVValues,setInputsCSVValues]=useState<string[]>([""])
    const [inputErrorNumber, setInputErrorNumber] = useState<string[]>(['']);
    const [inputErrorArrayNumber, setInputErrorArrayNumber] = useState<string[]>(['']);
    const [inputErrorArrayString,setInputErrorArrayString] = useState<string[]>(['']);
    const [inputNumberError, setInputNumberError] = useState<string>('')
    const validateNumberInputs = (newNumberInputs: string) => {
        let min = 1
        let max = 20
        if (validator.isNumeric(newNumberInputs)) {
            if (parseInt(newNumberInputs) < min) {
                newNumberInputs = min.toString()
            }
            if (parseInt(newNumberInputs) > max) {
                newNumberInputs = max.toString()
            }
            setNumberInputs(Number(newNumberInputs))

            setInputNumberError('')
        } else {
            setNumberInputs(Number(newNumberInputs))
            setInputNumberError('Ingresar solo acepta entre 1 y 20 inputs')
        }
    }
    const changeInputTextValue=(value:any,index:number)=>{
        
        let newArrayInputValues=[...inputsTextValues]
        newArrayInputValues[index]=value
        setInputsTextValues(newArrayInputValues)
    }
    const changeInputNumberValue=(value:any,index:number)=>{
        
        let newArrayInputValues=[...inputsNumberValues]
        newArrayInputValues[index]=value
        setInputsNumberValues(newArrayInputValues)
    }
    const changeInputArrayNumberValue=(value:any,index:number)=>{
        
        let newArrayInputValues=[...inputsArrayNumberValues]
        newArrayInputValues[index]=value
        setInputsArrayNumberValues(newArrayInputValues)
    }
    const changeInputArrayStringValue=(value:any,index:number)=>{
        
        let newArrayInputValues=[...inputsArrayStringValues]
        newArrayInputValues[index]=value
        setInputsArrayStringValues(newArrayInputValues)
    }
  const changeInputCSVValue=(value:any,index:number)=>{
      
      let newArrayInputValues=[...inputsCSVValues]
      newArrayInputValues[index]=value
      setInputsCSVValues(newArrayInputValues)
  }
  
  const validateInputs = (input:string,index:number) => {
    
    if(typeInputToRead===TypeInput.STRING){
        changeInputTextValue(input,index)
        
    }else if (typeInputToRead===TypeInput.NUMBER){

        changeInputNumberValue(input,index)
        const newInputErrorNumber=[...inputErrorNumber]
        if(/^\d+(\.\d+)?$/.test(input)){
            changeInputNumberValue(input,index)
            newInputErrorNumber[index]=''
        } else {
            newInputErrorNumber[index]='Ingrese un número como input'
            
        }
        setInputErrorNumber(newInputErrorNumber);
    }else if (typeInputToRead===TypeInput.CSV){
        changeInputCSVValue(input,index)
            
    }else if (typeInputToRead===TypeInput.ARRAY_NUMBER){
        const newInputErrorArrayNumber=[...inputErrorArrayNumber]
        if(/^\d+(\.\d+)?(,\d+(\.\d+)?)*$/.test(input)){
            newInputErrorArrayNumber[index]=''
        } else {
            newInputErrorArrayNumber[index]='Ingrese solamente números separados por una ",". Ejemplo: 123,456.234'
        }
        setInputErrorArrayNumber(newInputErrorArrayNumber);
        changeInputArrayNumberValue(input,index)
        
    }else if (typeInputToRead===TypeInput.ARRAY_STRING){
        const newInputErrorArrayString=[...inputErrorArrayString]
        if(/^("[^"]*"(,("[^"]*"))*)?$/.test(input)){
            newInputErrorArrayString[index]=''
        } else {
            newInputErrorArrayString[index]='Ingrese palabras entre comillas (" ") y separadas por comas (,). Ejemplo: "programa","curso" '
        }
        setInputErrorArrayString(newInputErrorArrayString);
        changeInputArrayStringValue(input,index)    
    }
      
  }
  const [selectedExcel, setSelectedExcel] = useState<string[]>([]);
  const [selectedCSVFile, setSelectedCSVFile] = useState<string[]>([]);
  const [selectedTXTFile, setSelectedTXTFile] = useState<string[]>([]);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>,index:number) => {

    if (event.target.files && event.target.files.length > 0) {
        const token = localStorage.getItem('token');
        let extension=""
        if(typeInputToRead===TypeInput.EXCEL){
           extension=".xlsx"
        }else if (typeInputToRead===TypeInput.CSV_File){
            extension=".csv"
        }else if (typeInputToRead===TypeInput.TXT){
            extension=".txt"
        }
        const formData = new FormData();
        formData.append(`myfile`, event.target.files[0]);
        formData.append('jupyterId', id.toString())
        formData.append('name', "input_"+(index+1)+extension); 
        formData.append('courseId',courseId.toString() ); 
        MetadataApi.post("codelesson/uploadjupyterinputsfile", 
        formData, {
            headers: ({
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            })
        }).then((res: AxiosResponse) => {
            const file = res.data;
            let newArrayInputValues:any=[]
            
            if(typeInputToRead===TypeInput.EXCEL){
                newArrayInputValues=[...selectedExcel]
                newArrayInputValues[index]=file
                setSelectedExcel(newArrayInputValues);
            }else if (typeInputToRead===TypeInput.CSV_File){
                newArrayInputValues=[...selectedCSVFile]
                newArrayInputValues[index]=file
                setSelectedCSVFile(newArrayInputValues);
            }else if (typeInputToRead===TypeInput.TXT){
                newArrayInputValues=[...selectedTXTFile]
                newArrayInputValues[index]=file
            
                setSelectedTXTFile(newArrayInputValues);
            }
            //setInputsCSVValues(newArrayInputValues)
        })
    }
  };
  const saveInputs = (e:any) => {
      e.preventDefault()
      
      const token = localStorage.getItem('token');
    
        let inputsToSave=[]
        let inputsToRead:any[]=[]
        if(typeInputToRead===TypeInput.STRING){
            inputsToRead=[...inputsTextValues]
        }else if(typeInputToRead===TypeInput.CSV){
            inputsToRead=[...inputsCSVValues]
        }else if (typeInputToRead===TypeInput.NUMBER){
            inputsToRead=[...inputsNumberValues]
        }else if (typeInputToRead===TypeInput.ARRAY_NUMBER){
            inputsToRead=[...inputsArrayNumberValues]
        }else if (typeInputToRead===TypeInput.ARRAY_STRING){
            inputsToRead=[...inputsArrayStringValues]
        }else if (typeInputToRead===TypeInput.EXCEL){
            inputsToRead=[...selectedExcel]
        }else if (typeInputToRead===TypeInput.CSV_File){
            inputsToRead=[...selectedCSVFile]
        }else if (typeInputToRead===TypeInput.TXT){
            inputsToRead=[...selectedTXTFile]
        }
        for(var index=0;index<numberInputs;index++){
            inputsToSave.push(inputsToRead[index])
        }
        MetadataApi.post("codelesson/savejupyterinputs", {
            jupyterId: id,
            values:inputsToSave,
            type:typeInputToRead
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
         
            handleUpdateSnackbar("Inputs creados")
            handleOpenSuccess()

        }).catch((reason: AxiosError) => {
            handleUpdateSnackbar("Crear Inputs")
            handleOpenError()
        })
  }
  const changeInputToRead=(e:any)=>{
    setTypeInputToRead(e.target.value)
    if(e.target.value===TypeInput.STRING){
        if(inputsTextValues.length===0){
            setInputsTextValues([" "])
            setNumberInputs(1)
        }else{
            setNumberInputs(inputsTextValues.length)
        }
        
    }else if(e.target.value===TypeInput.NUMBER){
        if(inputsNumberValues.length===0){
            setInputsNumberValues([" "])
            setNumberInputs(1)
        }else{
            setNumberInputs(inputsNumberValues.length)
        }
         
    }else if(e.target.value===TypeInput.CSV){
        if(inputsCSVValues.length===0){
            setInputsCSVValues([" "])
            setNumberInputs(1)
        }else{
            setNumberInputs(inputsCSVValues.length)
        }
    }else if(e.target.value===TypeInput.ARRAY_NUMBER){
        if(inputsArrayNumberValues.length===0){
            setInputsArrayNumberValues([" "])
            setNumberInputs(1)
        }else{
            setNumberInputs(inputsArrayNumberValues.length)
        }
    }else if(e.target.value===TypeInput.ARRAY_STRING){
        if(inputsArrayStringValues.length===0){
            setInputsArrayStringValues([" "])
            setNumberInputs(1)
        }else{
            setNumberInputs(inputsArrayStringValues.length)
        }
    }
      
  }
  useEffect(()=>{
    if(treeview){
        const token = localStorage.getItem('token');
        MetadataApi.post("codelesson/getjupyterinputs", {
            jupyterId: id,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            let value=res.data.values
            if(res.data.values.length===0){
                setNumberInputs(1) 
                value=" "
            }else{
                setNumberInputs(res.data.values.length)  
            }
            setInputsTextValues([""])
            setInputsCSVValues([""])
            setInputsNumberValues([""])
            setInputsArrayNumberValues([""])
            setInputsArrayStringValues([""])
            setInputErrorArrayNumber([""])
            setInputErrorNumber([""])
            setTypeInputToRead(res.data.input_type)
            if(res.data.input_type===TypeInput.STRING){
                setInputsTextValues(value)
            }else if(res.data.input_type===TypeInput.CSV){
                setInputsCSVValues(value)
            }else if (res.data.input_type===TypeInput.NUMBER){
                setInputsNumberValues(value)
            }else if (res.data.input_type===TypeInput.ARRAY_NUMBER){
                setInputsArrayNumberValues(value)
            }else if (res.data.input_type===TypeInput.ARRAY_STRING){
                setInputsArrayStringValues(value)
            }else if (res.data.input_type===TypeInput.EXCEL){
                setSelectedExcel(value)
            }else if (res.data.input_type===TypeInput.CSV_File){
                setSelectedCSVFile(value)
            }else if (res.data.input_type===TypeInput.TXT){
                setSelectedTXTFile(value)
            }
            setIsLoading(false)
        })
    } 
},[treeview,id])
const handleDownload = (e:any,index:number) => {
      e.preventDefault()
      let route=""
      if(typeInputToRead===TypeInput.EXCEL){
        route=selectedExcel[index]
      }else if (typeInputToRead===TypeInput.CSV_File){
        route=selectedCSVFile[index]
      }else if(typeInputToRead===TypeInput.TXT){
        route=selectedTXTFile[index]
      }
      const token = localStorage.getItem('token');
      MetadataApi.post("codelesson/downloadfile", {
        routeFile: route
      }, {
          headers: ({
              Authorization: 'Bearer ' + token,
             
          }),
          responseType: 'blob',
      }).then(async(response:AxiosResponse)=>{
        const filename = route.split('/').pop()||"input"
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
  };
  return (
    <>
    {!isLoading?
     <form onSubmit={saveInputs} >
    <Box sx={{height:465,border:"solid",borderWidth:0.5,justifyContent:"center",p:2}}  >
       
            <Grid container >
                <Grid container sx={{justifyContent:"center",alignItems:"center",display:"flex"}}>
                    <Grid item xs={7}>
                    <Typography variant='body1' alignItems='left' align='left' >Tipo de dato con el cual se probará los inputs:
                    </Typography>
                    </Grid>
                    <Grid item xs={5}>
                    <Select
                    size='small'
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    required
                    value={typeInputToRead}
                    onChange={(e:any)=>{changeInputToRead(e)}}
                    >
                        <MenuItem
                        key={"String"}
                        value={"String"}
                        >

                        {"String"}
                        </MenuItem>
                        <MenuItem
                        key={"Number"}
                        value={"Number"}
                        >
                        {"Number"}
                        </MenuItem>
                        <MenuItem
                        key={"CSV"}
                        value={"CSV"}
                        >
                        {"CSV (;)"}
                        </MenuItem>
                        <MenuItem
                        key={"ArrayString"}
                        value={"ArrayString"}
                        >
                        {"Array String (,)"}
                        </MenuItem>
                        <MenuItem
                        key={"ArrayNumber"}
                        value={"ArrayNumber"}
                        >
                        {"Array Number (,)"}
                        </MenuItem>
                        <MenuItem
                        key={"EXCEL"}
                        value={"Excel"}
                        >
                        {"Archivo Excel"}
                        </MenuItem>
                        <MenuItem
                        key={"CSV_file"}
                        value={"CSV_file"}
                        >
                        {"Archivo CSV"}
                        </MenuItem>
                        <MenuItem
                        key={"TXT_file"}
                        value={"TXT_file"}
                        >
                        {"Archivo TXT"}
                        </MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body1' alignItems='left' align='left' >Número de inputs:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                    required
                    id="numberInput"
                    name="numberInput"
                    onChange={e => { validateNumberInputs(e.target.value) }}
                    error={inputNumberError !== ''}
                    helperText={inputNumberError}
                    type="number"
                    InputProps={{ inputProps: { min: 1, max: 20 }, }}
                    value={numberInputs}
                    variant="standard"
                />
                </Grid>
                <Grid item xs={12} >
                    <Box height={370} justifyContent={"center"} sx={{ overflow:"auto",marginTop:1}}>
                        
                        {Array.from({length:numberInputs},(_,index)=>index).map((_,index)=>{
                        return(
                    
                        <Fragment key={index}>
                            <Grid  container sx={{marginTop:1}}>
                                <Grid item xs={12}>
                                    {typeInputToRead===TypeInput.EXCEL||typeInputToRead===TypeInput.CSV_File||typeInputToRead===TypeInput.TXT?
                                    <>
                                    {"Input "+(index+1)+": "}
                                    <input  
                                    type="file"
                                    accept={typeInputToRead===TypeInput.CSV_File?".csv":typeInputToRead===TypeInput.EXCEL?".xlsx":".txt"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleFileChange(e,index) }}
                                    id={"fileInput_"+typeInputToRead+"_"+index}
                                   />
                                   {(typeInputToRead===TypeInput.EXCEL&&selectedExcel[index])||(typeInputToRead===TypeInput.CSV_File&&selectedCSVFile[index])||(typeInputToRead===TypeInput.TXT&&selectedTXTFile[index])?  <button onClick={(e:any)=>{handleDownload(e,index)}}>Descargar</button>:null}
                                   </>
                                    :
                                    <TextField
                                    name='Input'
                                    label={"Input: "+(index+1)}
                                    multiline={typeInputToRead===TypeInput.STRING||typeInputToRead===TypeInput.CSV}
                                    InputLabelProps={{
                                        shrink: true,
                                        }}
                                    required
                                    fullWidth
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { validateInputs(e.target.value,index) }}
                                    error={typeInputToRead===TypeInput.NUMBER?!!inputErrorNumber[index]:
                                    typeInputToRead===TypeInput.ARRAY_NUMBER?!!inputErrorArrayNumber[index]: typeInputToRead===TypeInput.ARRAY_STRING?!!inputErrorArrayString[index]:false}
                                    helperText={typeInputToRead===TypeInput.NUMBER?inputErrorNumber[index]:typeInputToRead===TypeInput.ARRAY_NUMBER?inputErrorArrayNumber[index]
                                    :typeInputToRead===TypeInput.ARRAY_STRING?inputErrorArrayString[index]
                                    :null}
                                    type="text"
                                    value=
                                    {typeInputToRead===TypeInput.STRING? 
                                    inputsTextValues[index] : typeInputToRead===TypeInput.NUMBER?inputsNumberValues[index]: typeInputToRead===TypeInput.CSV?inputsCSVValues[index]:typeInputToRead===TypeInput.ARRAY_NUMBER? 
                                    inputsArrayNumberValues[index] :typeInputToRead===TypeInput.ARRAY_STRING? 
                                    inputsArrayStringValues[index] :null}
                                    variant="outlined"
                                    />
                                    }     
                                </Grid>
                            </Grid>
                        </Fragment>         
                        )
                        })}
                    </Box>
                </Grid>
            </Grid>
           
        
    </Box>
    <Grid item xs={12} sx={{alignItems:'right',display:"flex",justifyContent:"right"}}>
                <Button sx={{marginTop:2}} type='submit' variant='contained' 
                disabled={
                    ((typeInputToRead === TypeInput.NUMBER && inputErrorNumber.some((str: any) => str.trim() !== "" ))) ||
                    (typeInputToRead === TypeInput.ARRAY_NUMBER && inputErrorArrayNumber.some((str: any) => str.trim() !== "")) || 
                    (selectedExcel[0]===undefined&&typeInputToRead===TypeInput.EXCEL)||
                    (selectedCSVFile[0]===undefined&&typeInputToRead===TypeInput.CSV_File)||
                    (selectedTXTFile[0]===undefined&&typeInputToRead===TypeInput.TXT)||
                    !checkHasValidate}>Guardar</Button>
            </Grid>  
    </form>   
    :
    <Box sx={{ height:550,border:"solid",borderWidth:0.5,justifyContent:"center"}}/>}
    
    </>
  )
} 