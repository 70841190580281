
import { useCallback, useEffect, useState } from 'react';
import { Grid, Button, CardActions, List, Card, CardHeader, ListItemText, ListItemButton, Box, Dialog, DialogContent, DialogContentText, DialogActions, Typography, Paper } from '@mui/material';
import { ChapterDialog } from '../components/ChapterDialog';
import Divider from '@mui/material/Divider';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import TableSkeleton from './TableSkeleton';
import ListSkeleton from './ListSkeleton';
import TableContent from './TableContent'
import { SnackBarSuccess } from './SnackBarSuccess';
import { SnackBarError } from './SnackBarError';
type Chapter = {
  courseId: number,
  chapterId: number,
  name: string;
  type: string;
  order: number,
}
export default function ChapterList(props: any) {
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")

  const handleUpdateSnackbar = (message: string) => {
    setSnackbarMessage(message)
  }
  const handleOpenSuccess = () => {
    setOpenSuccess(true)
  }
  const handleOpenError = () => {
    setOpenError(true)
  }
  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const handleCloseError = () => {
    setOpenError(false)
  }
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedObject, setSelectedObject] = useState<object>([])
  const [selectedId, setSelectedId] = useState<number>(0)
  const [contents, setContent] = useState<any[]>([]);
  const [chapters, setChapters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [type, setType] = useState<string>("")
  const [name, setName] = useState<string>("")
  const getContentByChapter = (chapterId: any) => {
    const token = localStorage.getItem('token');
    MetadataApi.get("contents/getcontentsbychapter/" + chapterId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setContent(res.data)
    })
  }

  const handdleUpdateContentOrder = (array: any) => {
    let contador = 1
    let orderUpdate: any[] = []
    for (const content of array) {
      orderUpdate.push({ contentId: content.contentId, order: contador })
      contador++
    }
    const token = localStorage.getItem('token');
    MetadataApi.put("contents/updatecontentsorder/", {
      orderUpdate
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
    })
  }

  const handdleUpdateChapterOrder = (array: any) => {
    let contador = 1
    let orderUpdate: any[] = []
    for (const chapter of array) {
      orderUpdate.push({ chapterId: chapter.chapterId, order: contador })
      contador++
    }
    const token = localStorage.getItem('token');
    MetadataApi.put("chapters/updatechapterorder/", {
      orderUpdate
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
    })
  }
  const handdleDelete = () => {
    const token = localStorage.getItem('token');
    MetadataApi.delete("chapters/deletechapter/" + selectedId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {

      handleCloseDelete()
      getChaptersByCourse(props.courseId)
    })
  }
  const getChaptersByCourse = useCallback((courseId:number) => {
    const token = localStorage.getItem('token');
    MetadataApi.get("courses/coursename/" + courseId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {

      if (res.data[1]) {
        setType("Proyecto")
      } else if (!res.data[1]) {
        setType("Curso")
      }
      setName(res.data[0])
      setLoading(false)
    })
    MetadataApi.get("chapters/getchaptersbycourse/" + props.courseId, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      if (res.data.length > 0) {
        setSelectedId(res.data[0].chapterId)
        setChapters(res.data)
        setSelectedValue(res.data[0].name)
        setSelectedObject(res.data[0])
        getContentByChapter(res.data[0].chapterId)
      }
      setLoading(false)
    })

  }, [props.courseId])
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: Chapter
  ) => {
    setSelectedId(item.chapterId)
    setSelectedObject(item)
    setSelectedValue(item.name);
    getContentByChapter(item.chapterId)
  };

  //let move the order of courses of the right list of courses
  const swapObjects = (up: boolean, auxArray: object[], index1: number, section: string) => {
    if (up) {
      if (index1 !== 0) {
        let index2 = index1 - 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
      }
    } else {
      if (index1 !== auxArray.length - 1) {
        let index2 = index1 + 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
      }
    }
    let newArray: any = []
    if (section === "chapters") {
      newArray = chapters.map((element, i) => {
        return element = auxArray[i]
      })
    }
    if (section === "content") {
      newArray = contents.map((element, i) => {
        return element = auxArray[i]
      })
    }
    return newArray
  }
  const handleSwapTable = (up: boolean, selectedId: number) => {
    let auxArray = [...contents]
    let section = "content"
    let index1 = 0
    for (let i = 0; i < contents.length; i++) {
      if (contents[i].contentId === selectedId) {
        index1 = i
        break
      }
    }
    const newContentArray = swapObjects(up, auxArray, index1, section)
    setContent(newContentArray);
    handdleUpdateContentOrder(newContentArray)
  }
  const handleSwapList = (up: boolean, selectedId: number, section: string) => {
    let auxArray = [...chapters]
    let index1 = 0
    for (let i = 0; i < chapters.length; i++) {
      if (chapters[i].chapterId === selectedId) {
        index1 = i
        break
      }
    }
    const newChapterArray = swapObjects(up, auxArray, index1, section)
    setChapters(newChapterArray);
    handdleUpdateChapterOrder(newChapterArray)

  }
  const handleOpenDelete = () => {
    setOpenDelete(true)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
  }
  useEffect(() => {

    getChaptersByCourse(props.courseId)
  }, [getChaptersByCourse,props.courseId])


  const customList = (title: React.ReactNode, items: readonly any[], side: boolean) => (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        action={<>
          <ChapterDialog getChaptersByCourse={getChaptersByCourse} add={true} courseId={props.courseId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
          <Button size='large' onClick={() => { handleSwapList(true, selectedId, "chapters") }} startIcon={<KeyboardArrowUpIcon />} /><Button size='large' onClick={() => { handleSwapList(false, selectedId, "chapters") }} startIcon={<KeyboardArrowDownIcon />} />
        </>}

        title={title}
      />
      <Divider />
      <Paper sx={{ width: '100%', height: 400, bgcolor: 'background.paper', overflow: "auto" }}>
        <List
          sx={{
            width: "100%",
            height: 400,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          role="list"
        >
          {items.map((item: Chapter, index: number) => {
            const labelId = `${item.name}`;
            return (
              <ListItemButton selected={selectedValue === item.name}
                onClick={(event) => handleListItemClick(event, item)} key={index}>
                <ListItemText id={labelId} primary={`Capitulo ${index + 1}: ${item.name}`} />
              </ListItemButton>
            );
          })}
        </List>
      </Paper>

      <CardActions disableSpacing >
        <ChapterDialog getChaptersByCourse={getChaptersByCourse} item={selectedObject} courseId={props.courseId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
        <Button sx={{ marginLeft: 1 }} size="small" color="primary" variant='contained'
          onClick={handleOpenDelete}>
          Eliminar
        </Button>
        {<Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Desea realmente desea eliminar este capítulo?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete}>Cancelar</Button>
            <Button onClick={handdleDelete} autoFocus >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>}
        {/* <Button sx={{marginLeft:1}} size="small" color="primary" variant='contained'
          onClick={handdleUpdateChapterOrder}>
          Guardar
        </Button> */}
      </CardActions>
    </Card>
  );
  return (
    <>{!loading ? <>
      <Grid container spacing={1} justifyContent="center" pb={2}>
        <Grid item>
          <Typography variant='h4' alignItems='center'>Gestión del {type}: {name}</Typography>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1 }} ><Grid>
        <Grid container spacing={2} >
          <Grid item xs={3.5} >
            {customList('Capítulos', chapters, true)}
          </Grid>
          <Grid item xs={8.5} >
            <TableContent data={contents} getContentByChapter={getContentByChapter} handleSwapTable={handleSwapTable} courseId={props.courseId} chapterId={selectedId}></TableContent>
          </Grid>
        </Grid>
      </Grid>
      </Box></>
      :
      <Box>
        <Grid>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={3.5}><ListSkeleton /></Grid>
            <Grid item xs={8.5}><TableSkeleton /></Grid>
          </Grid>
        </Grid>
      </Box>
    }
      <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />

    </>
  );
}