import {Dialog, DialogContent, Typography,Button, DialogContentText, DialogActions, Grid } from '@mui/material';
import { ContentType, allTypeData } from '../enums/typeContent.enum';
import { Jupyterlesson } from './Jupyterlesson';
import { Content } from './Content';
import { Questionlesson } from './Questionlesson';
import { Readinglesson } from './Readinglesson';
import { useState } from 'react';
type Props={
  openCreator:boolean,
  setOpenCreator:Function,
  typeSelected:string,
  handleUpdateSnackbar:Function,
  handleOpenSuccess:Function,
  handleOpenError:Function,
  getChaptersByCourse:Function,
  selectedContentId:number,
  selectedChapterId:number,
  getAlldata:Function,
  setEditObject:Function,
  courseId:number,

}

export function TreeviewCreate({openCreator,setOpenCreator,typeSelected,handleUpdateSnackbar,handleOpenSuccess,handleOpenError,getChaptersByCourse,selectedContentId,selectedChapterId,getAlldata,setEditObject,courseId}:Props){
  const [openDiscart, setOpenDiscart] = useState<boolean>(false);
   const handleOpenDiscart =()=>{
        setOpenDiscart(true)
    }
    const handleCloseDiscart = () => {
      setOpenDiscart(false)
  }

  const handleAcceptDiscart=()=>{
    handleCloseDiscart()
    setOpenCreator(false)
   
}
    return(
        <>
         {<Dialog open={openCreator} fullWidth={typeSelected !== allTypeData.Content} maxWidth="xl"  >
        <Grid sx={{padding:1}}>
          {typeSelected === ContentType.JupyterLesson ?
            <Jupyterlesson handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenSuccess} handleUpdateSnackbar={handleUpdateSnackbar} handleOpenDiscart={handleOpenDiscart}  setCloseDialog={setOpenCreator} getData={getChaptersByCourse} add={true} contentId={selectedContentId} courseId={courseId} height={350} chapterId={selectedChapterId} repeated={true}></Jupyterlesson>
            :
            typeSelected === ContentType.Questions ? <Questionlesson getData={getChaptersByCourse} add={true} contentId={selectedContentId} handleOpenDiscart={handleOpenDiscart} setCloseDialog={setOpenCreator} courseId={courseId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} height={350} repeated={true}> </Questionlesson>
              :
              typeSelected === ContentType.Reading ? <Readinglesson handleOpenDiscart={handleOpenDiscart} setCloseDialog={setOpenCreator} getData={getChaptersByCourse} add={true} contentId={selectedContentId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} repeated={true} height={350} ></Readinglesson>
                : <>
                  <Typography>Complete el siguiente formulario para añadir un nuevo contenido al capítulo</Typography>
                  <Content add={true} chapterId={selectedChapterId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} getContentByChapter={getAlldata} treeview={true} handleClose={setOpenCreator} setEditObject={setEditObject} ></Content></>
          }
        </Grid>

      </Dialog>}  
       {<Dialog
                open={openDiscart}
                onClose={handleCloseDiscart}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea realmente descartar los cambios realizados?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDiscart} variant='outlined'>Cancelar</Button>
                    <Button onClick={handleAcceptDiscart} variant='contained'>
                        Descartar
                    </Button>
                </DialogActions>
            </Dialog>}

        </>
    )
}