import { useContext, useEffect, useState } from 'react';
import {  Avatar, Button, Dialog, DialogActions, DialogContent, Grid, Icon, IconButton, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import Card from '@mui/material/Card';
import axios, { AxiosError, AxiosResponse } from 'axios';
import MetadataApi from '../apis/metadata.api';
import validator from 'validator';
import EditIcon from '@mui/icons-material/Edit';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { myContext } from '../contexts/Context';
type Props = {
    userData: User,
    getUsers: Function,
    handleOpenSuccess: Function,
  handleOpenError: Function,
  handleUpdateSnackbar: Function
}

type User = {
    firstname: string,
    lastname: string,
    username: string,
    age: number,
    email: string,
    country: string,
    linkedin: string,
    degree: string,
}
export default function UserUpdateDialog({ userData, getUsers,handleOpenError,handleOpenSuccess,handleUpdateSnackbar }: Props) {
    const theme = useTheme();
    const ctx = useContext(myContext)
    const [countries, setCountries] = useState<any>([])
    const [user, setUser] = useState<any>({
        firstname: "",
        lastname: "",
        username: "",
        age: 0,
        email: "",
        country: "",
        linkedin: "",
        degree: "",
    })

    const [firstnameError, setFirstnameError] = useState('')
    const [lastnameError, setLastnameError] = useState('')
    const [linkedinError, setLinkedinError] = useState('')
    const [ageError, setAgeError] = useState('')
    const [degreeError, setDegreeError] = useState('')
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    useEffect(() => {

        let countriesNames: any = []
        axios.get("https://restcountries.com/v3.1/all").then((res: AxiosResponse) => {
            res.data.map((country: any) => {
                countriesNames.push(country.name.common)
                return true
            })
            setCountries(countriesNames.sort())
        })
        setUser(userData)

    }, [userData])

    const handleClose = () => {
        setOpenDialog(false)

    }

    const updateUser = (e: any) => {
        e.preventDefault()
        if (ageError !== '' || degreeError !== '' || linkedinError !== '' || firstnameError !== '' || lastnameError !== '')
            return;
        user["adminuser"]=ctx.username
        const token = localStorage.getItem('token');
        MetadataApi.put(`user/updateuserbyadmin`, {
            user
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {

            getUsers()
            handleUpdateSnackbar("Usuario Editado")
            handleOpenSuccess()

        }).catch((reason: AxiosError) => {


            handleUpdateSnackbar("Editar Usuario ")
      
            handleOpenError()
          })

    }

    const validateAge = (age: string) => {

        let min = 0
        let max = 200
        if (validator.isNumeric(age)) {
            if (parseInt(age) < min) {
                age = min.toString()
            }
            if (parseInt(age) > max) {
                age = max.toString()
            }
            setUser({ ...user, age: age })
            setAgeError('')
        } else {
            setUser({ ...user, age: "" })
            setAgeError('Ingresar una edad entre 1 y 200!')
        }
    }

    const validateLinkedin = (linkedin: string) => {
  
        setUser({ ...user, linkedin: linkedin })
        if (validator.isURL(linkedin)) {

            setLinkedinError('')
        } else {

            setLinkedinError('Ingresar una url válida!')
        }
    }

    const validateDegree = (degree: string) => {

        let newDegree = degree.split(" ").join("")
        setUser({ ...user, degree: degree })
        if (validator.isAlpha(newDegree, 'es-ES')) {

            setDegreeError('')
        } else {

            setDegreeError('Ingresar un nombre válido!')
        }
    }
    const validateFirstname = (firstname: string) => {
  
        setUser({ ...user, firstname: firstname })
        if (validator.isAlpha(firstname, 'es-ES')) {
            setFirstnameError('')
        } else {
            setFirstnameError('Ingresar un nombre válido!')
        }
    }
    const validateLastname = (lastname: string) => {
   
        setUser({ ...user, lastname: lastname })
        if (validator.isAlpha(lastname, 'es-ES')) {
            setLastnameError('')
        } else {
            setLastnameError('Ingresar un apellido válido!')
        }
    }

    const handleInputChange = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }
    return (

        <>
            <IconButton onClick={() => setOpenDialog(true)}><EditIcon></EditIcon></IconButton>
            {<Dialog open={openDialog} onClose={handleClose} maxWidth="xl" fullWidth>
                <DialogContent>

                    <div className="centered">
                        <>

                            <Grid container justifyContent="center" pb={5}>
                                <Grid item xs={10} justifyContent="center">
                                    <Box display="flex" alignItems={"center"} justifyContent="center">
                                        <Icon sx={{ pb: 1, width: 50, height: 50 }}>
                                            <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 50, height: 50 }}>{user.username !== undefined ? user.firstname.charAt(0).toUpperCase() : "U"}</Avatar>
                                        </Icon>
                                    </Box>
                                    <Typography sx={{ marginBottom: 2 }} component="h1" variant="h5" alignItems='center' align='center'>{user.username}</Typography>
                                    <Card>
                                        <form onSubmit={updateUser} id="form">
                                            <Box margin={2}>

                                                <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body1' alignItems='left' align='left' >Nombre:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            required
                                                            id="firstname"
                                                            name="firstname"
                                                            onChange={e => { validateFirstname(e.target.value) }}
                                                            error={firstnameError !== ''}
                                                            helperText={firstnameError}
                                                            type="text"
                                                            value={user.firstname}
                                                            variant="standard"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            required
                                                            id="lastname"
                                                            name="lastname"
                                                            onChange={e => { validateLastname(e.target.value) }}
                                                            error={lastnameError !== ''}
                                                            helperText={lastnameError}
                                                            type="text"
                                                            value={user.lastname}
                                                            variant="standard"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body1' alignItems='left' align='left' >Correo:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography variant='body1' alignItems='left' align='left' >{user.email}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body1' alignItems='left' align='left' >Contraseña:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <ChangePasswordDialog username={user.username} handleOpenSuccess={handleOpenSuccess} handleUpdateSnackbar={handleUpdateSnackbar} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body1' alignItems='left' align='left' >Edad:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            required
                                                            id="age"
                                                            name="age"
                                                            onChange={e => { validateAge(e.target.value) }}
                                                            error={ageError !== ''}
                                                            helperText={ageError}
                                                            type="number"
                                                            InputProps={{ inputProps: { min: 0, max: 250 }, }}
                                                            value={user.age}
                                                            variant="standard"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body1' alignItems='left' align='left' >Título:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            id="degree"
                                                            name="degree"
                                                            onChange={e => { validateDegree(e.target.value) }}
                                                            error={degreeError !== ''}
                                                            helperText={degreeError}
                                                            label=""
                                                            multiline
                                                            type="text"
                                                            value={user.degree}
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body1'>País:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Select id="country"
                                                            name="country"
                                                            onChange={handleInputChange}
                                                            value={user.country}
                                                            size='small'
                                                            sx={{ width: "100%" }}
                                                        >{countries.map((country: any) => (
                                                            <MenuItem key={country} value={country}>
                                                                <Typography variant='body1'>{country}</Typography>
                                                            </MenuItem>
                                                        ))}</Select>
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1" >Linkedin:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            id="linkedin"
                                                            name="linkedin"
                                                            onChange={e => { validateLinkedin(e.target.value) }}
                                                            error={linkedinError !== ''}
                                                            helperText={linkedinError}
                                                            label=""
                                                            multiline
                                                            type="text"
                                                            value={user.linkedin}
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Button variant='contained' type='submit' sx={{ rowGap: 2 }}>Guardar</Button>
                                              
 


                                            </Box>
                                        </form>
                                    </Card>
                                </Grid>

                            </Grid>


                        </>

                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>

                </DialogActions>

            </Dialog>}

        </>


    )
}