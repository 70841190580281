import { useContext, useEffect, useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { Grid, Typography, Container, Tab, Tabs, Box } from '@mui/material';

import TableSkeleton from '../components/TableSkeleton';

import { TableChat } from '../components/reviews/TableChat';
import { WebSocketContext } from '../contexts/WebSocketContext';
import { myContext } from '../contexts/Context';
import { Role } from '../enums/roles.enums';
import { ChatStatus } from '../enums/chatStatus.enum';
import { SnackBarSuccess } from '../components/SnackBarSuccess';
import { SnackBarError } from '../components/SnackBarError';

export function Chat() {
    const socket = useContext(WebSocketContext)
    const [loading, setLoading] = useState<boolean>(true);
    const [tabValue, setSelect] = useState<ChatStatus>(ChatStatus.Started)
    const [page, setPage] = useState(0);
    const [allChats, setAllChats] = useState<any[]>([])
    const ctx = useContext(myContext)
    const [openSuccess, setOpenSuccess] = useState<boolean>(false)
    const [openError, setOpenError] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const handleUpdateSnackbar = (action: string) => {
      setSnackbarMessage(action)
    }
    const handleOpenSuccess = () => {
      setOpenSuccess(true)
    }
    const handleOpenError = () => {
      setOpenError(true)
    }
    const handleCloseSuccess = () => {
      setOpenSuccess(false)
    }
    const handleCloseError = () => {
      setOpenError(false)
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    useEffect(() => {
        if(socket){
            socket.emit("connected", { username: ctx?.username, role: Role.TeacherAssistant })
            return () => {
                socket.off('connect')
    
            }
        }
        
    }, [ctx.username,socket])
    const getAllChats = (chatStatus: ChatStatus) => {
       setLoading(true)
        const token = localStorage.getItem('token');
        MetadataApi.post("learncomments/getallchats", {
            chatStatus: chatStatus
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
           
            setAllChats(res.data)

            setLoading(false)
        })
    }

    const changeSelect = (event: React.SyntheticEvent, newValue: ChatStatus) => {
        setSelect(newValue)
    }
    useEffect(() => {
        getAllChats(ChatStatus.Started)
    }, [])

    useEffect(() => {
        setLoading(true)
        setPage(0)
        getAllChats(tabValue)
    }, [tabValue])

    return (

        <Container className='centered'>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item>
                    <Typography variant='h4' alignItems='center'>Listado de Chats</Typography>

                </Grid>
            </Grid>
            <Box sx={{ borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={changeSelect} aria-label="basic tabs example">
                    <Tab label="Nuevo" value={ChatStatus.Started} />
                    <Tab label="Revisando" value={ChatStatus.Reviewing} />
                    <Tab label="Completados" value={ChatStatus.Completed} />

                </Tabs>
            </Box>
            {!loading ?
                <Grid >
                    <TableChat page={page} tabValue={tabValue} setTabNumber={setSelect} completed={false} getAllChats={getAllChats} handleChangePage={handleChangePage} data={allChats}   handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar}></TableChat>
                </Grid>
                :
                <Grid >
                    <TableSkeleton ></TableSkeleton>
                </Grid>

            }
     <SnackBarSuccess message={snackbarMessage} handleCloseSuccess={handleCloseSuccess} open={openSuccess} />
      <SnackBarError message={snackbarMessage} handleCloseError={handleCloseError} open={openError} />

        </Container>

    )
}