import { Button, Grid, Card, Typography, TextField, Tabs, Tab, FormControlLabel, Checkbox, Fab, Dialog, DialogContent, DialogActions } from '@mui/material';
import { useState, useEffect, useContext, useCallback } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { Wysiwyg } from './Wysiwyg';
import { myContext } from '../contexts/Context';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import { TestWindow } from './TestWindow';
import ReplayIcon from '@mui/icons-material/Replay';
import { SaveButton } from './SaveButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputTab } from './InputTab';
export function Jupyterlesson(props: any) {
    const ctx = useContext(myContext)
    const [editorValue, setEditorValue] = useState('<p><br></p>');
    const [disabledEditor, setDisabledEditor] = useState<boolean>(true);
    const [isCreating,setIsCreating]=useState<boolean>(false)
    const [checkFeedback, setCheckFeedback] = useState<boolean>(false)
    const [editorFeedbackValue, setEditorFeedbackValue] = useState<string>("<p><br></p>")
    const [tabValue, setTabValue] = useState<number>(0)
    const [jupyterTabValue,setJupyterTabValue]=useState<string>("Jupyter")
    const [jupyterNotebook, setJupyterNotebook] = useState<boolean>(false);
    const [jupyterSource, setJupyterSource] = useState<string>("")
    const [jupyterLoading, setJupyterLoading] = useState<boolean>(true)
    const [initialEditor, setInitialEditor] = useState<string>("<p><br></p>")
    const [initialFeedbackEditor, setInitialFeedbackEditor] = useState<string>("<p><br></p>")
    const [initialValidateCheck,setInitialValidateCheck]=useState<boolean>(true)
    const [initialEditorCheck,setInitialEditorCheck]=useState<boolean>(false)
    const [initialTitle, setInitialTitle] = useState<string>("")
    const [ShowButtonResponses, setShowButtonResponse] = useState<boolean>(false)
    const [isWindowReponsesOpen, setIsWindowResponsesOpen] = useState(false);
    const [tests, setTests] = useState<any[]>([])
    const [isCreated, setIsCreated] = useState<boolean>(false)
    const [isJupyterLoaded, setIsJupyterLoaded] = useState<boolean>(false)
    const [checkHasValidate, setCheckHasValidate] = useState<boolean>(true)
    const [isValidating,setIsValidating]=useState<boolean>(false)
    const [codelesson, setCodelesson] = useState({
        contentId: 0,
        id: 0,
        detail: "",
        order: 0,
        title: "",
        feedback: "",
        hadFeedback: false,
        canValidate: false
    });
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {

        setTabValue(newValue);
    };

    const getJupyterNotebookUrl = useCallback((chapterId: number, courseId: number, contentId: number, id: number, username: any) => {
        setJupyterLoading(true)
        const token = localStorage.getItem('token');
        MetadataApi.post("codelesson/getjupyternotebookteacher", {
            chapterId: chapterId,
            courseId: courseId,
            contentId: contentId,
            teacherName: username,
            jupyterId: id,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setJupyterLoading(false)
            if (res.data === "code error") {
                setIsJupyterLoaded(false)

            } else {
                setIsJupyterLoaded(true)
                setJupyterSource(res.data)
            }
        },)

    }, [])


    useEffect(() => {
        if (!ctx || !ctx.username) return
        if (!props.add) {
            setJupyterNotebook(true)
            setJupyterLoading(true)
            const token = localStorage.getItem('token');
            MetadataApi.get("codelesson/getcodelesson/" + props.id, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
                if (res.data.hadFeedback) {
                    setDisabledEditor(false)
                    setCheckFeedback(true)
                }
                const newData: any = {
                    id: res.data.id,
                    detail: res.data.detail,
                    order: res.data.order,
                    title: res.data.title,
                    feedback: res.data.feedback,
                    hadFeedback: res.data.hadFeedback,
                    canValidate:res.data.canValidate
                }
                if (res.data.hadFeedback) {
                    setDisabledEditor(false)
                    setCheckFeedback(true)
                } else {
                    setDisabledEditor(true)
                    setCheckFeedback(false)
                }
                if(res.data.canValidate){
                    setCheckHasValidate(true)
                }else{
                    setCheckHasValidate(false)
                }
                setTabValue(0)
                setCodelesson(newData)
                setInitialValidateCheck(res.data.canValidate)
                setInitialEditorCheck(res.data.hadFeedback)
                setInitialTitle(res.data.title)
                setInitialEditor(res.data.detail)
                setInitialFeedbackEditor(res.data.feedback)
                setEditorValue(res.data.detail)
                setEditorFeedbackValue(res.data.feedback)
                getJupyterNotebookUrl(props.chapterId, props.courseId, props.contentId, props.id, ctx.username)
            })
        }
    }, [props.chapterId, props.courseId, props.contentId, props.id, props.add, ctx, getJupyterNotebookUrl])

    //close modal method
    const handleClose = () => {
        setCodelesson(
            {
                ...codelesson, contentId: props.contentId,
                id: 0,
                detail: "",
                order: 0,
                title: "",
                feedback: "<p><br></p>",
                hadFeedback: false,
                canValidate:false
            }
        )
        setInitialTitle("")
        setInitialEditor("<p><br></p>")
        setDisabledEditor(true)
        setEditorValue("")
        setEditorFeedbackValue("")
        setJupyterLoading(true)
        setJupyterSource("")

        props.setCloseDialog(false)
    };

    const saveJupyter = useCallback(() => {
        if (props.add && !isCreated) {
            
            setJupyterLoading(true)
        }
        setIsSaving(true)
        const token = localStorage.getItem('token');
        let feedbackValue = true
        let finalFeedback = editorFeedbackValue
        if (disabledEditor) {
            feedbackValue = false
            finalFeedback = ""
        }
        let newCodelesson = {
            contentId: props.contentId,
            id: codelesson.id,
            detail: editorValue,
            order: codelesson.order,
            title: codelesson.title,
            feedback: finalFeedback,
            hadFeedback: feedbackValue,
            courseId: props.courseId,
            chapterId: props.chapterId,
            teacherName: ctx.username,
            canValidate: checkHasValidate
        }

        MetadataApi.post("codelesson/addcodelesson",
            newCodelesson
            , {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
                setUnsavedChanges(false)
                setFirstSaveDone(true)
                if (props.add && !isCreated) {
                    props.setCloseDialog(false)
                    setJupyterLoading(false)
                    setJupyterSource(res.data.jupyterNotebook)
                }/* else{
                props.setCloseDialog()
            } */
                setTextChanged(false)
                setIsSaving(false)
                newCodelesson.id = res.data.codelessonId
                newCodelesson.order = res.data.codelessonOrder
                setCodelesson(newCodelesson)
                setInitialTitle(codelesson.title)
                setInitialEditor(editorValue)
                setInitialFeedbackEditor(editorFeedbackValue)
                

                if (props.add && !isCreated) {
                    props.handleUpdateSnackbar("Jupyterlesson creado")
                    setIsCreated(true)
                } else {
                    props.handleUpdateSnackbar("Jupyterlesson editado")
                }
                props.getData()
                props.handleOpenSuccess()
                setIsCreating(false)
            }).catch((reason: AxiosError) => {
                setIsSaving(false)
                if (props.add && !isCreated) {
                    props.handleUpdateSnackbar("Crear Jupyterlesson")
                    setIsCreated(true)
                } else {
                    props.handleUpdateSnackbar("Editar Jupyterlesson")
                }
                props.handleOpenError()
                
            })
    }, [codelesson, ctx.username, disabledEditor, editorFeedbackValue, editorValue, isCreated, props,checkHasValidate])

    const createJupyterLesson = (e: any) => {
        setIsCreating(true)
        e.preventDefault()
        saveJupyter()
    }
    const handleInputChange = (e: any) => {
        setCodelesson({
            ...codelesson,
            [e.target.name]: e.target.value
        })
    }
    const handleOpenDiscart = () => {
        if (initialEditor === editorValue && initialFeedbackEditor === editorFeedbackValue && initialTitle === codelesson.title && initialEditorCheck===checkFeedback && initialValidateCheck===checkHasValidate) {
            handleClose()
        } else {
            props.handleOpenDiscart()
        }

    }
    const handlefeedback = () => {
        if (disabledEditor) {
            setTabValue(1)
        }
        setCheckFeedback(!checkFeedback)
        setDisabledEditor(!disabledEditor)
        if (tabValue === 1) {
            setTabValue(0)
        }

    }

    const handleValidateCheck = () => {
        setCheckHasValidate(!checkHasValidate)
    }
    const customReactQuill = (jupyterlesson: boolean) => {
        let toolbarFeedback = "feedback"
        let toolbarJupyter = "jupyterlesson"
        if (props.repeated) {
            toolbarFeedback = "feedbackTreeview"
            toolbarJupyter = "jupyterlessonTreeview"
        }
        return (
            <Card>
                {!jupyterlesson ? <Wysiwyg toolbarName={toolbarFeedback} height={props.height} value={editorFeedbackValue} change={setEditorFeedbackValue} courseId={props.courseId} />
                    : null}
                {jupyterlesson ? < Wysiwyg toolbarName={toolbarJupyter} height={props.height} value={editorValue} change={setEditorValue} courseId={props.courseId} />
                    : null}
            </Card>
        );
    }

    const handleValidate = () => {
        setIsWindowResponsesOpen(false)
        setShowButtonResponse(false)
        setIsValidating(true)
        const token = localStorage.getItem('token');
        MetadataApi.get("codelesson/getcodelesson/" + props.id, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {

            const token = localStorage.getItem('token');

            MetadataApi.post("codelesson/validatejupyter", {
                contentId: props.contentId,
                username: ctx.username,
                courseId: props.courseId,
                chapterId: props.chapterId,
                jupyterId: props.id
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
                if (res.data.status==="Error") {
                    setTests([{"output":res.data.error,"status":res.data.status}])
                } else {
                    setTests(res.data.arrayTests.results)
 
                }
                setIsValidating(false)
                setShowButtonResponse(false)
                setIsWindowResponsesOpen(true)

            },)
        }).catch((error:AxiosError)=>{
            setIsValidating(false)
        })
    }

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => {
        const handleUnload = (event: any) => {
            if (unsavedChanges) {
                event.preventDefault();

            }
        };
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [unsavedChanges]);


    useEffect(() => {
        if (editorValue === initialEditor && editorFeedbackValue === initialFeedbackEditor && initialTitle === codelesson.title && initialEditorCheck===checkFeedback && initialValidateCheck===checkHasValidate) {

            setUnsavedChanges(false)
            setTextChanged(false)
        } else {
            setTextChanged(true)
            setUnsavedChanges(true)
        }

    }, [editorValue, initialEditor, initialTitle, codelesson.title, editorFeedbackValue, initialFeedbackEditor,checkFeedback,checkHasValidate,initialEditorCheck,initialValidateCheck])



    const handleOpenWindow = () => {
        setIsWindowResponsesOpen(!isWindowReponsesOpen);
        setShowButtonResponse(false)
    };
    const handleCloseWindow = () => {
        setIsWindowResponsesOpen(false);
        setShowButtonResponse(true)
    };
    useEffect(() => {
        if (unsavedChanges && !props.add) {
            const saveTimeout = setTimeout(() => {
                saveJupyter()
                setUnsavedChanges(false);
            }, Number(process.env.REACT_APP_AUTOSAVE_TIMER) || 60000);

            return () => clearTimeout(saveTimeout);
        }
    }, [unsavedChanges, saveJupyter, props.add]);

    const [firstSaveDone, setFirstSaveDone] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [textChanged, setTextChanged] = useState<boolean>(false)

    const [openKillNotebook, setOpenKillNotebook] = useState<boolean>(false)  
    const killJupyterNotebook = () => {

        const token = localStorage.getItem('token');

        MetadataApi.post("codelesson/killjupyternotebookteacher", {
            teacherName: ctx.username,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            setOpenKillNotebook(false)
            getJupyterNotebookUrl(props.chapterId, props.courseId, props.contentId, props.id, ctx.username)
        })
    }
    const handleChangeJupyterTabValue= (event: React.SyntheticEvent, newValue: string) => {

        setJupyterTabValue(newValue);
    };
    useEffect(()=>{
        setShowButtonResponse(false)
        setIsWindowResponsesOpen(false)
    },[props.id,jupyterTabValue])

    return (
        <Grid container spacing={4} columns={12} justifyContent="center" alignItems="center">
            <Grid item xs={(props.treeview?5:12)}>
                <form onSubmit={createJupyterLesson}>
                    <TextField
                        required
                        autoFocus
                        margin="dense"
                        id="title"
                        name="title"
                        label="Título"
                        type="text"
                        value={codelesson.title}
                        onChange={handleInputChange}
                        fullWidth
                        variant="standard"
                    />
                    <FormControlLabel
                        value="top"
                        control={<Checkbox checked={checkFeedback} onChange={handlefeedback} />}
                        label="¿Añadir Feedback?"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        value="top"
                        control={<Checkbox checked={checkHasValidate} onChange={handleValidateCheck} />}
                        label="¿Añadir Validación?"
                        labelPlacement="start"
                    />
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="JupyterLesson" value={0} />
                            <Tab label="Feedback" value={1} disabled={disabledEditor} />
                        </Tabs>
                    </Box>
                    {tabValue === 0 ?
                        <Card>
                            {customReactQuill(true)}
                        </Card>
                        : null}
                    {tabValue === 1 ?
                        <Card>
                            {customReactQuill(false)}
                        </Card>
                        : null}
                    {props.treeview ? <Grid sx={{ marginTop: 2, textAlign: "right" }}>
                        <SaveButton textChanged={textChanged} isSaving={isSaving} firstSaveDone={firstSaveDone} saveFunction={saveJupyter}></SaveButton>
                    </Grid > : <Box sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 5,
                    }}><Button variant='contained' type='submit' disabled={isCreating} >{jupyterNotebook ? "Guardar" : "Crear"}</Button>
                        <Button variant='outlined' onClick={handleOpenDiscart} sx={{ marginRight: 1 }}>Cerrar</Button></Box>}
                </form>
            </Grid>
          
            
            {props.treeview ?
              <Grid item xs={7}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={jupyterTabValue} onChange={handleChangeJupyterTabValue} aria-label="basic tabs example">
                <Tab label="Jupyter" value={"Jupyter"} />
                <Tab label="Solución" value={"Solution"}/>
                <Tab label="Validaciones" disabled={!checkHasValidate} value={"Inputs"} />
            </Tabs>
            </Box>
            {jupyterTabValue==="Inputs"?
                    <InputTab handleOpenError={props.handleOpenError} handleOpenSuccess={props.handleOpenSuccess} handleUpdateSnackbar={props.handleUpdateSnackbar} id={props.id} treeview={props.treeview} checkHasValidate={checkHasValidate} courseId={props.courseId}></InputTab>
                    :
                    jupyterNotebook ?
                        jupyterLoading ?
                            <Box alignItems={"center"} display={"flex"} height={550} justifyContent={"center"} border={"solid"} sx={{ borderWidth: "1px" }} >
                                <CircularProgress />
                            </Box>
                            :
                            isJupyterLoaded ?
                                <iframe src={jupyterSource} width="100%" title='notebook'
                                    height="550"></iframe>
                                :
                                <Box alignItems={"center"} display={"flex"} height={550} justifyContent={"center"} border={"solid"} sx={{ borderWidth: "1px" }} >
                                    <Grid container>
                                        <Grid item xs={12}><Typography sx={{ textAlign: "center" }}>Error al Cargar JupyterNotebook</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} ><Button variant='contained' onClick={() => { getJupyterNotebookUrl(props.chapterId, props.courseId, props.contentId, props.id, ctx.username) }} >Recargar <ReplayIcon></ReplayIcon> </Button></Grid>
                                    </Grid>
                                </Box>
                        :
                        <Typography variant='h6' align='center'>Despues de apretar en "Crear" se generara su Jupyter Notebook</Typography>
            }
            </Grid>     
            :null
            }
            {props.treeview&&jupyterTabValue!=="Inputs" ?
                <>
                    {checkHasValidate ?
                    <Fab onClick={handleValidate} disabled={isValidating} variant='extended' size="small" color="primary" aria-label="add"
                        sx={{ position: "absolute", bottom: 110, right: 30 }}>
                        <PlaylistPlayRoundedIcon fontSize='large' /> <Typography variant='body2' sx={{ mr: 1 }}>{isValidating?"Validando":"Validar"} {isValidating?<CircularProgress  size={15} color="inherit" />:null}</Typography>
                    </Fab> : null}
                    <Fab onClick={() => { getJupyterNotebookUrl(props.chapterId, props.courseId, props.contentId, props.id, ctx.username) }} /* onClick={handleOpen} */ aria-label="add" size="small" color="primary" sx={{ position: "absolute", right: 20, top: 135 }} >

                        <ReplayIcon />
                    </Fab>
                    <Fab onClick={() => { setOpenKillNotebook(true) }} /* onClick={handleOpen} */ aria-label="add" size="small" color="error" sx={{ position: "absolute", right: 65, top:  135  }} >

                        <CloseIcon />
                    </Fab>
                </> : null}
            <TestWindow openDrawer={props.openDrawer} ShowButtonResponses={ShowButtonResponses} handleOpenWindow={handleOpenWindow} isWindowReponsesOpen={isWindowReponsesOpen} handleCloseWindow={handleCloseWindow} tests={tests}></TestWindow>
            {<Dialog
                open={openKillNotebook}
                onClose={() => { setOpenKillNotebook(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>

                    <Typography variant='h5'> Desea matar los procesos del Jupyter Notebook?</Typography>
                    <br />
                    <Typography variant='body2' >*Este proceso reinicia el sistema del jupyter notebook, en el caso de que este no se muestre aun utilizando el botón recargar</Typography>
                    <Typography variant='body2'>**NO ELIMINA EL JUPYTER NOTEBOOK</Typography>


                    <DialogActions>
                        <Button onClick={() => { setOpenKillNotebook(false) }}>Cerrar</Button>
                        <Button onClick={killJupyterNotebook} variant='contained'>Matar</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>}
        </Grid>

    )
}