
import { useEffect, useState } from 'react';
import { Grid, Typography} from '@mui/material';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';

import TableUsers from '../components/TableUsers';
import TableSkeleton from '../components/TableSkeleton';

export function AdminPanel(){
    const [loading, setLoading] = useState<boolean>(true);
    const [users,setUsers] = useState<any[]>([])
    const getUsers=()=>{
        const token = localStorage.getItem('token');
        MetadataApi.get("user/getallusersbyadmin", {
            headers: ({
            Authorization: 'Bearer ' + token
            })
        }).then((res : AxiosResponse) => {
            setUsers(res.data)
            setLoading(false) 
        }, () => {
            window.location.href = "/"
        })
    }
    useEffect(() => {
        setLoading(true);
        getUsers();
            
    },[])
    return (
        <Grid sx={{marginLeft:2,marginRight:2,marginTop:1}}>
        <Grid container spacing={2} justifyContent="center" pb={5}>
            <Grid item>
                <Typography variant='h4' alignItems='center'>Panel de Administración de Usuarios</Typography>
                
            </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" pb={3}>
        </Grid>
        {!loading ?
            <Grid >
                <TableUsers users={users} getUsers={getUsers}  ></TableUsers>
          
            </Grid>
        : 
            <Grid >
                <TableSkeleton ></TableSkeleton>
            </Grid>
            
        }
        
    </Grid>
    )
}