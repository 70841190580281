

import { useState } from 'react';
import { Card, CardHeader, Box, Button, Typography } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ContentType, allTypeData } from '../enums/typeContent.enum';
import { ChapterDialog } from './ChapterDialog';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { TreeviewDelete } from './TreeviewDelete';
import { TreeviewEdit } from './TreeviewEdit';
import { TreeviewCreate } from './TreeviewCreate';

type Props = {
  openDrawer: boolean,
  openTreeview: boolean,
  getChaptersByCourse: Function,
  handleOpenSuccess: Function,
  handleOpenError: Function,
  handleUpdateSnackbar: Function,
  courseId: number,
  chapters: any,
  typeSelected: string,
  setTypeSelected: any,
  setLessonStatus: Function,
  setSpecificContentData: Function,
  setChapters: Function,
  setSelectedChapterId: Function,
  selectedChapterId: any
  setSelectedContentId: Function,
  selectedContentId: any,
  getAlldata:Function,
  openCreator:boolean,
  setOpenCreator:Function
  
}

export function TreeviewList({openDrawer,openTreeview,getChaptersByCourse,courseId,handleOpenSuccess,handleOpenError,handleUpdateSnackbar,chapters,typeSelected,setTypeSelected,setLessonStatus,setSpecificContentData,setChapters,setSelectedChapterId,selectedChapterId,setSelectedContentId,selectedContentId,getAlldata,openCreator,setOpenCreator}: Props) {
  const [editObject, setEditObject] = useState<any>()
  const [typeEditSelected, setTypeEditSelected] = useState<string>("")
  const [deleteId, setDeleteId] = useState<number>(0)
  const [deleteType, setDeleteType] = useState<string>("")
  const [lastNameSelected, setLastNameSelected] = useState<string>("")
  const [dataFather, setDataFather] = useState<any>([])
  const [lastPosition, setLastPosition] = useState<number>()
  const [initialDisabled, setInitialDisabled] = useState<boolean>(true)
  const title = "Capitulos"
  const [editDisabled, setEditDisabled] = useState<boolean>(true)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
/*   const [openCreator, setOpenCreator] = useState<boolean>(false); */
  const handleSwapList = (up: boolean) => {
    let index1 = 0
    let auxArray = [...dataFather]
    let data: any = {}
    if (deleteType === allTypeData.Chapter) {
      for (let i = 0; i < dataFather.length; i++) {
        if (dataFather[i].chapterId === deleteId) {
          data = dataFather[i]
          index1 = i
          break
        }
      }
    } else if (deleteType === allTypeData.Content) {
      for (let i = 0; i < dataFather.length; i++) {
        if (dataFather[i].contentId === deleteId) {
          data = dataFather[i]
          index1 = i
          break
        }
      }
    } else {
      for (let i = 0; i < dataFather.length; i++) {
        if (dataFather[i].id === deleteId) {
          data = dataFather[i]
          index1 = i
          break
        }
      }
    }
    const newDataArray = swapObjects(up, auxArray, index1, data)
    updateOrder(newDataArray)

  }
  const updateNameLastSelected = (data: any, type: string, order: number) => {
    let name: string = ""
    switch (type) {
      case allTypeData.Chapter:
        name = "Capítulo " + order + ": " + data.name
        break
      case allTypeData.Content:
        name = "Contenido " + order + ": " + data.name
        break
      case allTypeData.JupyterLesson:
        name = "Jupyter lesson " + order + ": " + data.title
        break
      case allTypeData.Questions:
        name = "Question lesson " + order + ": " + data.question
        break
      case allTypeData.Reading:
        name = "Reading lesson " + order + ": " + data.title
        break
    }
    setLastNameSelected(name)
  }
  const swapObjects = (up: boolean, auxArray: object[], index1: number, data: any) => {
    let newPosition = lastPosition
    if (up) {
      if (index1 !== 0) {
        let index2 = index1 - 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
        newPosition = index1
        updateNameLastSelected(data, deleteType, newPosition)
      }
    } else {
      if (index1 !== auxArray.length - 1) {
        let index2 = index1 + 1
        let val1 = auxArray[index2]
        let val2 = auxArray[index1]
        auxArray[index1] = Object.assign({}, val1)
        auxArray[index2] = Object.assign({}, val2)
        newPosition = index1 + 2
        updateNameLastSelected(data, deleteType, newPosition)
      }
    }

    let newArray: any = []
    newArray = dataFather.map((element: any, i: number) => {
      return element = auxArray[i]
    })
    return newArray
  }

  const updateOrder = (newDataArray: any[]) => {
    let chaptersDuplicate = [...chapters]
    let orderUpdate: any[] = []
    let route = ""
    switch (deleteType) {
      case allTypeData.Chapter:
        setChapters(newDataArray)
        setDataFather(newDataArray)
        orderUpdate = getOrderArray(newDataArray)
        route = "chapters/updatechapterorder/"
        break
      case allTypeData.Content:
        for (let i = 0; i < chaptersDuplicate.length; i++) {
          if (chaptersDuplicate[i].chapterId === selectedChapterId) {
            chaptersDuplicate[i].contents = newDataArray
          }
        }
        orderUpdate = getOrderArray(newDataArray)
        route = "contents/updatecontentsorder/"
        setDataFather(newDataArray)
        setChapters(chaptersDuplicate)
        break
      case allTypeData.JupyterLesson:
        for (let i = 0; i < chaptersDuplicate.length; i++) {
          if (chaptersDuplicate[i].chapterId === selectedChapterId) {
            for (let j = 0; j < chaptersDuplicate[i].contents.length; j++) {
              if (chaptersDuplicate[i].contents[j].contentId === selectedContentId) {
                chaptersDuplicate[i].contents[j].code_lesson = newDataArray
              }
            }
          }
        }
        orderUpdate = getOrderArray(newDataArray)
        route = "codelesson/updatecodelessonorder/"
        setDataFather(newDataArray)
        setChapters(chaptersDuplicate)
        break
      case allTypeData.Reading:
        for (let i = 0; i < chaptersDuplicate.length; i++) {
          if (chaptersDuplicate[i].chapterId === selectedChapterId) {
            for (let j = 0; j < chaptersDuplicate[i].contents.length; j++) {
              if (chaptersDuplicate[i].contents[j].contentId === selectedContentId) {
                chaptersDuplicate[i].contents[j].reading = newDataArray
              }
            }
          }
        }
        orderUpdate = getOrderArray(newDataArray)
        route = "readinglesson/updatereadinlessongorder/"
        setDataFather(newDataArray)
        setChapters(chaptersDuplicate)
        break
      case allTypeData.Questions:
        for (let i = 0; i < chaptersDuplicate.length; i++) {
          if (chaptersDuplicate[i].chapterId === selectedChapterId) {
            for (let j = 0; j < chaptersDuplicate[i].contents.length; j++) {
              if (chaptersDuplicate[i].contents[j].contentId === selectedContentId) {
                chaptersDuplicate[i].contents[j].question = newDataArray
              }
            }
          }
        }
        orderUpdate = getOrderArray(newDataArray)
        route = "questions/updatequestionorder/"
        setDataFather(newDataArray)
        setChapters(chaptersDuplicate)
        break
    }
    const token = localStorage.getItem('token');
    MetadataApi.put(route, {
      orderUpdate
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {

    })
  }
  const getOrderArray = (dataArray: any[]) => {
    let orderUpdate: any = []
    let contador = 1
    for (const data of dataArray) {
      if (deleteType === allTypeData.Chapter) {
        orderUpdate.push({ chapterId: data.chapterId, order: contador })
      } else if (deleteType === allTypeData.Content) {
        orderUpdate.push({ contentId: data.contentId, order: contador })
      } else {
        orderUpdate.push({ id: data.id, order: contador })
      }
      contador++
    }
    return orderUpdate
  }

  const lessonHandler = (lessonData: any, selectedType: ContentType, contentId: number, chapterId: number, arrayData: any[]) => {
    setInitialDisabled(false)
    setLastPosition(lessonData.order)
    setEditDisabled(true)
    setDataFather([...arrayData])
    updateNameLastSelected(lessonData, selectedType, lessonData.order)
    setLessonStatus(true)
    setDeleteId(lessonData.id)
    setDeleteType(selectedType)
    setSelectedContentId(contentId)
    setTypeSelected(selectedType)
    setSpecificContentData(lessonData)
    setSelectedChapterId(chapterId)
  }

  const chapterContentHandler = (type: string, id: number, data: any, arraydata: any[], chapterId: number) => {
    setInitialDisabled(false)
    setLastPosition(data.order)
    updateNameLastSelected(data, type, data.order)
    setEditDisabled(false)
    setSelectedChapterId(chapterId)
    setDeleteId(id)
    setDeleteType(type)
    setLessonStatus(false)
    setEditObject(data)
    if (type === allTypeData.Chapter) {
      setSelectedChapterId(id)
      setTypeSelected(allTypeData.Content)
      setDataFather([...arraydata])
      setTypeEditSelected(allTypeData.Chapter)
      
    } else {
    
      if (type === allTypeData.Content) {
        setTypeSelected(data.type)
        setDataFather([...arraydata])
      } else {
        setTypeSelected(type)
      }
      setTypeEditSelected(allTypeData.Content)
      setSelectedContentId(id)
    }
  }
  
  return (
    <Box sx={{ display: "flex", overflowY: "hidden",height:"100%" }} >
      {openDrawer ? null : <><Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 550, width: 50, overflowY: "hidden" }}>
        <Typography sx={{ transform: "rotate(90deg)", textAlign: "center", whiteSpace: "nowrap" }}>{lastNameSelected}</Typography>
      </Box></>}

      <Card sx={{ width: "100%", height: "100%", border: "none", boxShadow: "none", visibility: (openTreeview ? "visible" : "hidden") }}>
        <CardHeader
          sx={{ px: 2, py: 1, border: "none" }}
          titleTypographyProps={{ variant: 'h6' }}
          title={title}
          action={<>
            <ChapterDialog getChaptersByCourse={getChaptersByCourse} add={true} courseId={courseId} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} handleUpdateSnackbar={handleUpdateSnackbar} />
            <Button size='large' onClick={() => { handleSwapList(true) }} startIcon={<KeyboardArrowUpIcon />} /><Button size='large' onClick={() => { handleSwapList(false) }} startIcon={<KeyboardArrowDownIcon />} />
          </>}
        />

        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            height: "85%", maxWidth: "100%", overflow: "auto", '&::-webkit-scrollbar': {
              width: 0
            }
          }}
        >
          {
            chapters.map((chapter: any, indexChapter: number) => {
              return (
                <TreeItem sx={{ backgroundColor: "#bdbdbd" }} key={"chapter" + String(chapter.chapterId)} nodeId={"chapter" + String(chapter.chapterId)} label={"Capitulo " + (indexChapter + 1) + ": " + chapter.name} onClick={() => { chapterContentHandler(allTypeData.Chapter, chapter.chapterId, chapter, chapters, chapter.chapterId) }} >
                  {chapter.contents.map((content: any, indexContent: number) => {
                    return (
                      <TreeItem sx={{ backgroundColor:"#e0e0e0" }} key={"content" + String(content.contentId)} nodeId={"content" + String(content.contentId)} label={"Contenido " + (indexContent + 1) + ": " + content.name + " (" + content.type + ")"} onClick={() => { chapterContentHandler(allTypeData.Content, content.contentId, content, chapter.contents, chapter.chapterId) }}>
                        {content.code_lesson.length > 0 ?
                          content.code_lesson.map((codelesson: any, indexJupyter: number) => {
                            return (
                              <TreeItem sx={{ fontStyle: "italic",backgroundColor: "white" }} key={"codelesson" + String(codelesson.id)} nodeId={"codelesson" + String(codelesson.id)} label={"Jupyter lesson " + (indexJupyter + 1) + ": " + codelesson.title} onClick={() => lessonHandler(codelesson, ContentType.JupyterLesson, content.contentId, chapter.chapterId, content.code_lesson)} ></TreeItem>
                            )
                          }) : content.question.length > 0 ?
                            content.question.map((question: any, indexQuestion: number) => {
                              return (
                                <TreeItem sx={{ fontStyle: "italic" ,backgroundColor: "white" }} key={"question" + String(question.id)} nodeId={"question" + String(question.id)} label={"Question lesson " + (indexQuestion + 1) + ": " + question.question} onClick={() => lessonHandler(question, ContentType.Questions, content.contentId, chapter.chapterId, content.question)} ></TreeItem>
                              )
                            }) : content.reading.length > 0 ?
                              content.reading.map((reading: any, indexReading: number) => {
                                return (
                                  <TreeItem sx={{ fontStyle: "italic",backgroundColor: "white"}} key={"reading" + String(reading.id)} nodeId={"reading" + String(reading.id)} label={"Reading lesson " + (indexReading + 1) + ": " + reading.title} onClick={() => lessonHandler(reading, ContentType.Reading, content.contentId, chapter.chapterId, content.reading)} ></TreeItem>
                                )
                              }) : null}
                        {
                        }
                      </TreeItem>
                    )
                  })
                  }
                </TreeItem>
              )
            })
          }
        </TreeView>
        <Button variant='contained' disabled={initialDisabled} size='small' sx={{ marginRight: 1, marginLeft: 1 }} onClick={()=>{setOpenCreator(true)}}  >Crear
          {typeSelected === allTypeData.Content ? " Contenido" :
            typeSelected === allTypeData.JupyterLesson ? " Jupyter" :
              typeSelected === allTypeData.Reading ? " Reading" :
                typeSelected === allTypeData.Questions ? " Question" : null
          }
        </Button>
        <Button size="small" variant='outlined' sx={{ marginRight: 1 }} disabled={initialDisabled} onClick={() => { setOpenDelete(true) }}>Eliminar</Button>
        <Button size='small' variant='outlined' disabled={editDisabled} onClick={() => { setOpenEdit(true) }}>Editar</Button>
      </Card>
      {openDelete?<TreeviewDelete
        openDelete={openDelete} setOpenDelete={setOpenDelete} deleteType={deleteType} deleteId={deleteId} getAlldata={getAlldata} setDeleteId={setDeleteId} setLessonStatus={setLessonStatus} handleUpdateSnackbar={handleUpdateSnackbar} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError}
        />:null}
        {openEdit?<TreeviewEdit openEdit={openEdit} setOpenEdit={setOpenEdit} typeEditSelected={typeEditSelected} getAlldata={getAlldata} editObject={editObject} setEditObject={setEditObject} courseId={courseId} handleUpdateSnackbar={handleUpdateSnackbar} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} selectedChapterId={selectedChapterId} />
        :null}
        {openCreator?<TreeviewCreate openCreator={openCreator} setOpenCreator={setOpenCreator} typeSelected={typeSelected} handleUpdateSnackbar={handleUpdateSnackbar} handleOpenSuccess={handleOpenSuccess} handleOpenError={handleOpenError} getChaptersByCourse={getChaptersByCourse} selectedContentId={selectedContentId}  selectedChapterId={selectedChapterId} getAlldata={getAlldata} setEditObject={setEditObject} courseId={courseId} />:null}
    </Box>
  )
}