import React, { useState, useContext, Fragment } from 'react'
import { AxiosError, AxiosResponse } from 'axios';
import MetadataApi from '../apis/metadata.api';
import validator from 'validator';
import { myContext } from '../contexts/Context';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';

type Props = {
  getUsers: Function,
  handleOpenSuccess: Function,
  handleOpenError: Function,
  handleUpdateSnackbar: Function
}

export default function UserRegistration({ getUsers, handleOpenError, handleOpenSuccess, handleUpdateSnackbar }: Props) {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [firstname, setFirstname] = useState<string>("")
  const [lastname, setLastname] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState<string[]>([])
  const [passwordRepeatError, setPasswordRepeatError] = useState('')
  const [firstnameError, setFirstnameError] = useState('')
  const [lastnameError, setLastnameError] = useState('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const ctx = useContext(myContext)
  const register = (event: any) => {
    event.preventDefault();
    setError('')
    if (emailError !== '' || passwordError.length > 0 || passwordRepeatError !== '' || firstnameError !== '' || lastnameError !== '')
      return;
    const token = localStorage.getItem('token');
    MetadataApi.post("/user/userregisterbyadmin", {
      email,
      password,
      firstname,
      lastname,
      adminuser: ctx.username,
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      if (res.data === "success") {
        getUsers()

        handleUpdateSnackbar("Usuario Creado")
        handleOpenSuccess()
        setOpenDialog(false)
      }
      else
        setError('error')
    }).catch((reason: AxiosError) => {


      handleUpdateSnackbar("Crear Usuario ")

      handleOpenError()
    })
  }

  const validateEmail = (email: string) => {
    if (validator.isEmail(email)) {
      setEmail(email)
      setEmailError('')
    } else {
      setEmailError('Ingresar un correo válido!')
    }
  }
  const validatePassword = (password: string) => {
    const errorMessages: string[] = [];
    setPassword(password)
    if (password.length < 8) {
      errorMessages.push('La constraseña debe poseer al menos 8 caracteres');
    }
    // Other conditions can be checked separately
    if (!/[a-z]/.test(password)) {
      errorMessages.push('La contraseña debe poseer al menos una letra minúscula.');
    }

    if (!/[A-Z]/.test(password)) {
      errorMessages.push('La contraseña debe poseer al menos una letra mayúscula.');
    }

    if (!/\d/.test(password)) {
      errorMessages.push('La contraseña debe poseer al menos un número.');
    }

    if (!/[^a-zA-Z\d]/.test(password)) {
      errorMessages.push('La contraseña debe poseer al menos un caracter especial.');
    }
    setPasswordError(errorMessages)

  }
  const validatePasswordRepeat = (passwordRepeat: string) => {
    if (passwordRepeat === password) {
      setPasswordRepeatError('')
    } else {
      setPasswordRepeatError('Las contraseñas no son iguales!')
    }
  }
  const validateFirstname = (firstname: string) => {
    if (validator.isAlpha(firstname, 'es-ES')) {
      setFirstname(firstname)
      setFirstnameError('')
    } else {
      setFirstnameError('Ingresar un nombre válido!')
    }
  }
  const validateLastname = (lastname: string) => {
    if (validator.isAlpha(lastname, 'es-ES')) {
      setLastname(lastname)
      setLastnameError('')
    } else {
      setLastnameError('Ingresar un apellido válido!')
    }
  }
  const handleClose = () => {
    setOpenDialog(false)

  }

  return (
    <>
      <Button variant='contained' sx={{ height: "35px" }} onClick={() => setOpenDialog(true)}>Nuevo Usuario</Button>
      {<Dialog open={openDialog} onClose={handleClose} maxWidth="xl" >
        <DialogContent>


        </DialogContent>
        <Container className='centered' maxWidth="xs">
          <Typography component="h1" variant="h5" align={'center'}>
            Registro
          </Typography>
          <Box
            component="form"
            onSubmit={register}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoFocus
              onChange={e => validateEmail(e.target.value)}
              error={emailError !== ''}
              helperText={emailError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              onChange={e => validatePassword(e.target.value)}
              helperText={
                passwordError.length > 0
                  ? passwordError.map((message, index) => <div key={index}>{message}</div>)
                  : ''
              }
              error={passwordError.length > 0}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordrepeat"
              label="Reingresar Contraseña"
              type="password"
              id="passwordrepeatmui textfield valida"
              onChange={e => validatePasswordRepeat(e.target.value)}
              error={passwordRepeatError !== ''}
              helperText={passwordRepeatError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstname"
              label="Nombre"
              name="firstname"
              autoFocus
              onChange={e => validateFirstname(e.target.value)}
              error={firstnameError !== ''}
              helperText={firstnameError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastname"
              label="Apellido"
              name="lastname"
              autoFocus
              onChange={e => validateLastname(e.target.value)}
              error={lastnameError !== ''}
              helperText={lastnameError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={email.length === 0 || password.length === 0 || firstname.length === 0 || lastname.length === 0}
            >
              Registrarse
            </Button>
            {error !== "" ? (
              <Alert variant="filled" severity="error">
                Error: Usuario ya existe
              </Alert>
            ) : (<></>)}
          </Box>
        </Container>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>

        </DialogActions>

      </Dialog>}
    </>

  )
}