import { DialogContent, Dialog, Button, DialogContentText, DialogActions} from '@mui/material';
import { useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';

import { Jupyterlesson } from './Jupyterlesson';
export function CodelessonDialog(props: any) {

    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [openDiscart, setOpenDiscart] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleOpenDiscart =()=>{
        setOpenDiscart(true)
    }
    const handleOpenDelete = () => {
        setOpenDelete(true)
    }
    const handleCloseDelete = () => {
        setOpenDelete(false)
    }
    const handleAcceptDiscart=()=>{
        handleCloseDiscart()
        setOpen(false)
    }
    const handdleDelete = () => {
        const token = localStorage.getItem('token');
        MetadataApi.delete("codelesson/deletejupyterlesson/" + props.id, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            props.getContentCodelessonByContent()
            handleCloseDelete()

        })
    }
    const handleCloseDiscart = () => {
        setOpenDiscart(false)
    }
    return (
        <>
            <Button onClick={handleClickOpen} sx={{ marginRight: 1 }} size="small" variant='contained' color='primary'>
                {props.add ? "Crear Nuevo" : "Editar"}
            </Button>
            {props.add ? null : <Button sx={{ marginRight: 1 }} onClick={handleOpenDelete} size="small" variant='contained' color='primary' >
                Eliminar
            </Button>}
            {<Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea realmente desea eliminar este JupyterLesson?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancelar</Button>
                    <Button onClick={handdleDelete} autoFocus >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>}
            {<Dialog open={open}  fullWidth maxWidth="xl">
                <DialogContent >
                    <Jupyterlesson treeview={false} handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenSuccess} handleUpdateSnackbar={props.handleUpdateSnackbar} handleOpenDiscart={handleOpenDiscart} setCloseDialog={setOpen} getData={props.getContentCodelessonByContent} add={props.add} id={props.id} contentId={props.contentId} courseId={props.courseId} height={350} chapterId={props.chapterId}></Jupyterlesson>
                </DialogContent>

            </Dialog>}
            {<Dialog
                open={openDiscart}
                onClose={handleCloseDiscart}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea realmente descartar los cambios realizados?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDiscart} variant='outlined'>Cancelar</Button>
                    <Button onClick={handleAcceptDiscart} variant='contained'>
                        Descartar
                    </Button>
                </DialogActions>
            </Dialog>}
        </>
    )
}