import { TextField, DialogContentText, DialogContent, DialogTitle, Dialog, DialogActions, Button, MenuItem, SelectChangeEvent, Select, OutlinedInput, Box, Chip, InputLabel, Grid, FormLabel, Typography, Switch,} from '@mui/material';
import { useState, useContext } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from "react-router-dom";
import validator from 'validator';
import { TestDialog } from './TestDialog';
import { myContext } from '../contexts/Context';

export function CourseDialog(props: any) {
  const navigate = useNavigate()
  const ctx = useContext(myContext)
  const [urlError, setUrlError] = useState('')
  const [hourError, setHourError] = useState('')
  const [open, setOpen] = useState<boolean>(false);
  const [allCoursesType, setAllCoursesType] = useState<readonly string[]>([])
  const [courseTypes, setCourseTypes] = useState<string[]>([]);
  const [course, setCourse] = useState({
    courseId: 0,
    name: "",
    description: "",
    isActive: false,
    version: 0,
    id_group: 0,
    draft: true,
    order: 0,
    professor_name: "",
    professor_link: "",
    isProject: 0,
    hours: 0,
    code: "",
    hours_limit: false,
  });
  const validateUrl = (url: string) => {
    setCourse({ ...course, professor_link: url })
    if (validator.isURL(url, { protocols: ['http', 'https'], require_protocol: true })) {
      setUrlError('')
    } else {
      setUrlError('Ingresar una url válida! (https o http)  ')
    }
  }
  const validateHour = (hours: string) => {
    let min = 0
    let max = 10000
    if (validator.isNumeric(hours)) {
      if (parseInt(hours) < min) {
        hours = min.toString()
      }
      if (parseInt(hours) > max) {
        hours = max.toString()
      }
      setCourse({ ...course, hours: parseInt(hours) })
      setHourError('')
    } else {
      setCourse({ ...course, hours: 0 })
      setHourError('Ingresar una edad entre 0 y 10000!')
    }
  }

  const handleChangeType = (event: SelectChangeEvent<typeof courseTypes>) => {
    const {
      target: { value },
    } = event;
    setCourseTypes(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleClickOpen = () => {
    if (props.add !== true) {
      let isProjectValue = 0
      if (props.item.isProject) {
        isProjectValue = 1
      }
      setCourse({
        ...course, courseId: props.item.courseId,
        name: props.item.name,
        description: props.item.description,
        isActive: props.item.isActive,
        version: props.item.version,
        id_group: props.item.id_group,
        draft: props.item.draft,
        order: props.item.order,
        professor_name: props.item.professor_name,
        professor_link: props.item.professor_link,
        isProject: isProjectValue,
        hours: props.item.hours,
        code: props.item.code,
        hours_limit: props.item.hours_limit,
      })
      setCourseTypes(props.item.type.split(",").sort())
    }
    setOpen(true);
    getAllTypes()
  };
  //close modal method
  const handleClose = () => {
    setCourse(
      {
        ...course, courseId: 0,
        name: "",
        description: "",
        isActive: false,
        version: 0,
        id_group: 0,
        draft: true,
        order: 0,
        professor_name: "",
        professor_link: "",
        isProject: 0,
        hours: 0,
        code: "",
      hours_limit: false,
      }
    )
    setAllCoursesType([""])
    setOpen(false);
  };
  //modify values by the input value
  const handleInputChange = (e: any) => {
    setCourse({
      ...course,
      [e.target.name]: e.target.value
    })
  }
  const handleLocation = (treeview: boolean) => {
    let route = `/chapters/`
    if (treeview) {
      route = `/treechapters/`
    }
    let path = route + props.item.courseId;
    navigate(path);

  }

  const getAllTypes = () => {
    const token = localStorage.getItem('token');
    MetadataApi.get("types/getall", {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setAllCoursesType(res.data)
    })

  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const token = localStorage.getItem('token');
    const newTypes = courseTypes.sort().join()
    /*  console.log('course',course,'props',props.isProject) */
    let isNewCourse = false
    if (props.add) {
      isNewCourse = true
    }
    MetadataApi.post("courses/addcourse", {
      courseId: course.courseId,
      name: course.name,
      description: course.description,
      isActive: course.isActive,
      version: course.version,
      id_group: course.id_group,
      draft: course.draft,
      order: course.order,
      type: newTypes,
      professor_name: course.professor_name,
      professor_link: course.professor_link,
      isProject: props.isProject,
      hours: course.hours,
      code: course.code,
      isNewCourse: isNewCourse,
      creatorUser: ctx.username,
      hours_limit: course.hours_limit,
      
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      handleClose()
      props.getCourses(props.isProject)
      props.handleOpenSuccess()
      if (props.add) {
        if (props.isProject) {
          props.handleUpdateSnackbar("Proyecto creado")

        } else {
          props.handleUpdateSnackbar("Curso creado")
        }
      } else {
        if (props.isProject) {
          props.handleUpdateSnackbar("Proyecto editado")

        } else {
          props.handleUpdateSnackbar("Curso editado")
        }
      }
    }).catch((reason: AxiosError) => {
      if (props.add) {
        if (props.isProject) {
          props.handleUpdateSnackbar("Crear Proyecto")
        } else {
          props.handleUpdateSnackbar("Crear Curso")
        }
      } else {
        if (props.isProject) {
          props.handleUpdateSnackbar("Editar Proyecto")

        } else {
          props.handleUpdateSnackbar("Editar Curso")
        }
      }
      props.handleOpenError()
    })

  }

  return (
    <>
      <Button onClick={handleClickOpen} sx={{ marginRight: 1 }} size="small" variant={props.versionRow ? "outlined" : "contained"} color='primary' disabled={props.creation}>
        {props.add ? props.versions ? "Crear Borrador" : "Crear nuevo" : "Editar"}
      </Button>
      {/* {
        props.versionRow ?
          <Button onClick={handleClickOpen} sx={{ marginRight: 1 }} size="small" variant='outlined' color='primary' disabled={props.creation}>
            {props.add ? props.versions ? "Crear Borrador" : "Crear nuevo" : "Editar"}
          </Button>
          :
          <Button onClick={handleClickOpen} sx={{ marginRight: 1 }} size="small" variant='contained' color='primary' disabled={props.creation}>
            {props.add ? props.versions ? "Crear Borrador" : "Crear nuevo " : "Editar"}
          </Button>
      } */}
      {props.content ? props.versionRow ? <>{/* <Button sx={{ marginRight: 1 }} onClick={()=>handleLocation(false)} size="small" variant='outlined' color='primary'>
          Contenido</Button> */}
        <Button sx={{ marginRight: 1 }} onClick={() => handleLocation(true)} size="small" variant='outlined' color='primary'>
          Contenidos</Button>
        <TestDialog courseId={props.item.courseId} variant={'outlined'} handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenError} handleUpdateSnackbar={props.handleUpdateSnackbar}></TestDialog>
      </>

        : <>{/* <Button sx={{ marginRight: 1 }} onClick={()=>handleLocation(false)} size="small" variant='contained' color='primary'>
          Contenido</Button> */}
          <Button sx={{ marginRight: 1 }} onClick={() => handleLocation(true)} size="small" variant='contained' color='primary'>
            Contenidos</Button>
          <TestDialog courseId={props.item.courseId} variant={'contained'} handleOpenSuccess={props.handleOpenSuccess} handleOpenError={props.handleOpenError} handleUpdateSnackbar={props.handleUpdateSnackbar}></TestDialog>
        </> : null}
      {<Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.add ? "Crear " : "Editar "}{props.isProject ? "Proyecto" : "Curso"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete el siguiente formulario para añadir un nuevo {props.isProject ? "Proyecto" : "Curso"} al sistema
          </DialogContentText>
          <form onSubmit={handleSubmit} id="form">
            <TextField

              required
              margin="dense"
              id="name"
              name="name"
              label="Nombre"
              type="text"
              value={course.name}
              onChange={handleInputChange}
              fullWidth
              variant="standard"
            />
            <TextField
              required

              margin="dense"
              id="code"
              name="code"
              label="Código"
              type="text"
              value={course.code}
              onChange={handleInputChange}
              fullWidth
              variant="standard"
            />
            <Grid container spacing={2}>
              <Grid item xs={6} >
                <FormLabel component="legend">¿Añadir tiempo limite?</FormLabel>  
                <Box display="flex" alignItems="center"><Typography variant="body1" >
                No
              </Typography>
              <Switch
                checked={course.hours_limit}
                onChange={(e) => {
                  setCourse({
                    ...course,
                    [e.target.name]: !course.hours_limit,
                  });
                }}
                name="hours_limit"
                color="primary"
              />
              <Typography variant="body1" >
                Sí
              </Typography></Box>
                
               {/*  <FormControlLabel control={ <Checkbox
                    checked={course.hours_limit}
                    onChange={(e) => {
                      setCourse({
                        ...course,
                        [e.target.name]: !course.hours_limit,
                      });
                    }}
                    name="hours_limit"/>} label={course.hours_limit?"Sí":"No"} />    */}   
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  margin="dense"
                  id="hours"
                  name="hours"
                  label="Duración en horas"
                  type="text"
                  InputProps={{ inputProps: { min: 0, max: 200 }}}
                  value={course.hours}
                  onChange={e => {validateHour(e.target.value)}}
                  error={hourError !== ''}
                  helperText={hourError}
                  variant="standard"/>
              </Grid>
            </Grid>
            <TextField
              required
              margin="dense"
              id="description"
              name="description"
              label="Descripción"
              type="text"
              multiline
              value={course.description}
              onChange={handleInputChange}
              fullWidth
              variant="standard"
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  margin="dense"
                  id="professor_name"
                  name="professor_name"
                  onChange={handleInputChange}
                  label="Nombre profesor"
                  value={course.professor_name}
                  type='text'
                  fullWidth
                  variant="standard"
                ></TextField>
              </Grid>
              <Grid item xs={6} >
                <TextField
                  required
                  margin="dense"
                  id="professor_link"
                  name="professor_link"
                  label="Link profesor"
                  type="text"
                  error={urlError !== ''}
                  helperText={urlError}
                  value={course.professor_link}
                  onChange={e => { validateUrl(e.target.value) }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
            <InputLabel id="demo-multiple-chip-label">Categorias</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              fullWidth
              required
              value={courseTypes}
              onChange={handleChangeType}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {allCoursesType.map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                >
                  {type}
                </MenuItem>
              ))}
            </Select>

            <DialogActions>
              <Button onClick={handleClose} variant='outlined'>Cancelar</Button>
              <Button type="submit" variant="contained">{props.add ? "Crear" : "Editar"}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>}
    </>
  )
}