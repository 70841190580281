import { useState, useContext, Fragment } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Dialog, DialogContent, DialogContentText, DialogActions, Button, DialogTitle, Typography, Box, SvgIcon } from '@mui/material';
import { myContext } from '../contexts/Context';
import CloseIcon from '@mui/icons-material/Close';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import ReplayIcon from "@mui/icons-material/Replay";

type Props = {
  right: any,
  tabValue: number,
  handlerOpenRemove: Function,
  myProgramsCoursesId: any,
  page: number,
  setPage: Function,
  openCoursesNotRemovedDialog: boolean,
  setOpenCoursesNotRemovedDialog: Function,
  coursesNotRemoved: any,
  username:string,
  handleOpenResetStartDate:Function,
  openKillDialog:Function
}
export default function TableCoursePrograms({
  right,//[0]=>name,[1]=>version,[2]=>courseId,[3]=>activateDate, [4]?=>(tabvalue=>start_date:tabvalue2=>programcourses)
  username,tabValue,handlerOpenRemove,myProgramsCoursesId,page,setPage,openCoursesNotRemovedDialog,setOpenCoursesNotRemovedDialog,coursesNotRemoved,handleOpenResetStartDate,openKillDialog
}: Props) {
  const ctx = useContext(myContext)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openCantRemoveDialog, setOpenCantRemoveDialog] = useState<boolean>(false)
  const [courseName, setCourseName] = useState<string>("")
  const [programsByCourse, setProgramsByCourse] = useState<any>([])
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
  const findProgramsByCourse = (courseName: string, courseId: number) => {
    setCourseName(courseName)

    const token = localStorage.getItem('token');
    MetadataApi.post("learncourses/getprogramsbycourseidadmin/", {
      username: username,
      courseId: courseId,
      adminuser:ctx.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setProgramsByCourse(res.data)
      setOpenCantRemoveDialog(true)

    })
  }
  const handleClose = () => {
    setOpenCantRemoveDialog(false)
    setProgramsByCourse([])
  }

  const changeDateFormat = (date: any) => {
    if (date === "null" || date === null || date === undefined||date === " ") {
      return " "
    } else {
      const dateObject = new Date(date);
      return dateObject.toISOString().replace("T", " ").replace("Z", " ").replace(".000", " ").replace(".001", " ")
    }
  }
  const customTable = () => {
    return (<>
      <TableContainer>
        <Table
          sx={{ maxHeight: 420, height: 420, width: 800, border: "solid", borderWidth: 0.5 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Nombre </TableCell>
              <TableCell align='center'>Version </TableCell>
              <TableCell align='center'>Fecha de activación </TableCell>
                {tabValue===0?
                <TableCell align='center'>Fecha de inicio </TableCell>
                :null}
                <TableCell align="center">Opciones 
                </TableCell>
                {/* <TableCell align="center">Matar Notebook 
                </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
          {
              right
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: number) => {
                  return (
                    <Fragment key={item[0] + item[1]}>
                      <TableRow
                        hover

                        tabIndex={-1}
                        key={item[0]}
                      >
                        <TableCell align="left" width={"30%"}>{item[0]}</TableCell>
                        <TableCell align="center" width={"10%"}>{item[1]}</TableCell>
                        <TableCell align="center" width={"25%"}> {changeDateFormat(item[3])}</TableCell>
                        {tabValue===0?<TableCell align="center" width={"25%"}>{changeDateFormat(item[4])}</TableCell>:null}
                        
                        <TableCell align="center"width={"10%"}>
                          {tabValue === 1 ? (
                            <IconButton
                              onClick={() => {
                                handlerOpenRemove(
                                  item[0],
                                  parseInt(item[2]),
                                  item[3],
                                  item[4]
                                );
                              }}
                              color="error"
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <Box display={"flex"} alignContent={"center"} justifyContent={"center"}>
                              {myProgramsCoursesId.find(
                                (id: number) => id === parseInt(item[2])
                              ) === undefined ? (
                                <IconButton
                                  onClick={() => {
                                    handlerOpenRemove(
                                      item[0],
                                      parseInt(item[2]),
                                      item[3]
                                    );
                                  }}
                                  color="error"
                                >
                                  <CloseIcon />
                                </IconButton>
                              ) : (
                                <Tooltip
                                  title="No es posible eliminar, pertenerce a uno o varios programas.
                              Click para ver"
                                  onClick={() => {
                                    findProgramsByCourse(item[0], item[2]);
                                  }}
                                >
                                  <IconButton>
                                    <CloseIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title="Matar JupyterNotebook" onClick={()=>{openKillDialog()}}>
                              <IconButton >
                                <SvgIcon xmlns="http://www.w3.org/2000/svg" width={24} height={24}  stroke={"#f44336"} strokeWidth="1" >
                                <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" fill="#ffffff"/><path d="M14 3v5h5M9.9 17.1L14 13M9.9 12.9L14 17" fill="#ffffff"/>
                                </SvgIcon>
                          </IconButton>
                              </Tooltip>
                             
                              {item[5]===true?   <Tooltip  onClick={()=>{handleOpenResetStartDate(item[0],item[2])}}  title="Reiniciar fecha de inicio">
                              <IconButton color="primary">
                              <ReplayIcon />
                              </IconButton>
                             
                              </Tooltip>:null}
                           
                              
                            </Box>
                          )}
                             </TableCell>
                            
                         
                    
                      </TableRow>

                    </Fragment>
                  );
                })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={right.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>)
  }
  return (
    <>
      {customTable()}
      {/* Programs where belong a course*/}
      {<Dialog open={openCantRemoveDialog} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          <DialogTitle>Programas donde el curso {courseName} pertenece:</DialogTitle>
          <DialogContentText>
            {programsByCourse.map((program: any) => {
              return (
                <Typography sx={{marginLeft:2}}>- {program.name}</Typography>
              )
            })}
          </DialogContentText>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>}
      {/* Courses not removed dialog */}
      {tabValue === 1 && openCoursesNotRemovedDialog && coursesNotRemoved.length !== 0 ?
        <Dialog open={openCoursesNotRemovedDialog} onClose={() => { setOpenCoursesNotRemovedDialog(false) }} maxWidth="lg">
          <DialogTitle>Cursos no eliminados por pertenecer a los siguientes programas:</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {coursesNotRemoved.map((courseName: string) => {
                return (
                  <Typography>{courseName}</Typography>
                )
              })}
            </DialogContentText>
            <DialogActions>
              <Button variant='outlined' onClick={() => { setOpenCoursesNotRemovedDialog(false) }}>Salir</Button>
            </DialogActions>
          </DialogContent>
        </Dialog> : null}
    </ >
  );
}