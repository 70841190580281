
import { Grid } from '@mui/material';
import ChapterList from '../components/ChaptersList'
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';


export default function Chapters() {
    
    const navigate = useNavigate();
    const goBack = () => { navigate(-1); }
    const { courseId } = useParams();
    
    return (
        <Grid sx={{ marginLeft:2,marginRight:2}
        }>   
            <Grid>
                <IconButton onClick={goBack}>
                    <ChevronLeftIcon />
                </IconButton>
            </Grid>
            <Grid container spacing={1} item xs={12} >
                <Grid item xs={12} >
                    <ChapterList courseId={courseId} />
                </Grid>
            </Grid>
        </Grid>

    );

}