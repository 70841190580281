import List from '@mui/material/List';
import { Skeleton} from '@mui/material';
import {Card,CardHeader,Divider,CardActions,ListItemButton} from '@mui/material';
export default function ListSkeleton() {
  return (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
       
        title={<Skeleton/>}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 400,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
       
        
        role="list"
      >
        {[1,2,3,4,5,6].map((value:number) => {
          return (
              <ListItemButton key={value}>
                
                <Skeleton key={value}/>
              </ListItemButton>

            
          );
        })}

      </List>
      <CardActions disableSpacing >
          <Skeleton variant="rounded" width={230} height={40} />
          <Skeleton variant="rounded" width={230} height={40} />
      </CardActions>
    </Card>
  );
}