import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
export function SnackBarSuccess(props:any) {

    return (
    <> 
        <Snackbar open={props.open} autoHideDuration={2000} onClose={props.handleCloseSuccess}>
        <Alert onClose={props.handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          {props.message} {" "}con exito!
        </Alert>
      </Snackbar>
    </>
    )
}