
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Skeleton, TableFooter, Toolbar, Typography } from '@mui/material';



export default function TableSkeleton() {
  return (
    <Box>
    <Toolbar>
    </Toolbar>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 900,minHeight:450 }} aria-label="simple table">
        <TableHead>
        </TableHead>
        <TableBody>
          {[1,2,3,4,5].map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              <TableCell ></TableCell>
              <TableCell ><Typography variant="h6" component="div">
                  <Skeleton/>
              </Typography></TableCell>
              <TableCell ><Typography variant="h6" component="div">
                  <Skeleton/>
              </Typography></TableCell>
              <TableCell><Typography variant="h6" component="div">
                  <Skeleton/>
              </Typography></TableCell>
              <TableCell ><Typography variant="h6" component="div">
                  <Skeleton/>
              </Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
         
        </TableFooter>
      </Table>
    </TableContainer>
    </Box>
  );
}
